// ----------------------------------------------------------------------------
// DOM Related Utility Functions
// ----------------------------------------------------------------------------

/**
 * Checks the MimeType of the given file input against the given types.
 * Returns true if valid, false otherwise.
 *
 * @param   {Element} $input - file input Element
 * @returns {Boolean}
 */
export function checkFileInput($input) {
  const max  = Number($input.dataset.max || MAX_FILE_SIZE);
  const file = $input.files[0];

  if (file && file.size > max) {
    $input.value = null; // clear the file
    new FastFundModal({
      title:   'Notice',
      content: `
        "${file.name}" is ${ff.fileSize(file.size)} and cannot be added
        because it exceeds the file size limit of ${ff.fileSize(max)}.
      `
    });
    return false;
  }
  return true;
}

/**
 * Checks the MimeType of the given file input against the given types.
 * Returns true if valid, false otherwise.
 *
 * @param   {Element} $input - file input Element
 * @param   {Array}   type   - Array of valid extensions, ie. [gif, png]
 * @returns {Boolen}
 */
export function checkMimeType($input, types) {
  const file  = $input.files[0];
  let   valid = false;

  if (file) {
    types = ff.isArray(types) ? types : Array(types);
    for (let i=0; i < types.length; i++) {
      if (new RegExp(`\\.${types[i]}$`, 'i').test(file.name)) {
        valid = true
        break;
      }
    }
  }

  if (!valid) {
    const info = `
      The selected file is invalid, please ensure that your file is one of the following types:
      <ul>${types.map(t => `<li>${t}</li>`).join('')}</ul>
    `.trim();

    new FastFundModal({title: 'Invalid File', content: info});
    $input.value = null;
  }

  return valid;
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * <wait> milliseconds.
 *
 * ex.
 *      let debounced = ff.debounce(function() { // taxing stuff }, 250);
 *      window.addEventListener('resize', debounced);
 */
export function debounce(func, wait = 100) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(()=>func.apply(this, args), wait);
  };
}

/**
 * Disable specific elements within a container
 *
 * @param {Element} container - container Element
 * @param {Array}   selectors - Array of CSS selectors to disable
 */
export function disableElements(container, selectors=[]) {
  const elements = Array.from(ff.getAll('input,select,textarea,button,[href]', container));

  for (const el of elements) {
    for (const sel of selectors) {
      if (el.matches(sel)) {
        ff.toggleElement(el, {disabled: true});
        break;
      }
    }
  }
}

/**
 * Disable the given form element
 *
 * @param {Element} form      - form Element to disable
 * @param {Array}   selectors - Array of CSS selectors to enable
 */
export function disableForm(form, selectors=[]) {
  // disable the entire form, then reenable any exceptions
  for (const el of form.elements) {
    if (ff.isActive(el)) ff.toggleElement(el, {disabled: true});
  }
  selectors = ff.isArray(selectors) ? selectors : Array(selectors);
  ff.getAll(selectors.join(','), form).forEach($el => ff.toggleElement($el, {disabled: false}));
}

/**
 * Empty all content within the given element
 *
 * @param {Element} parent    - parent Element to empty
 */
export function empty(parent) {
  if (ff.isElement(parent)) {
    while (parent.firstChild) parent.removeChild(parent.firstChild);
  }
}

/**
 * Add the given JS to a new <script> element, execute then remove it
 *
 * @param {String} js - JS content to execute
 */
export function execJS(js) {
  const $script = document.createElement('script');
  js            = js.replace(/<(\/)?script>/gi, '');  // ensure to remove any existing script tags
  $script.text  = `(async ()=>{ ${js} })();`;         // wrap in an async IIFE
  document.head.appendChild($script).parentNode.removeChild($script);
}

/**
 * Update a flash notification element with the given content
 *
 * @param {String} type    - the flash type (error, info, warning, etc.)
 * @param {String} content - flash/status content
 */
export function flash(type='info', content=null) {
  if (content) {
    content = `
      <div class="flash flash-${type}" role="alert">
        <div class="flash-close" onclick="ff.flash()">&times;</div>
        <div class="flash-text">${content}</div>
      </div>
    `.trim();
  }

  ff.getAll('div.flash-container').forEach(function(div, idx, obj) {
    if (content) div.innerHTML = content;
    else ff.removeChildren(div);
  });
}

/**
 * Focus the first visible form input, returns the focused element
 *
 * @param   {String/Element}   obj - Form, Element or DOM selector
 * @returns {Element}
 */
export function focusForm(obj) {
  let form    = null;
  let element = null;

  if (obj !== undefined) {
    if (ff.isString(obj)) element = ff.getAll(obj)[0];  // obj is a selector
    else if (ff.isElement(obj)) {
      if (obj.tagName == 'FORM') form = obj;
      else element = obj;                               // obj is the focusable element
    }
  }
  else form = document.forms[0];                        // no obj given, use first form if available

  if (form) {                                           // find the first focusable element within form
    for (let i=0; i<form.length; i++) {
      let $el = form[i];

      if ($el.tabIndex >= 0 && ff.isFormInput($el) && ff.isActive($el) && !$el.readOnly && !$el.matches('.readonly')) {
        element = $el;
        break;
      }
      else {
        //console.log(`no focus: ${$el.name}`);
      }
    }
  }

  if (element) {
    if (element.tagName == 'INPUT' && ['text'].includes(element.type)) {
      // only select non-date text inputs (masks handle selection in their focus handlers)
      if (!element.date) element.select();
    }
    element.focus();
  }

  return element;
}

/**
 * Focus the submit button of the given form
 *
 * @param   {Element}  form - form Element
 * @returns {Element}  submit button
 */
export function focusFormSubmit(form) {
  const submit = ff.get('input[type=submit],button.save-button', form);
  if (submit && ff.isActive(submit)) {
    submit.focus();
    return submit;
  }

  return null;
}

/**
 * Focus and return the next available form input
 *
 * @param   {Element}  element - form or offset Element
 * @returns {Element}          - Element that was focused
 */
export function focusNextElement(element) {
  let focused = null;

  if (ff.isElement(element)) {
    if (element.tagName.toLowerCase() === 'form') focused = ff.focusForm(element);
    else {
      let canFocus = false;
      let form     = element.form;

      // loop through the form elements
      for (let i=0; i < form.length; i++) {
        let el = form[i];

        if (!canFocus && el == element) {
          // we found the offset element, now we can focus the next available
          canFocus = true;
        }
        else if (canFocus && el.tabIndex != -1 && ff.isFormInput(el)) {
          focused = el;
          try { el.select() }
          catch(ex) { el.focus() }
          break;
        }
      }
    }
  }

  return focused;
}

/**
 * alias for document.querySelector or
 * getElementById if selector is prefixed with '#'
 *
 * @param   {String}    selector  - DOM select string or Element ID
 * @param   {Element}   container - DOM container Element object to search
 * @returns {Element}   first matching element
 */
export function get(selector, container) {
  if (selector[0] == '#') return document.getElementById(selector.slice(1));
  else return (container || document).querySelector(selector);
}

/**
 * alias for document.querySelectorAll
 *
 * @param   {String}           selector   - DOM selector
 * @param   {Element/String}   container  - DOM container Element object or Selector to search
 * @returns {NodeList}
 */
export function getAll(selector, container) {
  if (container && ff.isString(container)) container = ff.get(container);
  return (container || document).querySelectorAll(selector);
}

/**
 * Find the first focusable element within the given container and return it,
 * or return a null element if none were found
 *
 * @param   {Element}   container - Array of Elements or single Element object
 * @returns {Element}
 */
export function getFocusable(container) {
  if (ff.isElement(container)) container = Array.from(ff.getAll(ff.FOCUSABLE_SELECTOR, container));

  for (const el of container) {
    //console.log(`checking: ${el.id}`);
    if (ff.isActive(el)) return el;
  }

  return document.createElement(null);
}

/**
 * Returns the top, bottom, width and height position values of a given element
 *
 * @param   {Element}   el - DOM Element object
 * @returns {DOMRect}   DOMRect object - {left, top, right, bottom, x, y, width, height}
 */
export function getPosition(el) {
  return el.getBoundingClientRect();
}

/**
 * Return the value of the given selector
 *
 * @param   {String}           selector   - DOM selector
 * @param   {Element/String}   container  - DOM container Element object or Selector to search
 * @returns {String}
 */
export function getValue(selector, container) {
  const $el = ff.get(selector, container);
  return $el ? $el.value : null;
}

/**
 * Applies a background highlight effect to the given element, see effects.css
 *
 * @param   {Element}   el    - DOM Element object
 * @param   {String}    type  - highlight type: 'error', 'info'
 */
export function highlightEffect(el, type='error') {
  // titleize the type and generate the animation style string
  type            = (type == 'error') ? 'error' : 'info';
  const animation = `highlight${type[0].toUpperCase()}${type.slice(1)} .75s linear`;
  el.addEventListener('animationend', e => { e.target.style.animation = null });
  el.style.animation = animation;
}

/**
 * Insert an element after another
 *
 * @param   {Element}   reference - Reference Element
 * @param   {String}    element   - Element to insert
 */
export function insertAfter(reference, element) {
  reference.parentNode.insertBefore(element, reference.nextSibling);
}

/**
 * Insert an element before another
 *
 * @param   {Element}   reference - Reference Element
 * @param   {String}    element   - Element to insert
 */
export function insertBefore(reference, element) {
  reference.parentNode.insertBefore(element, reference);
}

/**
 * Removes all children from the given element
 *
 * @param {Element} el - DOM Element object
 */
export function removeChildren(el) {
  while (el.firstChild) el.removeChild(el.firstChild);
}

/**
 * Sets the maxheight of the given element so it doesn't overflow the body
 *
 * @param {Element} el - DOM Element object
 */
export function setMaxHeight(el) {
  if (el) {
    const overflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    const elPos     = ff.getPosition(el);
    const maxHeight = window.innerHeight - elPos.top - ff.FOOTER_PADDING;

    el.style.maxHeight = `${maxHeight}px`;
    document.body.style.overflow = overflow;
  }
}

/**
 * Set a tabIndex for each element in the given container
 *
 * @param   {Element}  container - Array of Elements or single Element object
 * @param   {Integer}  index     - Starting tabIndex
 * @returns {Integer}  last index
 */
export function setTabIndex(container, index=1) {
  if (ff.isElement(container)) container = Array.from(ff.getAll(ff.FOCUSABLE_SELECTOR, container));

  container.forEach(el => { el.tabIndex = index++ });
  return index;
}

/**
 * Sort the options of the given select alphabetically
 *
 * @param {Element}  select - Element object
 */
export function sortSelectOptions(select) {
  const options = Array.from(select.options);
  options.sort(function(a, b) { return a.text.localeCompare(b.text) });

  // drop all options and re-add from the sorted array
  select.length = 0;
  options.forEach(o => {
    o.selected = false;
    select.add(o);
  });
}

/**
 * Stops an Event
 *
 * @param {Event}  ev - Event object
 */
export function stopEvent(ev) {
  ev = ev || event;
  if (ev) {
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
  }
}

/**
 * Update certain attributes of an Element
 *
 * @param   {Element}  el       - Element object
 * @param   {Object}   options  - options to toggle: checked, selected, disabled, hidden,
 *                                                   readonly, required or value to set/update value
 * @returns {Element}  given Element
 */
export function toggleElement(el, options) {
  if (ff.isElement(el)) {
    options = options || {};

    if (options.disabled == true && ['a','i'].includes(el.tagName.toLowerCase())) {
      delete options['disabled'];
      options.hidden = true;
    }

    ['disabled', 'hidden', 'required', 'readOnly'].forEach(attr => {
      const key = attr.toLowerCase();

      if (options[key] == true) {
        el[attr] = true;
        el.classList.add(key);
      }
      else if (options[key] == false) {
        el[attr] = false;
        el.classList.remove(key);
      }
    });

    ['checked', 'selected', 'value'].forEach(attr => {
      if (options.hasOwnProperty(attr) && (el[attr] != options[attr])) {
        // use setValue instead of "value=" for currency inputs
        if (attr == 'value' && el.hasOwnProperty('setValue')) el.setValue(options[attr]);
        else el[attr] = options[attr];
        // dispatch a change event if checked or value was changed
        el.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}));
      }
    });
  }

  return el;
}

/**
 * Updates the innerHTML of an element
 *
 * @param {String}  selector - selector for the DOM element to update
 * @param {String}  html     - html content
 * @param {String}  script   - JS code to attach and run
 */
export async function updateHTML(selector, html, script) {
  const $el  = ff.get(selector);
  let   init = false;

  if ($el) {
    init = true;
    $el.innerHTML = html;
  }
  else console.error(`invalid selector: ${selector}`);

  // if we're passed script content, add it to a script tag,
  // append to HEAD where it's executed, then remove it
  if (!ff.isEmpty(script)) {
    init = true;
    const $script = document.createElement('script');
    $script.text  = `(async ()=>{ ${script} })();`;   // wrap script content in an async IIFE
    document.head.appendChild($script).parentNode.removeChild($script);
  }

  // run initContent() whenever page content changes
  if (init) await ff.initContent();
}
