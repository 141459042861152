'use strict';

import FastFundMaskedInput from './fastfund_masked_input.js';

/**
 * Account Class
 */
export default class FastFundAccount extends FastFundMaskedInput {
  //---------------------------------------------------------------------------------------------------------
  // Static Functions
  //---------------------------------------------------------------------------------------------------------

  static DEFAULT_KEY   = 'accounts';
  static INACTIVE_HINT = '<span class="bold underline">INACTIVE ACCOUNT</span><br>';
  static INVALID_HINT  = 'No valid account selected';

  /**
   * Initialize an account data source with the given key and data
   *
   * @param   {Object}   data - Object of account data from Account.get_json_data
   * @param   {String}   key  - Identifier for a given set of accounts, optional
   */
  static initAccounts(data, key) {
    const mask          = data.mask.split('-').map(s => `\\d{${s.length}}`).join('-');
    key                 = key || this.DEFAULT_KEY;
    this._emptyChar     = '_';
    this._pattern       = `^${mask}$`;
    this._accounts      = this._accounts || {};
    this._accounts[key] = data;
    this._accountRegExp = new RegExp(this._pattern);                  // Account number RegExp
    this._mask          = data.mask.replace(/\d/g, this._emptyChar);  // Account number mask, eg. __-___-___-____

    if (this._clearIcon === undefined) {
      // initialize account elements for cloning
      this._containerDiv = document.createElement('div');
      this._clearIcon    = document.createElement('i');
      this._hintIcon     = document.createElement('i');
      this._plusIcon     = document.createElement('i');
      this._hiddenInput  = document.createElement('input');
      Object.assign(this._containerDiv, {className: 'icon-wrapper'});
      Object.assign(this._clearIcon,    {className: 'far ff-mask-icon fa-trash-can', title: 'Clear Account'});
      Object.assign(this._plusIcon,     {className: 'far ff-mask-icon fa-plus',      title: 'Select all Accounts'});
      Object.assign(this._hintIcon,     {className: 'far ff-mask-icon fa-question',  data:  {tooltip: 'No valid account selected'}});
      Object.assign(this._hiddenInput,  {type: 'hidden'});
    }

    return this._accounts;
  }

  /**
   * Account Data getter
   *
   * @param   {Element} input  - account input Element
   */
  static getAccounts(input) {
    const key = input.dataset.key || this.DEFAULT_KEY;
    return (this._accounts || {})[key];
  }

  //---------------------------------------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------------------------------------

  /**
   * Constructor
   *
   * @param   {Element}   input  - input Element
   */
  constructor(input) {
    super(input, {mask: FastFundAccount._mask, maskChars: FastFundAccount._emptyChar});

    this.input         = input;                                     // DOM element of masked account input
    this.input.account = this;                                      // Add a reference to this FastFundAccount instance
    this.accountData   = FastFundAccount.getAccounts(input);        // Object of account data
    this.accountRegExp = FastFundAccount._accountRegExp;            // Account number RegExp
    this.required      = input.required;                            // required flag
    this.allowInactive = input.dataset.inactive;                    // allow inactives flag
    this.filter        = input.dataset.filter;                      // filter flag
    this.initialValue  = (input.value.trim() || input.dataset.id);  // initial account id value for persisted objects
    this.searchLimit   = 1000;                                      // search result limit
    this.disabled      = false;                                     // disabled flag
    this.initialized   = false;                                     // initialized flag
    this.linkedIndex   = null;                                      // linked index (0 start/first, 1 end/last)
    this.linkedInput   = null;                                      // linked input object for account ranges
    this.hiddenInput   = null;                                      // hidden input for form submission value
    this.hintIcon      = null;                                      // tooltip hint icon element
    this.clearIcon     = null;                                      // account clear icon element
    this.plusIcon      = null;                                      // account plus icon element
    this.menu          = null;                                      // FastFundMenu instance
    this.segment       = null;                                      // saved segment cursor position
    this.onInvalid     = null;                                      // on invalid callback
    this.textCache     = '';                                        // text cache for account name searching
    //this.canLink     = input.dataset.canlink === true;            // user can link new accounts

    if (ff.isEmpty(this.accountData)) throw `missing account data for ${input.dataset.key}`;
    else {
      // finalize initialization if we have account data
      this.accounts    = this.accountData.numbers;           // Object of: {number: {id, inactive, name, short_name}}
      this.map         = this.accountData.map;               // Input map of char position to segment
      this.search      = ff.debounce(this._search, 250);     // debounced search function
      this.segments    = this.accountData.segments;          // Account segment data

      this._initInput();
      this._initEvents();
      this.initialized = true;

      ff.updateObjects(input, this);
    }
  }

  //---------------------------------------------------------------------------------------------------------
  // Private Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Initialize the menu object
   */
  _initMenu() {
    // bind 'this' to the method so 'this' always refers to our instance
    this._onMenuSelect = this._onMenuSelect.bind(this);
    this.menu = new FastFundMenu(this.input, {base: this.input, maxHeight: null, onSelect: this._onMenuSelect});
  }

  /**
   * Initialize the input
   */
  _initInput() {
    this.hintIcon    = FastFundAccount._hintIcon.cloneNode();     // hint icon is always used
    this.clearIcon   = FastFundAccount._clearIcon.cloneNode();    // clear icon is always used
    this.hiddenInput = FastFundAccount._hiddenInput.cloneNode();  // hidden input to hold account ID value for submit
    Object.assign(this.hiddenInput, {account: this, name: this.input.name || this.input.dataset.name, required: this.input.required});
    for (const [k, v] of Object.entries(this.input.dataset)) this.hiddenInput.dataset[k] = v;

    // check for and generate the linked input
    if (this.input.dataset.link) {
      this.plusIcon    = FastFundAccount._plusIcon.cloneNode();
      this.linkedInput = ff.get(`#${this.input.dataset.link}`);
      if (this.linkedInput) {
        this.linkedIndex = this.linkedInput.account ? 1 : 0;                                 // this account is last if the link is initialized
        Object.assign(this.plusIcon, {account: this, onclick: function(){ this.account.selectAll() } });
        // select all accounts if there's only 1 option and nothing selected
        if (this.linkedIndex == 1 && Object.keys(this.accounts).length == 1 && !this.getValue() && !this.linkedInput.account.getValue()) this.selectAll();
      }
    }
    // update and position the icons
    const inputWidth       = this.linkedInput ? this.mask.length+8 : this.mask.length+6;     // visible input width in 'ch' units
    const iconWidths       = this.linkedInput ? [22, 36, 54] : [20, 38];                     // left position offsets in 'px' for icons
    const icons            = [this.plusIcon, this.hintIcon, this.clearIcon].filter(i => i);  // array of icons
    this.input.name        = '';                                                             // remove name so only the hiddenInput value is submitted
    this.input.style.width = `${inputWidth}ch`;
    Object.assign(this.clearIcon, {account: this, onclick: function(){ this.account.clear() } });
    icons.forEach((icon, idx) => icon.style.left = this.input.offsetWidth-iconWidths[idx] + 'px');

    // insert the container div before the account input, then add
    // the remaining elements to a fragment for appending as a whole
    const container = FastFundAccount._containerDiv.cloneNode();
    const fragment  = document.createDocumentFragment();
    ff.insertBefore(this.input, container);
    fragment.appendChild(this.input);
    fragment.appendChild(this.hiddenInput);
    icons.reverse().forEach(icon => fragment.appendChild(icon));
    container.appendChild(fragment);

    // update the values, apply the mask and init the tooltip
    this.setValue(this.initialValue);
    this._applyMask();
    new FastFundToolTip(this.hintIcon);

    if (this.input.disabled || this.input.readOnly) this.disable(true);
    else this.enable(true);
  }

  /**
   * Check and validate the account input value
   *
   * @param   {String}   number     - Account number string
   * @param   {String}   clear      - 'force' (always clear) or 'invalid' (clear only on invalid)
   */
  _checkAccount({number = null, clear = false} = {}) {
    number              = (number || this.input.value).toString().slice(0, this.mask.length);
    const account       = this.accounts[number];
    const previousValue = this.hiddenInput.value.trim() || this.mask;  // use the mask if input value is empty
    let inputClass      = null;                                        // CSS class to set on input after checks
    let validityText    = '';                                          // custom validity text to set
    this.hiddenInput.value        = null;                              // reset hidden input value
    this.hintIcon.dataset.tooltip = FastFundAccount.INVALID_HINT;
    this.input.title              = FastFundAccount.INVALID_HINT;
    this.input.classList.remove('warning');
    this.input.classList.remove('alert');

    //console.log(`num: ${number}  clear: ${clear}  prev: ${previousValue}`);

    if (clear) {
      // reset input value to the mask
      this.input.value = this.mask;
    }
    else if (this.filter) {
      // allow any value to be set when this object is acting as a filter
      const text                    = `Filter value: ${number}`;
      this.hiddenInput.value        = number;
      this.input.value              = number;
      this.input.title              = text;
      this.hintIcon.dataset.tooltip = text;
    }
    else if (!account) {
      // blank/invalid number
      if (number != this.mask && !ff.isEmpty(number)) {
        inputClass   = 'alert';
        validityText = FastFundAccount.INVALID_HINT;

        // a full number was entered, try and look it up
        if (this.accountRegExp.test(number)) {
          if (typeof this.onInvalid === 'function') this.options.onInvalid(this, number);
          else {
            ff.fetch('/ajax/get_account', {
              method:     'POST',
              data:       {number: number},
              onSuccess:  acct => {
                if (acct) {
                  let txt = `Account ${number} exists but is not a valid option for this entry`;

                  if (acct.inactive) {
                    inputClass = 'warning';
                    txt = `${txt} because it's currently marked INACTIVE`;
                  }

                  new FastFundModal({title: 'Invalid Account', content: `${txt}.`});
                }
                else {
                  /*
                  let account = this.input;
                  let modal = new FastFundModal({
                    title: 'Invalid Account',
                    content: `Account ${number} does not exist, would you like to create/link it now?`,
                    onClose: function() { account.focus() },
                    onOK: function(data) {
                      modal.close();
                      new FastFundModal({
                        title:    'Account Creation',
                        content:  `coming soon...`,
                        onClose:  function() { account.focus() }
                      });
                    }
                  });
                  */
                }
              }
            });
          }
        }
      }
    }
    else {
      // valid account
      this.input.value              = number;
      this.input.title              = account.name;
      this.hiddenInput.value        = account.id;
      this.hintIcon.dataset.tooltip = `
        ${account.inactive ? FastFundAccount.INACTIVE_HINT : ''}
        ${number}<br>${account.name}
      `;

      if (account.inactive) {
        inputClass = 'warning';
        if (this.initialized) ff.highlightEffect(this.input, 'info');
        this.input.title = `(INACTIVE) ${account.name}`;

        if (!this.allowInactive && (account.id != this.initialValue)) {
          // Only invalidate the input when trying to select an inactive account that's
          // different from the original account id. This behavior -
          //   1. prevents form submission unless the account is changed to an active account
          //   2. allows users to make other changes to the object and keep the original inactive account
          validityText            = this.input.title;
          inputClass              = 'alert';
          this.hiddenInput.value  = '';
        }
      }
    }

    // set or clear the custom validity and input class
    if (inputClass) this.input.classList.add(inputClass);
    this.input.setCustomValidity(validityText);

    // always dispatch a change event if the parent form is observable and the input value has changed
    //if (this.initialized && this.input.form && this.input.form.dataset.changed !== 'true') {
    if (this.initialized && this.input.form) {
      const currValue = this.hiddenInput.value.trim() || this.mask;
      //console.log(`changed: prev: ${previousValue}  cur: ${currValue}  num: ${number}`);
      if (previousValue != currValue) {
        //ff.formChange(this.input.form, true);
        this.hiddenInput.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}));
      }
    }
  }

  /**
   * Returns an HTML string using <b> for matched digits
   *
   * @param   {String}   number - Account number string
   * @param   {String}   term   - Search string
   * @returns {String}   HTML
   */
  _highlightSearchResult(number, term) {
    let highlight = '';

    for (let i=0, l=term.length; i<l; i++) {
      const ch = term[i];
      if (ch != '-' && ch == number[i]) highlight = `${highlight}<b>${ch}</b>`;
      else highlight += number[i];
    }

    return `${highlight}${number.substr(this.mask.length)}`;
  }

  /**
   * Initialize event listeners
   */
  _initEvents() {
    // input events
    this.input.onblur     = function(event) { this.account._checkAccount() };
    this.input.onfocus    = function(event) { this.account._onFocus(event) };
    this.input.onkeypress = function(event) { this.account._onKeyPress(event) };
    this.input.onkeydown  = function(event) { this.account._onKeyDown(event) };
    this.input.onpaste    = function(event) { this.account._onPaste(event) };
    this.input.oninvalid  = function(event) { this.account._onInvalid(event) };
    ff.addMutationObserver(this.input, this);
  }

  /**
   * Handle a focus event
   *
   * @param   {Event}   event - Event object
   */
  _onFocus(event) {
    if (!this.menu) this._initMenu();
    this.textCache = '';
    this._checkPosition();
  }

  /**
   * Handle an invalid event
   *
   * @param   {Event}   event - Event object
   */
  _onInvalid(event) {
    this.input.setCustomValidity('A valid account number is required.');
    this.input.setSelectionRange(0,1);
    this.input.focus();
  }

  /**
   * Handle a keydown event
   *
   * @param   {Event}   event - Event object
   */
  _onKeyDown(event) {
    const pos  = this.input.selectionStart || 0;
    let   stop = true;

    switch (event.key) {
      case 'Escape':
        // close menu here instead of letting menu close itself
        this.menu.close();
        this.textCache = '';
        break;
      case 'Backspace':
        this.textCache = this.textCache.substr(0, this.textCache-1);
        this._removeChar(pos);
        this._selectPreviousChar(pos);

        this.search();
        break;
      case 'Delete':
        this._deleteChar(pos);
        this.search();
        break;
      case 'Home':
        if (this.menu.isOpen()) this.menu.move('first');
        else this.input.setSelectionRange(0, 1);
        break;
      case 'End':
        if (this.menu.isOpen()) this.menu.move('last');
        else this.input.setSelectionRange(this.mask.length-1, this.mask.length);
        break;
      case 'ArrowLeft':
        this._selectPreviousChar();
        break;
      case 'ArrowRight':
        this._selectNextChar();
        break;
      case ' ':
        this._removeChar(pos);
        this._selectNextChar();
        this.search();
        break;
      case 'PageDown':
        if (this.menu.isOpen()) this.menu.move('nextPage');
        break;
      case 'PageUp':
        if (this.menu.isOpen()) this.menu.move('previousPage');
        break;
      case 'ArrowUp':
        if (this.menu.isOpen()) this.menu.move('previous');
        else {
          this.segment = this.accountData['map'][pos];
          this._search({source: 'segment'});
        }
        break;
      case 'ArrowDown':
        if (this.menu.isOpen()) this.menu.move('next');
        else if (!this.filter) this._search({force: true});
        break;
      case 'Tab':
        // menu select if menu is open
        if (this.menu.isOpen()) this.menu.select();
        // select next empty char if not at end and not a complete #
        //else if (this.input.selectionEnd < this.mask.length && !this.accountRegExp.test(this.input.value)) this._selectEmptyChar(pos+1);
        // else let the event bubble
        else return;
        break;
      case 'Enter':
        // select if the menu is open else do nothing
        if (this.menu.isOpen()) this.menu.select();
        break;
      default:
        stop = false;
        this.search();
        break;
    }

    if (stop) ff.stopEvent(event);
    this._checkPosition();
  }

  /**
   * Handle a keypress event
   *
   * @param   {Event}   event - Event object
   */
  _onKeyPress(event) {
    const key = String(event.key).toLowerCase();

    if (key.length == 1) {
      const pos = this.input.selectionStart || 0;

      if (this._validChar(pos, key)) {
        this.textCache = '';    // clear text cache on any valid numeric input
        this._setChar(pos, key);
        this._selectNextChar();
        this.search();
      }
      else if (this.textCache.length >= 2) {
        this.search({source: 'name'});
      }
      event.preventDefault();
    }
  }

  /**
   * Select callback handler for the Menu
   *
   * @param   {Element}   el - Selected element
   */
  _onMenuSelect(el) {
    this.menu.close();

    if (el) {
      el = el.closest('li');
      const num    = el.dataset.value;
      let   select = false;

      //console.log(`selected: ${num}   segment: ${this.segment}`);

      if (!ff.isEmpty(num)) {
        if (this.segment == null) {
          this.input.value = num;
          select = true;
        }
        else {
          const segments = this.input.value.split('-');
          segments[this.segment] = num;
          this.input.value = segments.join('-');
          this._selectEmptyChar();
        }

        this._checkAccount();
        this.input.focus();
        if (select) this.input.select();
      }
    }
  }

  /**
   * Handle a paste event
   *
   * @param   {Event}   event - Event object
   */
  _onPaste(event) {
    ff.stopEvent(event);
    const text = (event.originalEvent || event).clipboardData.getData('text/plain');
    if (this.accountRegExp.test(text)) {
      // only allow a paste if the clipboard data matches the account number pattern
      this._checkAccount({number: text});
    }
    else ff.highlightEffect(this.input, 'error');
  }

  /**
   * Remove the character at the given position
   *
   * @param   {Integer}   pos - Input position
   */
  _removeChar(pos) {
    if (pos < 0) pos = 0;
    else if (pos >= this.mask.length) pos = this.mask.length-1;

    if (pos == 0 && ((this.input.selectionEnd || 0) >= this.mask.length)) {
      // clear the input if entire value was selected when backspace/del
      this._checkAccount({clear: true});
      this._onFocus();
    }
    else this._setChar(pos, this.mask[pos]);
  }

  /**
   * Search a source, call via debounced func `this.search`
   *
   * @param   {Object}   options - source (number, name or segment #), force
   */
  _search(options = {}) {
    let   results = [];
    const opts    = Object.assign({
      // merge any given options with some defaults
      source: 'number',
      force:  false
    }, options);

    this.menu.close();

    if (opts.source == 'segment') {
      // segment position, show all segments for the given position even when filtering
      results = this.accountData['segments'][this.segment];
    }
    else if (!this.filter) {
      if (opts.source == 'number') {
        const val = this.input.value;
        this.segment = null;

        // only search if we have at least 1 digit entered and the search value is different
        if (/\d/.test(val)) {
          const regexp  = new RegExp(`^${val.replace(/_/g,'\\d')}$`);
          let   matches = 0;

          for (const num in this.accounts) {
            if (regexp.test(num)) {
              matches++;
              // stop pushing at searchLimit matches
              if (matches <= this.searchLimit) {
                const label = this._highlightSearchResult(num, val);
                results.push({value: num, label: `${label} ${this.accounts[num].name}`, inactive: this.accounts[num].inactive});
              }
            }
          }
        }
      }
      else if (opts.source == 'name') {
        let   matches = 0;
        const regexp  = new RegExp(`(\\w*)(${this.textCache})(\\w*)`, 'i');

        for (const num in this.accounts) {
          const name = this.accounts[num]['name'];

          if (regexp.test(name)) {
            matches++;
            // stop pushing at searchLimit matches
            if (matches <= this.searchLimit) {
              const label = name.replace(regexp, "$1<b>$2</b>$3");
              results.push({value: num, label: `${num} ${label}`});
            }
          }
        }
      }
    }

    if (opts.force || (results && results.length >= 1)) {
      const html = [];

      if (results.length == 0) html.push('<li disabled class="bold disabled" data-value=""> No Results Found </li>');
      else {
        let klass;
        results.forEach(obj => {
          if (obj.inactive) klass = `class="inactive"`;
          else klass = '';

          html.push(`<li ${klass} data-value="${obj.value}">${obj.label}</li>`);
        });
      }

      this.menu.open(html.join(''));
    }
  }

  /**
   * Select the first empty character starting at the given pos
   *
   * @param   {Integer}   pos - Input position
   */
  _selectEmptyChar(pos) {
    if (pos >= 0) {
      while (pos < this.mask.length) {
        if (this.input.value[pos] == this.maskChars) break;
        pos++;
      }
    }
    else pos = this.input.value.indexOf(this.maskChars);

    this._selectNextChar(pos);
  }

  /**
   * Returns true if the given character and position are valid against the mask
   *
   * @param    {Integer}   pos - Input position
   * @param    {String}    ch  - Input character (lower cased)
   * @returns  {Boolean}   true if valid
   */
  _validChar(pos, ch) {
    if (this.mask[pos] == this.maskChars) {
      if (this.validChars.includes(ch)) return true;
      else if ('abcdefghijklmnopqrstuvwxyz'.includes(ch)) this.textCache += ch;
    }
    return false;
  }

  //---------------------------------------------------------------------------------------------------------
  // Public Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Clear the date input
   *
   * @param   {Boolean} focus - focus the input after clearing
   */
  clear(focus=true) {
    this._checkAccount({clear: true});
    if (focus) this.focus();
    return this;
  }

  /**
   * Destroy this object's associated elements
   */
  destroy() {
    if (this.menu) {
      this.menu.destroy();
      this.menu = null;
    }

    this.input.account = null;
    return this;
  }

  /**
   * Disable this input
   *
   * @param   {Boolean}   force   - force disabling
   * @param   {Boolean}   clear   - clear input value
   */
  disable(force, clear) {
    if (force || !this.disabled) {
      if (clear) this.clear();
      this.disabled       = true;
      this.input.required = false;
      this.input.pattern  = null;
      this.input.setSelectionRange(0,0);
      [this.clearIcon, this.plusIcon].filter(i => i).forEach(i => i.classList.add('disabled'));

      if (this.input.readOnly) ff.toggleElement(this.input, {readonly: true});
      else ff.toggleElement(this.input, {disabled: true});
      return this;
    }
  }

  /**
   * Enable this input
   *
   * @param   {Boolean}   force   - force enabling
   */
  enable(force) {
    if (force || this.disabled) {
      this.disabled       = false;
      this.input.disabled = false;
      this.input.required = this.required;
      if (this.required) this.input.pattern = FastFundAccount._pattern;
      [this.clearIcon, this.plusIcon].filter(i => i).forEach(i => i.classList.remove('disabled'));
      return this;
    }
  }

  /**
   * Focus the account input on the first character
   */
  focus() {
    this.input.focus();
    this.input.setSelectionRange(0, 1);
    return this;
  }

  /**
   * Return the hidden input value
   *
   * @returns  {String}   Account ID
   */
  getValue() {
    return this.hiddenInput.value;
  }

  /**
   * Hide this entire account input
   */
  hide() {
    ff.toggleElement(this.input.closest('div'), {hidden: true});
    return this;
  }

  /**
   * Unhide this entire account input
   */
  unhide() {
    ff.toggleElement(this.input.closest('div'), {hidden: false});
    return this;
  }

  /**
   * Select all accounts for linked inputs
   */
  selectAll() {
    if (this.linkedInput) {
      const numbers = Object.keys(this.accounts);

      if (this.linkedIndex == 0) {
        this.setAccountNumber(numbers[0]);
        this.linkedInput.account.setAccountNumber(numbers[numbers.length-1]);
      }
      else {
        this.linkedInput.account.setAccountNumber(numbers[0]);
        this.setAccountNumber(numbers[numbers.length-1]);
      }
    }
    return this;
  }

  /**
   * Set the input to the given account id
   *
   * @param   {String}   id     - Account ID
   */
  setAccountId(id) {
    let number;
    if (id) number = Object.keys(this.accounts).find(num => this.accounts[num].id == id);
    // clear the input if an id was not matched to a number
    this._checkAccount({number: number, clear: number === undefined});
    return this;
  }

  /**
   * Set the input to the given account number
   *
   * @param   {String}   number - Account number
   */
  setAccountNumber(number) {
    this._checkAccount({number: number});
    return this;
  }

  /**
   * Set the input based on the given ID or Number
   *
   * @param   {String}   val - Account ID or Number
   */
  setValue(val) {
    // if the value contains any non-digit, set via Number, otherwise set via ID
    if (/\D/.test(val)) this.setAccountNumber(val);
    else this.setAccountId(val);
    return this;
  }

}
