'use strict';

/**
 * ReportSet Class - UI for a sortable, nested tree view of Accounting report sets
 */
export default class FastFundReportSet {
  //---------------------------------------------------------------------------------------------------------
  // Static Functions
  //---------------------------------------------------------------------------------------------------------

  // Checkbox Icon Class Names
  static CHECKBOX_ICONS = {
    base: 'report-set-box far',
    all:  'fa-square-check',
    some: 'fa-square-minus',
    none: 'fa-square'
  };

  // Toggle Icon Class Names
  static TOGGLE_ICONS = {
    min:  'fa-caret-right',
    max:  'fa-caret-down'
  };

  // Misc. Class Names
  static CLASSES = {
    root:             'report-set-root-container',
    group_container:  'report-set-group-container',
    items_container:  'report-set-items-container',
    box:              'report-set-box',
    group:            'report-set-group',
    item:             'report-set-item',
    label:            'report-set-label',
    nested:           'report-set-item-nested',
    sortable:         'report-set-sortable',
  };

  static LOAD_SET_URL = '/reports/report_sets/load_set';

  //---------------------------------------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------------------------------------

  /**
   * Constructor
   *
   * @param   {Element}   parentForm  - Parent Form Element that controls the ReportSet
   * @param   {Object}    data        - Segment data from backend ReportSet.data
   */
  constructor(parentForm, data) {
    // members
    this.data         = data;                                              // segment data
    this.parentForm   = parentForm;                                        // Parent form element controlling the ReportSet
    this.selectLoad   = ff.get('select.report-set');                       // load existing report select element
    this.selectType   = ff.get('select.segment-type');                     // segment type select element
    this.boxRollup    = ff.get('input[name~=rollup]', this.parentForm);    // rollup checkbox option for financial statement reports
    this.form         = ff.get('form.report-set');                         // report set form element
    this.root         = ff.get(this._getClass('root', 'div'), this.form);  // segment data and sorting root container div
    this.items        = {};                                                // map of {internal id: item/segment object}
    this.sortables    = [];                                                // Sortable objects container
    this.lastID       = 0;                                                 // last internal ID number
    this.types        = Object.keys(data);                                 // array of useable segment types
    this.type         = this.selectType.value;                             // currently selected segment type (ReportSet::TYPES)
    this.persistent   = true;                                              // don't destroy this object after AJAX requests (ff.OBJECTS)
    this.changed      = false;                                             // segment data has been changed flag
    this.initialized  = false;                                             // initialized flag
    this.report       = parentForm.id == 'report_form';                    // report vs reportset flag

    this._initUI();
    this._initEvents();

    ff.updateObjects(this.inputSetData, this);
    this.initialized = true;
  }

  //---------------------------------------------------------------------------------------------------------
  // Private Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * General Initialization after instantiating
   */
  _initUI() {
    // allow UI to load then stretch the root div
    const root = this.root;

    ff.initOnce(()=>{
      setTimeout(()=>{
        root.classList.remove('hidden');
        const maxHeight   = window.innerHeight - root.offsetTop - ff.FOOTER_PADDING;
        root.style.height = `${maxHeight}px`;
      }, 25);
    });

    // add hidden inputs to the parent form for report set data and info
    const fragment          = document.createDocumentFragment();
    const input             = document.createElement('input');
    input.type              = 'hidden';
    this.inputSetData       = input.cloneNode();
    this.inputSetData.name  = this.report ? 'set_data' : 'report_set[set_data]';
    this.inputSetName       = input.cloneNode();
    this.inputSetName.name  = 'set_name';
    this.inputSetType       = input.cloneNode();
    this.inputSetType.name  = 'set_type';
    fragment.appendChild(this.inputSetData);
    fragment.appendChild(this.inputSetName);
    fragment.appendChild(this.inputSetType);
    this.parentForm.appendChild(fragment);

    this._initData();
    this.refresh();
  }

 /**
   * Initialize the events
   */
  _initEvents() {
    this.parentForm.addEventListener('submit', event => this._submit(event));

    this.form.addEventListener('change', event => {
      const $el = event.target;

      if (this.selectLoad && ($el == this.selectLoad)) this.loadSet();
      else if ($el == this.selectType) {
        this.type = parseInt($el.value);
        this.refresh();
      }
    });

    this.form.addEventListener('click', event => {
      const $el     = event.target;
      let   refresh = false;

      if ($el.id == 'show_all') {
        const action = $el.checked ? 'max' : 'min';
        ff.getAll(this._getClass('group', 'div'),  this.root).forEach($group => this._toggleGroup($group, action));
      }
      else if ($el.id == 'select_all') {
        const classes = [this._getClass('group', 'div'), this._getClass('item', 'div')].join(',');
        ff.getAll(classes,  this.root).forEach($div => this._selectItem($div, $el.checked));
        refresh = true;
      }
      else if ($el.matches(this._getClass('box', 'i'))) {
        this._selectItem($el.closest('div'));
        refresh = true;
      }
      else if ($el.matches(this._getClass('label', 'span'))) {
        this._editItem($el.closest('div'));
      }
      else if ($el.closest('a.new_accumulator')) {
        ff.stopEvent(event);
        this._addGroup();
      }
      else if ($el.closest('a.sort_segments')) {
        ff.stopEvent(event);
        this._sortItems();
      }
      else if ($el.closest('i.report-set-toggle')) this._toggleGroup($el.closest('div'));

      if (refresh) this.refresh();
    });
  }

  /**
   * Initialize the segment data
   */
  _initData() {
    this.items = {}; // reset items map

    if (!this.types.includes(this.type)) this.type = this.types[0];

    for (const [type, items] of Object.entries(this.data)) {
      items.forEach(item => {
        item.id = this.lastID++; // assign an internal id to each segment item object

        this.items[item.id] = item;
        (item.items || []).forEach(i => {
          i.id = this.lastID++;
          this.items[i.id] = i;
        });
      });
    }
  }

  /**
   * Add a new Accumulator Group
   */
  _addGroup() {
    const id    = this.lastID++;        // next internal ID
    const group = {
      id:       id,                     // internal ID for this JS object only
      number:   -1*id,                  // external ID for serialization, groups must be negative
      name:     'New Accumulator',
      acc:      'group',
      min:      true,
      items:    []
    };

    this._editItem(null, group);
  }

  /**
   * Edit an Item
   *
   * @param   {Element}   $div    - Group/Item div to edit
   * @param   {Object}    group   - new Group object to add
   */
  _editItem($div, group=null) {
    const item = group || this.items[$div.dataset.id];

    if (item) {
      const label = item.acc ? 'Accumulator' : 'Item';
      let   types = '';

      if (item.acc && this.type >= 2) {
        // group type options for everything but Funds and CostCenter Groups
        types = `
          <label for="type" class="required">Type</label>
          <div>
            <label><input type="radio" name="acc" value="group" ${item.acc == 'group' ? 'checked="checked"' : ''}><span>Group</span></label>
            <label><input type="radio" name="acc" value="subtotal" ${item.acc == 'subtotal' ? 'checked="checked"' : ''}><span>Subtotal</span></label>
          </div>
          <div></div>
          <ul class="italic">
            <li>Group - items in a group are combined into a single line total</li>
            <li>Subtotal - each item in the group is displayed and then subtotaled</li>
          </ul>
        `.trim();
      }

      const content = `
        <form id="item_edit_form" class="ff-form">
          Enter the ${label} detail below and click 'OK' to confirm and close.
          <div class="grid" style="grid-template-columns: auto 1fr; margin-top: 1em">
            <label for="name" class="required">Name</label>
            <input type="text" id="name" name="name" maxlength="50" required="required" value="${item.name}">
            ${types}
          </div>
        </form>
      `.trim();

      const buttons = [
        {
          label: 'ok',
          callback: (modal, button) => {
            const form = ff.get('#item_edit_form');
            if (form && form.reportValidity()) {
              const data = new FormData(form);
              item.name  = data.get('name');
              if (item.acc) item.acc = data.get('acc') || item.acc; // get new acc or keep existing

              modal.close();

              if (group) {
                // add group
                const html = this._getGroupHTML(group);
                this.data[this.type].unshift(group);                // insert new group to the beginning of data[type]
                this.items[group.id] = group;                       // add to the items object
                this.root.insertAdjacentHTML('afterbegin', html);   // insert into DOM
              }

              this.refresh(true);
            }
          }
        }
      ];

      // only allow deletion of existing items (accumulators)
      if (item.acc && !group) buttons.push(
        {
          label:    'delete',
          class:    'ff-button-alert',
          callback: (modal, button) => {
            button.blur();

            if (window.confirm('Delete this Accumulator?')) {
              const $parent  = ff.get(`div[data-id="${item.id}"]`, this.root).parentElement;
              let   lastNode = $parent;

              // remove the nested class and move each item to the root node where the group was
              ff.getAll(this._getClass('item', 'div'), $parent).forEach($item => {
                $item.classList.remove(this._getClass('nested'));
                ff.insertAfter(lastNode, $item);
                lastNode = $item;
              });

              $parent.remove();
              this._serialize();
              this.refresh();
              modal.close();
            }
          }
        }
      );

      new FastFundModal({title: `${label} Edit`, cancel: true, content: content, buttons: buttons});
    }
  }

  /**
   * Returns a DIV class name
   *
   * @param     {String}   klass   DIV class key (for CLASSES)
   * @param     {String}   prefix  Class prefix for
   * @returns   {String}   DIV class name
   */
  _getClass(klass, prefix=null) {
    return [prefix ? `${prefix}.` : null, FastFundReportSet.CLASSES[klass]].join('');
  }

  /**
   * Returns HTML content for a Group
   *
   * @param     {Object}   group - ReportSet group object
   * @returns   {String}   HTMl content
   */
  _getGroupHTML(group) {
    // groups need a container for their items and an outer container for the entire content
    const itemHTML = this._getItemHTML(group);
    const klass    = [this._getClass('sortable'), this._getClass('items_container')].join(' ');
    const items    = (group.items || []).map(i => this._getItemHTML(i, group)).join('\n');

    return `<div class="${this._getClass('group_container')}">${itemHTML}<div class="${klass}">${items}</div></div>`;
  }

  /**
   * Returns HTML content for an item row
   *
   * @param     {Object}   item    - ReportSet item
   * @param     {Object}   parent  - Parent ReportSet item if item is nested
   * @returns   {String}   HTMl content
   */
  _getItemHTML(item, parent) {
    const boxClasses = [FastFundReportSet.CHECKBOX_ICONS.base];
    const divClasses = [];
    const html       = [];
    let   total      = 1;
    let   selected   = 0;

    if (item.acc) {
      // accumulator / group
      total       = item.items.length;
      selected    = item.items.filter(i => i.selected).length;
      const grp   = (item.acc == 'group' ? 'Group: ' : 'Subtotal: ') + `${selected}/${total}`;
      const caret = (item.min || total == 0) ? FastFundReportSet.TOGGLE_ICONS.min : FastFundReportSet.TOGGLE_ICONS.max;

      divClasses.push(this._getClass('group'));
      html.push(`<i title="Show/Hide Items" class="report-set-toggle fas ${caret}"></i>`);
      html.push(`<span class="${this._getClass('label')} ${this._getClass('group')}" title="Click to Edit">${item.name} (${grp})</span>`);
    }
    else {
      const label = `${item.number}&nbsp;&ndash;&nbsp;${item.name}`;
      divClasses.push(this._getClass('item'));

      if (item.selected) selected++;
      if (parent) {
        divClasses.push(this._getClass('nested'));
        if (parent.min) divClasses.push('hidden');
      }

      html.push(`<span class="${this._getClass('label')} ${this._getClass('item')}" title="Click to Edit">${label}</span>`);
    }

    if (selected == 0) boxClasses.push(FastFundReportSet.CHECKBOX_ICONS.none);
    else if (selected == total) boxClasses.push(FastFundReportSet.CHECKBOX_ICONS.all);
    else boxClasses.push(FastFundReportSet.CHECKBOX_ICONS.some);

    return `
      <div class="${divClasses.join(' ')}" data-id="${item.id}">
        <i class="fas fa-grip-dots-vertical sortable-handle" title="Drag to Sort"></i>
        <i class="${boxClasses.join(' ')}" title="Select/Deselect ${item.acc ? 'Items' : 'Item'}"></i>
        ${html.join('\n')}
      </div>
    `.trim();
  }

  /**
   * Change/Refresh the display for the given segment type
   *
   * @param   {Boolean}   serialize - Serialize if true
   */
  _refresh(serialize=false) {
    const html = [];

    // serialize the current type DOM items before refreshing
    if (serialize) {
      if (this.selectLoad) this.selectLoad.value = ''; // the selected set is not longer applicable if changed
      this._serialize();
    }

    (this.data[this.type] || []).forEach(item => {
      if (item.acc) html.push(this._getGroupHTML(item));
      else html.push(this._getItemHTML(item));
    });

    this._setChanged();
    this.destroy();
    this.root.innerHTML = html.join('\n');

    // initialize the sortable
    ff.getAll(this._getClass('sortable', 'div'), this.form).forEach($el => {
      this.sortables.push(new Sortable($el, {
        animation:              150,
        //delay:                  25,                     // delay before sorting
        //fallbackTolerance:      2,
        //emptyInsertThreshold:   5,                      // px, distance mouse must be from empty sortable to insert drag element into it
        fallbackOnBody:         true,                   // appends the cloned DOM Element into the Document's Body
        forceFallback:          true,
        multiDrag:              true,                   // single click handle to select multiple items
        //invertSwap:             true,                   // Will always use inverted swap zone if set to true
        //swapThreshold:          1,
        direction:              'vertical',
        dataIdAttr:             'data-rsid',            // HTML attribute that is used by the `toArray()` method
        group:                  'report-set',
        handle:                 '.sortable-handle',
        selectedClass:          'sortable-selected',    // multi-drag selected class
        onStart: event => {
          const groups = Array.from(ff.getAll(this._getClass('group', 'div'),  this.root));

          if (event.item.matches(this._getClass('group_container', 'div'))) {
            // moving group - hide all groups
            groups.forEach($div => this._toggleGroup($div, 'min'));
          }
          else {
            // moving item - hide all groups except this item's parent
            //const $group = event.item.closest(this._getClass('group_container', 'div'))?.children[0];
            //groups.filter($div => $div != $group).forEach($div => this._toggleGroup($div, 'min'));
          }
        },
        onEnd: event => {
          // maximize the target group after dropping an item
          if (event.item.matches(this._getClass('nested', 'div'))) {
            const $group = event.item.closest(this._getClass('group_container', 'div')).children[0];
            if ($group) this._toggleGroup($group, 'max');
          }

          this.refresh(true);
        },
        onChange: event => {
          if (event.item.matches(this._getClass('item', 'div'))) {
            const items = event.items.length > 0 ? event.items : [event.item];

            if (event.to == this.root) items.forEach(i => i.classList.remove(this._getClass('nested')));
            else items.forEach(i => i.classList.add(this._getClass('nested')));
          }
        },
        onClone: event => {
          if (event.item.matches(this._getClass('group_container', 'div'))) {
            // minimize the group being moved so its cloned element is minimized when created
            this._toggleGroup(event.item.children[0], 'min');
          }
        },
        onMove: event => {
          if (event.dragged.matches(this._getClass('group_container', 'div'))) {
            // groups can only be moved within the root, not within another group
            return event.to == this.root;
          }
          else return true;
        },
        onSelect: event => {
          // check the element selected for multidrag - only items can be selected
          if (event.item.matches(this._getClass('group_container', 'div'))) {
            Sortable.utils.deselect(event.item);
          }
        }
      }));
    });
  }

  /**
   * Selected/Deselect an Item
   *
   * @param   {Element}   $div    - Group/Item div to select/deselect
   * @param   {String}    action  - forced state true/false
   */
  _selectItem($div, selected=null) {
    const item = this.items[$div.dataset.id];
    let   items;

    if (item) {
      if (item.acc) items = item.items;  // all items in the group
      else items = [item];               // single item

      items.forEach(i => i.selected = (selected == null) ? !i.selected : selected);
    }
  }

  /**
   * Mark the parent form changed after any changes to this set
   */
  _setChanged() {
    if (this.initialized && this.parentForm) {
      this.changed = true;
      ff.formChange(this.parentForm, true);
    }
  }

  /**
   * Serialize the DOM data after sorting or before submit
   */
  _serialize() {
    // serialize the currently displayed items
    const items = [];

    // loop through all top level items, nested items will be accessed from their parent group
    Array.from(this.root.children).forEach($div => {
      let item = null;

      if ($div.matches(this._getClass('item', 'div'))) item = this.items[$div.dataset.id];
      else if ($div.matches(this._getClass('group_container', 'div'))) {
        // GROUP
        item = this.items[$div.children[0].dataset.id];
        if (item) {
          item.items = []; // reset this group's items based on the current DOM layout

          Array.from($div.children[1].children).forEach($item => {
            const child = this.items[$item.dataset.id];
            if (child) item.items.push(child);
          })
        }
      }

      if (item) items.push(item);
    });

    this.data[this.type] = items;
  }

 /**
   * Sort items in ascending order
   */
  _sortItems() {
    this._serialize(); // ensure current display is serialized

    this.data[this.type] = this.data[this.type].sort((a, b) => {
      // sort nested group items
      if (a.acc) a.items = a.items.sort((a,b) => a.number > b.number ? 1 : -1);
      if (b.acc) b.items = b.items.sort((a,b) => a.number > b.number ? 1 : -1);
      // sort main items
      if (!a.acc && !b.acc) return (a.number > b.number ? 1 : -1);  // compare numbers for items
      else if (a.acc && !b.acc) return -1;                          // groups before items
      else if (!a.acc && b.acc) return 1;                           // groups before items
      else return (a.name > b.name ? 1 : -1);                       // compare names for groups
    });

    this._refresh();
    ff.highlightEffect(this.root, 'info');
  }

 /**
   * Serialize ReportSet data for submit
   *
   * @param   {Event}   event - Submit event
   */
  _submit(event) {
    this.inputSetData.value = '';
    this.inputSetName.value = '';
    this.inputSetType.value = this.type;

    if (this.report) {
      // reports with Fund segments require at least 1 to be selected
      if (this.data[0]) {
        const funds    = Object.values(this.data[0]);
        let   selected = false; // funds.filter(fund => !fund.selected);

        funds.forEach(fund => {
          if (fund.acc) fund.items.forEach(f => selected = selected || f.selected);
          else selected = selected || fund.selected;
        });

        if (!selected) {
          new FastFundModal({title: 'Error', content: 'No Funds have been selected.'});
          ff.stopEvent(event);
          return;
        }
      }
    }

    if (this.changed || this.report) {
      // save the set name if it exists
      if (this.inputSetName && this.selectLoad) {
        let option;
        if (this.selectLoad.value > 0) option = Array.from(this.selectLoad.options).filter(o => o.selected && !o.disabled)[0];
        this.inputSetName.value = option ? option.text : '';
      }
      // ensure current type is serialized before submitting
      this._serialize();
      // remove the internal IDs from all objects & renumber the groups
      const data  = ff.deepClone(this.data);
      let   num   = 0; // renumber all groups to ensure they each have a unique value
      for (const [type, items] of Object.entries(data)) {
        items.forEach(item => {
          delete item.id;
          if (item.number < 0) item.number = --num; // only renumber groups (group numbers must be negative!)
          (item['items'] || []).forEach(i => delete i.id);
        });
      }
      // finally, save JSON data to the form input
      this.inputSetData.value = JSON.stringify(data);
    }
  }

  /**
   * Show/Hide the items in a group
   *
   * @param   {Element}   $div    - Group div to toggle
   * @param   {String}    action  - forced state min/max
   */
  _toggleGroup($div, action=null) {
    const item = this.items[$div.dataset.id];

    if (item) {
      if ((item.min && action == 'min') || (!item.min && action == 'max')) {
        // nothing to do, group is already in the requested state
      }
      else {
        const $caret = ff.get('i.report-set-toggle', $div);
        item.min = action ? (action == 'min') : !item.min;

        Object.values(FastFundReportSet.TOGGLE_ICONS).forEach(icon => $caret.classList.remove(icon));

        if ((item.min && !action) || action == 'min') {
          // minimize group
          Array.from($div.nextElementSibling.children).forEach(c => c.classList.add('hidden'));
          $caret.classList.add(FastFundReportSet.TOGGLE_ICONS.min);
        }
        else if ((!item.min && !action) || action == 'max') {
          // maximize group
          Array.from($div.nextElementSibling.children).forEach(c => c.classList.remove('hidden'));
          $caret.classList.add(FastFundReportSet.TOGGLE_ICONS.max);
        }
      }
    }
  }

  //---------------------------------------------------------------------------------------------------------
  // Public Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Change/Refresh the display for the given segment type
   *
   * @param   {Boolean}   serialize - Serialize if true
   */
  refresh(serialize=false) {
    this._refresh(serialize);
  }

  /**
   * Load an existing ReportSet
   */
  loadSet() {
    if (this.selectLoad) {
      const id = this.selectLoad.value; // save the selected value

      ff.fetch(FastFundReportSet.LOAD_SET_URL, {
        data:       {id: id},
        onSuccess:  data=>{
          if (data) {
            this.data = data;
            this._initData();
            this.refresh();
            ff.highlightEffect(this.root, 'info');

            if (id < 1) {
              // default set data was loaded, reset the select option and enable the ROLLUP option
              this.selectLoad.value = '';
              if (this.boxRollup) ff.toggleElement(this.boxRollup, {disabled: false, checked: true});
            }
            else {
              // disable the ROLLUP option whenever an existing set is loaded,
              // the set may have groups which override the rollup option for reporting
              if (this.boxRollup) ff.toggleElement(this.boxRollup, {disabled: true, checked: false});
            }
          }
          else {
            new FastFundModal({title: 'Error', content: 'An error occurred loading the Report Set data, please try again or contact support.'});
          }
        }
      });
    }
  }

  /**
   * Destroy/Cleanup this object
   */
  destroy() {
    this.sortables.forEach(sortable => { try { sortable.destroy() } catch(e){} });
  }

}
