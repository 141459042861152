'use strict';

/**
 * Modal Class
 *
 * @param   {Object} options - Object of modal options
 */
export default class FastFundModal {
  //---------------------------------------------------------------------------------------------------------
  // Static Variables
  //---------------------------------------------------------------------------------------------------------
  static LIMIT  = 3;            // max number of open modals
  static PREFIX = 'ff-modal';   // class name prefix

  /**
   * @returns {Boolean}    true if any modal is open
   */
  static get exists() {
    return !!ff.get(`div.${FastFundModal.PREFIX}-overlay`);
  }

  //---------------------------------------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------------------------------------
  constructor(options = {}) {
    // merge given options with defaults
    this.options  = Object.assign({
      private:        false,              // true to remove transparency on overlay background
      cancel:         false,              // true to include a default cancel button
      ok:             true,               // true to include a default ok button
      close:          true,               // true to include X for closing and allow ESC to close
      draggable:      true,               // true for draggable windows
      buttons:        null,               // Array of objects of {label: 'button text', callback: func()}
      content:        null,               // modal HTML content
      flash:          null,               // flash/info HTML content
      onClose:        null,               // close callback
      onOpen:         null,               // open callback
      open:           true,               // automatically open on init
      width:          null,               // custom modal width
      title:          'Confirm',          // modal title
    }, options);

    this.overlay   = null;                // modal overlay element
    this.modal     = null;                // actual modal div element
    this.elements  = [];                  // focusable form elements within this.content
    this.callbacks = {};                  // object of {button id: callback func}
    this.id        = this.getClass(ff.randomString(6));

    // bind 'this' to the method so 'this' always refers to our instance
    this.open      = this.open.bind(this);
    this.close     = this.close.bind(this);

    if (this.options.open) this.open();
    return this;
  }

  /**
   * Initialize modal HTML
   */
  _initHTML() {
    let buttonContent = '',
        buttons       = this.options.buttons ? [...this.options.buttons] : [],
        closeContent  = this.options.close ? `<div id="${this.id}_close" class="${this.getClass('close')}" title="Close">&times;</div>` : `<div id="${this.id}_close"></div>`,
        flashContent  = '',
        styles        = [`z-index: ${ff.GLOBALS.zIndex++}`];

    if (this.options.ok && buttons.length == 0) buttons.push({label: 'ok', callback: modal => modal.close()});
    if (this.options.cancel) buttons.push({label: 'cancel', callback: modal => modal.close()});
    if (this.options.width)  styles.push(`width: ${this.options.width}`);
    if (this.options.flash)  flashContent = `<div id="${this.id}_flash" class="${this.getClass('flash')} flash-container">${this.options.flash}<br></div>`;

    for (let i=0; i < buttons.length; i++) {
      const button = buttons[i];
      const label  = button['label'];
      const id     = button['id'] || `${this.id}_${label}_button`;

      if (typeof button['callback'] === 'function') this.callbacks[id] = button['callback'];

      if (button['content']) {
        // use provided content for the button, id must be provided when using a callback!
        buttonContent += button.content;
      }
      else {
        // build a new button
        const klass = button['class'] || `ff-modal-${label}-button`;
        buttonContent += `<button type="button" id="${id}" class="ff-button ${klass}" data-disable-with="${label.toUpperCase()}">${label}</button>`;
      }
    }

    return `
      <div id="${this.id}_modal" class="${FastFundModal.PREFIX}" style="${styles.join(';')}">
        <div id="${this.id}_title" class="${this.getClass('title')}">
          <div class="h5">${this.options.title}</div>
          <div id="${this.id}_buttons" class="${this.getClass('buttons')} ff-button-row">${buttonContent}</div>
          ${closeContent}
        </div>
        <div id="${this.id}_content" class="${this.getClass('content')}">
          ${flashContent}
          ${this.options.content || ''}
        </div>
      </div>
    `.trim();
  }

  /**
   * Initialize events
   */
  _initListeners() {
    // Add ESC key listener to the document if allowed to close
    if (this.options.close) document.addEventListener('keydown', e => {
      if (e.key == 'Escape') {
        // only allow the modal to close on ESC if the active element doesn't use ESC
        // to cancel/close itself (select, date/account widgets, etc.)
        const element = document.activeElement;
        const noClose = (element && element.matches('select,.ff-date,.ff-account'));
        if (!noClose) this.close();
      }
    });

    // listener to keep focus within modal when tabbing
    this.modal.addEventListener('keydown', e => {
      if (e.key == 'Tab') {
        // focus the first focusable element if we tab off the last
        if (e.target === this.elements[this.elements.length-1]) {
          ff.stopEvent(e);
          this.elements[0].focus();
        }
      }
    });

    // Add listeners to the close actions
    let overlayID = this.overlay.id;
    [this.overlay, ff.get(`#${this.id}_close`)].forEach((el) => {
      if (el) el.addEventListener('click', e => {
        if (this.callbacks.hasOwnProperty(e.target.id)) {
          this.callbacks[e.target.id](this, e.target); // button with callback clicked
        }
        else if (overlayID == el.id && (overlayID != e.target.id || !this.options.close)) {
          return; // don't close if the click came from within the modal or we're set not to close
        }
        else {
          this.close();
        }
      });
    });

    // Add a listener to confirmation buttons to handle the rails ujs confirm:complete event
    ff.getAll('button[data-confirm]', this.modal).forEach(b => {
      b.addEventListener('confirm:complete', e => {
        if (Array.isArray(e.detail) && e.detail[0] === true) this.close(); // action was confirmed
        e.target.blur();
      });
    });

    // Event Handler for 'Enter', prevent the form's submit from firing and use the modal Save button instead
    const $modalForm = ff.get('form', this.modal);
    if ($modalForm) {
      $modalForm.addEventListener('keydown', event => {
        if (event.key == 'Enter' && event.target.tagName != 'TEXTAREA') {
          const button = ff.get(`button[id$=_save],button[id$=_ok]`);
          if (button) button.click();
          ff.stopEvent(event);
        }
      });
    }

    // Draggable events
    if (this.options.draggable) {
      // only allow the title div to be draggable so we can select & dbl click inside the modal
      // once the title/handle receives mousedown, then switch draggable to the modal so the entire
      // modal div can move
      const title = ff.get(`#${this.id}_title`);
      title.draggable = true;
      title.classList.add('ff-modal-handle');
      title.addEventListener('mousedown', e => { this.modal.draggable = true;  title.draggable = false });
      title.addEventListener('mouseup',   e => { this.modal.draggable = false; title.draggable = true  });

      // prevent default events on the droppable
      this.overlay.addEventListener('dragenter', e => e.preventDefault());
      this.overlay.addEventListener('dragover',  e => e.preventDefault());

      this.modal.addEventListener('dragstart', e => {
        const $el = document.elementFromPoint(e.clientX, e.clientY);
        if ($el.closest('div.ff-modal-handle')) {
          // only handle the drag event if it originated from the title div,
          // this allows other draggables to function within the modal
          const position = ff.getPosition(e.target);
          //console.log(`cX: ${e.clientX} cY: ${e.clientY} posX: ${position.x} posY: ${position.y} posLeft: ${position.left} posTop: ${position.top}`);
          position.x = position.left - e.clientX;
          position.y = position.top - e.clientY;

          e.dataTransfer.setData('text/plain', JSON.stringify(position));
        }
        else e.dataTransfer.clearData();
      });

      this.overlay.addEventListener('drop', e => {
        const data = e.dataTransfer.getData('text/plain');
        if (data) {
          e.preventDefault();
          const json = JSON.parse(data);
          const endX = e.clientX + json.x;
          const endY = e.clientY + json.y;

          // reposition if moved off screen
          /*
          let width   = parseInt(e.dataTransfer.getData('width'));
          let height  = parseInt(e.dataTransfer.getData('height'));
          if (endX < 0) endX = 0;
          else if ((endX + width) > document.body.offsetWidth) endX = document.body.offsetWidth - width;
          if (endY < 0) endY = 0;
          else if ((endY + height) > document.body.offsetHeight) endY = document.body.offsetHeight - height;
          */

          this.modal.style.left = `${endX}px`;
          this.modal.style.top  = `${endY}px`;
          this.modal.draggable  = false;
        }
      });
    }
  }

  /**
   * Initialize the modal
   */
  _initModal() {
    let self                  = this;
    this.overlay              = document.createElement('div');
    this.overlay.id           = `${this.id}-overlay`;
    this.overlay.className    = this.options.private ? `${this.getClass('overlay')} ${this.getClass('overlay', 'private')}` : `${this.getClass('overlay')}`;
    this.overlay.style.zIndex = ff.GLOBALS.zIndex++; // increment overlay zIndex before adding ff-modal
    this.overlay.innerHTML    = this._initHTML();
    document.body.appendChild(this.overlay);

    this.modal = ff.get(`#${this.id}_modal`);

    this._initListeners();
    if (typeof this.options.onOpen === 'function') this.options.onOpen(this);

    // blur any previously focused element and find something to focus on within this modal
    // note, need to use a timeout so any previous blur/focus events have time to finish before we refocus
    setTimeout(() => { self.updateTabOrder() }, 250);
  }

  //---------------------------------------------------------------------------------------------------------
  // Public Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Generate and return a prefixed modal class name
   *
   * @param   {...String}   names     Class names (eg. getClass(1,2,3))
   * @returns {String}      prefixed class name
   */
  getClass(...names) {
    names = names.filter(n => n);
    return ([FastFundModal.PREFIX].concat(names)).join('-').trim();
  }

  /**
   * Replace the close icon with a spinner
   */
  startSpinner() {
    const $close = ff.get(`#${this.id}_close`);
    if ($close) {
      $close.classList.add(this.getClass('spinner'));
      $close.innerHTML = '<i class="fas fa-cog fa-spin"></i>';
    }
  }

  /**
   * Replace the spinner with the close icon
   */
  stopSpinner() {
    const $close = ff.get(`#${this.id}_close`);
    if ($close) {
      $close.classList.remove(this.getClass('spinner'));
      $close.innerHTML = '&times;';
    }
  }

  /**
   * Open the modal
   */
  open(options = {}) {
    this.close();
    if (ff.getAll(`div.${this.getClass('overlay')}`).length > FastFundModal.LIMIT) alert('modal limit reached');
    else {
      // merge any new options with existing options
      if (!ff.isEmpty(options)) Object.assign(this.options, options);
      this._initModal();
    }
  }

  /**
   * Close the modal
   */
  close() {
    if (this.overlay) {
      // run the onClose callback if any
      if (typeof this.options.onClose === 'function') this.options.onClose(this);

      // destroy any objects that were created in the modal
      ff.getAll(ff.OBJECTS_SELECTOR, this.modal).forEach(function(el, idx, obj) {
        if (el.id && ff.OBJECTS[el.id]) {
          ff.OBJECTS[el.id].destroy();
          delete ff.OBJECTS[el.id];
        }
      });

      // destroy the modal elements
      this.modal.remove();
      this.modal = null;
      this.overlay.remove();
      this.overlay = null;
    }
  }

  /**
   * Refresh the tab order of the modal field elements
   *
   * @param   {Boolean} focus - true to focus the first focusable item after update
   */
  updateTabOrder(focus=true) {
    const content = ff.get(`#${this.id}_content`);
    const buttons = ff.get(`#${this.id}_buttons`);
    // find all focusable elements and buttons, reassign their tabindexes and then focus the first one
    this.elements = Array.from(ff.getAll(ff.FOCUSABLE_SELECTOR, content)).concat(Array.from(ff.getAll(ff.FOCUSABLE_SELECTOR, buttons)));

    ff.setTabIndex(this.elements);

    if (focus) {
      if (document.activeElement) document.activeElement.blur();
      const $el = ff.getFocusable(this.elements);
      try { $el.select() }
      catch(e) { $el.focus() }
    }
  }
}
