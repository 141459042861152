// ----------------------------------------------------------------------------
// Transaction Related Functions
// ----------------------------------------------------------------------------

/**
 * Add rows to a Distribution table
 *
 * @param   {Element}   $container  - Element of distributions
 * @param   {Array}     attrs       - Array of Distribution line attribute definitions
 * @param   {Array}     lines       - Array of Distribution line objects
 * @param   {Boolean}   focus       - true to focus the first item of the last line
 * @param   {Boolen}    trigger     - true to trigger the EVENTS.lineAdded event
 * @returns {Element}   last TR element that was added
 */
export function addLine($container, attrs, lines, focus, trigger=true) {
  const $fragment  = document.createDocumentFragment();
  const accounts   = [];  // array of accounts to init after append
  const currencies = [];  // array of currencies to init after append
  const searches   = [];  // array of searches to init after append
  let   $trLast, $el;

  if (lines) lines = ff.isArray(lines) ? lines : Array(lines);
  else lines = [{}];

  for (const line of lines) {
    const lkey         = ff.randomString();
    const cleared      = line.cleared === true;
    const locked       = (line.id && (line.locked || cleared));
    const $tr          = document.createElement('tr');
    $tr.dataset.lkey   = lkey;
    $tr.dataset.locked = !!locked;

    // row attributes
    for (const attr of attrs) {
      $el              = document.createElement(attr.type);
      $el.value        = line.hasOwnProperty(attr.name) ? line[attr.name] : '';
      $el.dataset.lkey = lkey;      // line key to identify all items in a single line/distribution
      $el.dataset.name = attr.name; // real name attribute is set in finalizeForm()

      if (attr.hasOwnProperty('class_name')) $el.className      = attr.class_name;
      if (attr.hasOwnProperty('required'))   $el.required       = !!attr.required;
      if (attr.hasOwnProperty('title'))      $el.title          = attr.title;
      if (attr.hasOwnProperty('pattern'))    $el.pattern        = attr.pattern;
      if (attr.hasOwnProperty('style'))      $el.style.cssText  = attr.style;
      if (attr.hasOwnProperty('readonly'))   { $el.readonly = true; $el.classList.add('readonly'); }

      // copy any data attributes
      if (attr.data) Object.entries(attr.data).forEach(([key, val], index) =>  $el.dataset[key] = val);
      if (attr.hidden) {
        // add hidden inputs directly to the TR
        $el.type = 'hidden';
        if (attr.name == '_destroy') $el.value = '';
        $tr.appendChild($el);
      }
      else {
        const $td = document.createElement('td');

        if (attr.file) {
          if (line.id) {
            if (line.url) {
              $el = document.createElement('span');
              $el.className = 'nowrap';
              $el.innerHTML = `
                <a title="${line.name}" target="_blank" href="${line.url}">
                  ${line.name} <i class="far link-icon fa-external-link"></i>
                </a>
              `.trim();
            }
            else {
              // readonly input field for persisted files with no URL
              $el.type    = 'text';
              $item.value = line.name;
              ff.toggleElement($el, {readonly: true});
            }
          }
          else {
            // new file input
            $el.type     = 'file';
            $el.onchange = function() { ff.checkFileInput(this) };
          }
        }
        else {
          // attribute specific handling
          switch (attr.type) {
            case 'input':
              if (attr.checkbox) {
                $el.type = 'checkbox';
                $td.classList.add('center');

                $el.checked = $el.value == 'true' || $el.value == 1;
                $el.value   = 1; // change the checked value, 1 for true if checked
              }
              else $el.type = 'text';

              if (attr.hasOwnProperty('maxlength')) $el.maxLength = attr.maxlength;
              if (attr.name == 'memo') $el.name = 'dist_memo'; // set a temp name value to allow browser autofill

              // keep track of items that need initializing after appending
              if (/ff-account/.test(attr.class_name)) {
                accounts.push($el);
                if (cleared && line.id) ff.toggleElement($el, {readonly: true});
              }
              else if (/ff-search/.test(attr.class_name)) {
                searches.push($el);
                if (line.subentity_id) $el.dataset.value = line.subentity_id;
                if (line.subentity_name) $el.value = line.subentity_name;
                if (cleared && line.id) ff.toggleElement($el, {readonly: true});
              }
              else if (/ff-decimal/.test(attr.class_name)) {
                currencies.push($el);
                if (cleared && line.id) ff.toggleElement($el, {readonly: true});
                if (attr.nonzero) {
                  $el.pattern = NONZERO_REGEX;
                  $el.title   = 'A non-zero decimal number is required';
                }
              }
              break;
            case 'select':
              $el.innerHTML = attr.options;
              $el.value     = line[attr.name];    // reset value after adding options
              break;
            case 'i':
              if (attr.name == 'cleared') {
                if (cleared) {
                  $el.className = $el.className || 'far fa-check noclick';
                  $td.title     = $el.title || 'Item has been marked as Cleared';
                }
                else $el = document.createElement('span');
              }
              else if (attr.name == 'tooltip') {
                // only add the tooltip icon if tooltip content exists,
                // see application_helper.tooltip()
                if (line['tooltip']) {
                  if (line['error']) $tr.classList.add('error');
                  $el.className       = $el.className + ' fas fa-message-question ff-tooltip';
                  $el.dataset.tooltip = line[attr.name];
                  $el.onmouseover     = function(){if (!this.tooltip) { new FastFundToolTip(this).open()} };
                }
              }
              else if (/sort/.test(attr.name)) {
                // add sortable class to the tr if a sort icon was included
                $tr.classList.add('sortable');
                $el.className = $el.className || 'far fa-arrows-v sortable-handle';
                $el.title     = $el.title || 'Drag to Sort';
              }
              else if (/(rem|del)/.test(attr.name)) {
                if (locked) $el = document.createElement('span');
                else {
                  $el.className = $el.className || 'far fa-times delete-icon';
                  $el.title     = $el.title || 'Remove Line';
                  // add a default onclick handler for remove/delete icons
                  $el.onclick = function(){
                    let remove = true;
                    if (attr.confirm) remove = window.confirm(attr.confirm);
                    if (remove) txn.removeLine(this.closest('tr'), true);
                  };
                }
              }
              else {
                const match = attr.name.match(/(edit|import)/);

                if (match) {
                  if (match[0] == 'edit') {
                    $el.className      = $el.className || 'far fa-edit edit-icon';
                    $el.title          = $el.title || 'Edit Item';
                  }
                  else {
                    $el.className      = $el.className || 'far fa-file-import import-icon';
                    $el.title          = $el.title || 'Import Items';
                  }

                  $el.style.cssText += '; color: var(--primaryBlue); margin: 0 .25em';
                  $td.classList.add('center');
                }
              }

              break;
          }
        }

        $td.appendChild($el);
        $tr.appendChild($td);
      }
    }

    $fragment.appendChild($tr);
    $trLast = $tr;
  }

  // append the fragment to the container and initialize any objects
  $container.appendChild($fragment);

  accounts.forEach(a => new FastFundAccount(a));
  currencies.forEach(c => new FastFundDecimalInput(c));
  searches.forEach(s => new FastFundSearch(s));

  // notify the form that a line was added
  if ($trLast) {
    if (trigger) $trLast.dispatchEvent(ff.EVENTS.lineAdded);
    if (focus) {
      setTimeout(()=>{
        const $focusable = ff.getFocusable($trLast);
        const $div       = $trLast.closest('div.scrollable');

        if ($focusable) {
          if ($focusable.hasOwnProperty('select')) $focusable.select();
          else {
            $focusable.blur();   // Safari workaround
            $focusable.focus();
          }
        }

        if ($div && ($div.scrollHeight > $div.offsetHeight)) {
          setTimeout(()=>{
            $div.scrollTo({behavior: 'smooth', top: $div.scrollHeight});
          }, 50);
        }
      }, 10);
    }
  }

  return $trLast;
}

/**
 * Apply a selected allocation
 *
 * @param   {Element}   $container  - Element of distributions
 * @param   {Element}   $allocation - Allocation select Element
 * @param   {Element}   $amount     - Transaction/Control amount input Element
 * @param   {Array}     attrs       - Array of Distribution line attribute definitions
 * @param   {Array}     allocations - Array of Allocation objects (from Allocation.available_allocations)
 * @param   {Function}  callback    - callback to invoke after allocating
 */
export function applyAllocation($container, $allocation, $amount, attrs, allocations, callback) {
  const id         = $allocation.value;
  const allocation = id ? allocations.find(a => a.id == id) : null;

  if (allocation) {
    const amount    = $amount.getValue();
    const pctType   = allocation.type == 0; // Allocation::PERCENTAGE_TYPE
    let   allocated = false;

    // reset the appeal to match the allocation appeal for FR allocations
    // NOTE - use toggleElement to trigger a change event so gifts are refreshed
    if (allocation.appeal_id) ff.toggleElement(ff.get('#appeal_id'), {value: allocation.appeal_id});

    if (pctType) {
      // Percentage type line
      if (amount == 0) {
        new FastFundModal({
          title:    'Notice',
          content:  'You must enter an amount before applying a Percentage based Allocation.',
          onClose:  ()=>{ $allocation.value = ''; $amount.select() }
        });
      }
      else {
        // Account Only type line
        const lines = allocation.lines;
        let   total = 0.0; // running distribution
        let   amt   = 0.0; // distribution amount
        // remove all lines
        ff.getAll('tr[data-locked="false"]', $container).forEach($tr => txn.removeLine($tr, false, false));
        allocated = true;

        for (let i=0; i < lines.length; i++) {
          const line = lines[i];
          // calculate the dist. amount and add the line
          amt   = ff.toDecimal((i == lines.length-1) ? (amount - total) : amount*(line.percentage/100.0));
          total = ff.toDecimal(total + amt);
          line.amount = amt;
          txn.addLine($container, attrs, line, false, false);
        }
      }
    }
    else {
      // remove all lines
      ff.getAll('tr[data-locked="false"]', $container).forEach($tr => txn.removeLine($tr, false, false));
      txn.addLine($container, attrs, allocation.lines, false, false);
      allocated = true;
    }

    if (allocated) {
      ff.focusNextElement($allocation);
      if (typeof callback === 'function') callback();
    }
  }
}

/**
 * Finalize and index all form elements matching a Rails nested association format
 * so that the form submit can be successfully processed, eg -
 *   <input name="transaction[lines][<index>][memo])">
 *
 * @param   {Element}   $form   - Form Element of nested attributes
 * @param   {Object}    data    - Transaction view data
 */
export function finalizeForm($form, data) {
  const prefix = data.attr_prefix;   // input name prefix (model name)
  let lkey     = null;               // element grouping key
  let idx      = 0;                  // starting line index is 1 (0 is control)

  // reset form's changed flag
  ff.formChange($form, false);

  for (let $el of $form.elements) {
    if ($el.dataset.lkey && $el.dataset.name) {
      if ($el.dataset.lkey != lkey) {
        // change in lkey indicates a new distribution row/group
        lkey = $el.dataset.lkey;
        idx++;
      }
      $el.name = `${prefix}[${idx}][${$el.dataset.name}]`;          // set the name to rails nested attribute format
      if ($el.matches('input[data-name="index"]')) $el.value = idx; // update the actual index value for sorting

      /*
        // TODO - find way for browser autocomplete to work with nested fields, eg: memos[]
        //        currently, only the first value will be cached
        if ($el.dataset.name == 'memo') {
          $el.insertAdjacentHTML('afterend', `<input type="text" name="memos[]" value="${$el.value}">`);
        }
      */
    }
  }
}

/**
 * Handle a chk number/reference change to see if it's been used for the given account,
 * calls the check_reference ajax callback.
 *
 * @param   {Element}   $el       input of the check/reference value
 * @param   {Object}    options   options -
 *                        account_id   - Account ID to scope by
 *                        subentity_id - Subentity ID to scope by (ex. Other_109)
 *                        callback     - callback function
 *                        object_type  - CSV string of Transaction Object Types
 *                                       ex. 'JournalEntry' or 'CashDisbursment,VendorPayment'
 */
export async function checkReference($el, options={}) {
  const CHK_REF_URL = '/ajax/check_reference';
  const value       = String($el.value).trim();

  if (!!currentUser.preferences.ref_check == false || ff.isEmpty(value)) {
    // ensure a given callback is invoked even if we don't perform the check
    if (typeof options.callback === 'function') options.callback();
    return false;
  }
  else {
    return await ff.fetch(CHK_REF_URL, {
      method:     'POST',
      data:       Object.assign(options, {reference: value}),
      onSuccess:  data=>{
        if (String(data) != 'true') {
          // a duplicate was not found
          if (typeof options.callback === 'function') options.callback();
        }
        else {
          // a duplicate was found
          let content;
          if (options.hasOwnProperty('account_id')) {
            content = 'The check number or reference that was entered has already been used for the selected account.';
          }
          else {
            if (!ff.isEmpty(options.subentity_id)) {
              const type = options.subentity_id.split('_')[0];
              content = `The selected reference has already been used for this ${type} and transaction type.`;
            }
            else content = 'The selected reference has already been used for this transaction type.';
          }

          new FastFundModal({
            title:    'Notice',
            content:  `${content}<br><br>Click OK to keep the current selection or CANCEL to clear it.`,
            buttons: [
              {
                label: 'ok',
                callback: (modal, button) => {
                  modal.close();
                  ff.focusNextElement($el);
                  if (typeof options.callback === 'function') options.callback();
                }
              },
              {
                label: 'cancel',
                callback: (modal, button) => {
                  modal.close();
                  $el.value = '';
                  $el.focus();
                  if (typeof options.callback === 'function') options.callback();
                }
              }
            ]
          });
        }
      }
    });
  }
}

/**
 * Initialize the Appeal Select
 *
 * @param   {Element}   $el   - Appeal Select Element
 * @param   {Object}    data  - Appeal and Gift data
 * @param   {String}    value - Appeal value
 */
export function initAppealData($el, data, value) {
  let options = '<option value=""></option>';

  data.appeals.forEach(appeal => {
    let disabled = appeal.inactive == true ? `disabled=""` : '';
    options += `<option value="${appeal.id}" ${disabled}>${appeal.name}</option>`;
  });

  $el.innerHTML = options;
  $el.value     = value;
}

/**
 * Initialize the Gift Selects
 *
 * @param   {Element}   $container  - Element of distributions
 * @param   {Object}    data        - Appeal and Gift data
 * @param   {String}    value       - Appeal value
 */
export function initGiftData($container, data, value) {
  const appeal  = data.appeals.find(a => a.id == value);
  let   options = '<option value=""></option>';

  if (appeal) {
    appeal.gifts.forEach(gift => {
      const disabled = gift.inactive == true ? `disabled=""` : '';
      options += `<option value="${gift.id}" ${disabled}>${gift.name}</option>`;
    });
  }

  // reset any existing gift selects
  ff.getAll('select[data-name="gift_id"]', $container).forEach($gift => {
    const val = $gift.value;
    $gift.options.length = 0;
    $gift.insertAdjacentHTML('afterbegin', options);
    $gift.value = val; // reset the original value
  });

  const gift = data.line_attrs.find(a => a.name == 'gift_id');
  if (gift) gift.options = options;
}

/**
 * Fetch and return a single Subentity object
 *
 * @param   {String}    id        - Subentity ID, ex. Other_12
 * @param   {Function}  callback  - Callback function
 */
export async function getEntityData(id, callback) {
  let data = null;

  if (id) {
    data = await ff.fetch('/ajax/get_entity', {
      data:       ff.toParams({subentity_id: id}),
      onSuccess:  data => { return data },
      onError:    ()=> { return null }
    });
  }

  if (typeof callback === 'function') callback(data);
}

/**
 * Remove rows in a Distribution table with no selected account
 *
 * @param   {Element}   $container  - Element containing TRs to remove
 */
export function removeEmptyLines($container) {
  ff.getAll('tr', $container).forEach($tr => {
    if (ff.getAll('[name$="account_id"][value=""]', $tr).length > 0) {
      txn.removeLine($tr);
    }
  });
}

/**
 * Remove a row from a Distribution table
 *
 * @param   {Element}   $tr     - TR Element to remove
 * @param   {Boolen}    focus   - true to focus an item in the next/previous row
 * @param   {Boolen}    trigger - true to trigger the EVENTS.lineRemoved event
 */
export function removeLine($tr, focus=false, trigger=true) {
  const $id   = ff.get('input[data-name="id"]', $tr);
  const $form = $tr.closest('form');

  if ($id && !ff.isEmpty($id.value)) {
    // this row contains persisted data, mark _destroy and move it out of the table
    // NOTE - use cloned nodes to preserve the original $tr
    const $idClone = $id.cloneNode();
    const $destroy = ff.get('input[data-name="_destroy"]', $tr);
    if ($destroy) {
      const $destroyClone = $destroy.cloneNode();
      $destroyClone.value = '1';
      $form.appendChild($destroyClone);
    }

    $form.appendChild($idClone);
    ff.formChange($form, true);
  }

  // find a next/previous element to focus on
  if (focus) {
    const trs = ff.getAll('tr', $tr.closest('tbody'));
    let   idx = 0;

    for (const $el of trs) {
      if ($el == $tr) break;
      else idx++;
    }

    if (trs[idx+1]) ff.getFocusable(trs[idx+1]).focus();
    else if (trs[idx-1]) ff.getFocusable(trs[idx-1]).focus();
  }

  // finally, remove the $tr
  $tr.remove();
  if (trigger) {
    // NOTE - Create a new lineRemoved event so we can include the removed $tr in 'detail'.
    //        However, dispatching the event from $tr won't work with downstream listeners,
    //        the event must be dispatched AFTER $tr is removed.
    const lineRemoved = new CustomEvent(ff.EVENTS.lineRemoved.type, {bubbles: true, cancelable: true, detail: $tr});
    $form.dispatchEvent(lineRemoved);
  }
}
