'use strict';

/**
 * Multi Select Class
 *
 * @param   {Element}  select  - parent select Element
 */
export default class FastFundMultiSelect {
  //---------------------------------------------------------------------------------------------------------
  // Static Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Returns the default options
   *
   * @returns {Object}
   */
  static get defaultOptions() {
    return {
      base:       null,       // base/positioning element or selector for the menu
      filter:     null,       // true to add a filter
      status:     null,       // true to add additional header links: All Active, All Inactive
      position:   'auto',     // force position to 'top' or 'bottom'
      offset:     0           // menu offset px
    };
  }

  //---------------------------------------------------------------------------------------------------------
  // Main Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Constructor
   *
   * @param   {Element}   select  - parent select Element
   */
  constructor(select) {
    // members
    this.id                 = ff.randomString();  // unique ID
    this.select             = select;             // parent select Element
    this.select.multiselect = this;               // reference to this instance
    this.init               = false;              // init flag
    this.menu               = null;               // FastFundMenu instance
    this.dropDown           = null;               // the proxy dropdown select element
    this.dropDownCount      = null;               // disabled option to show selection count (eg. 1/10)
    this.filter             = null;               // filter input element
    this.options            = Object.assign(FastFundMultiSelect.defaultOptions, select.dataset);
    this.options.base       = this.options.base || this.dropDown;

    // bind 'this' to the method so 'this' always refers to our instance
    this._onMenuSelect      = this._onMenuSelect.bind(this);

    // initialize elements and events
    this._initDropDown();
    this._initEvents();
    this._refresh();
    this.init = true;

    ff.updateObjects(select, this);
  }

  /**
   * Initialize the Drop Down element
   */
  _initDropDown() {
    // create a custom dropdown and add it to the DOM
    this.dropDown      = document.createElement('select');
    this.dropDownCount = new Option('','');       // placeholder element for displaying count (eg. 3/10)
    this.select.hidden = true;                    // ensure original select is hidden
    this.select.classList.add('ff-multiselect');  // ensure original select has multiselect class
    Object.assign(this.dropDown,      {title: this.select.title || 'Selection', className: 'ff-multiselect-label'});
    Object.assign(this.dropDownCount, {disabled: true, selected: true});
    this.dropDown.add(this.dropDownCount);
    ff.insertBefore(this.select, this.dropDown);

    // create the header content
    const status = !this.options.status ? '' : `
      <a href="#" data-select="active" title="All Active"><i class="far fa-user-check"></i>All Active</a>
      <a href="#" data-select="inactive" title="All Inactive"><i class="far fa-user-times"></i>All Inactive</a>
    `.trim();

    // create the filter content
    const filter = !this.options.filter ? '' : `
      <form class="ff-form" id="${this.id}_filter_form">
        <div class="ff-multiselect-filter">
          <label for="${this.id}_filter">Filter</label>
          <input id="${this.id}_filter" type="text" spellcheck="false">
          <a href="#" data-select="trash" title="Clear Filter"><i class="trash far fa-trash-can"></i></a>
        </div>
      </form>
    `.trim();

    const header = `
      <div class="ff-multiselect-header">
        <div class="ff-multiselect-links">
          <a href="#" data-select="all" title="Select All Items"><i class="far fa-check-square"></i>Select All</a>
          <a href="#" data-select="none" title="Deselect All Items"><i class="far fa-square"></i>Deselect All</a>
          ${status}
          <a href="#" class="grid-end" data-select="close" title="Close Menu"><i class="close fas fa-times"></i></a>
        </div>
        ${filter}
      </div>
    `.trim();

    // instantiate the menu and add the headers to its top
    this.menu = new FastFundMenu(this.dropDown, Object.assign({
      content:    this._initMenuOptions(),
      maxHeight:  null,
      onSelect:   this._onMenuSelect,
      //onClose:    (menu)=>{ this.dropDown.focus() }
    }, this.options));

    this.menu.ul.insertAdjacentHTML('beforebegin', header);
    this.filter = ff.get(`#${this.id}_filter`);
  }

  /**
   * Initialize the menu content from the parent select options
   *
   * @returns {String} - HTML content for FastFundMenu
   */
  _initMenuOptions() {
    const  $groups = ff.getAll('optgroup', this.select);
    let    content = [];
    let    _getOption = function($o, gid) {
      const disabled = $o.disabled ? 'disabled="" class="disabled"' : '';
      const text     = $o.innerText.trim();
      const group    = gid ? `data-gid=${gid}` : '';
      const input    = `<input type="checkbox" value="${$o.value}" ${disabled} ${$o.selected ? 'checked' : ''} ${group}>`.trim();
      const label    = `<label>${input}${text}</label>`;
      let   data     = Object.assign({label: text, value: $o.value}, $o.dataset);
      data = Object.entries(data).map(([key,val]) => `data-${key}="${val}"`).join(' ');
      return `<li ${disabled} ${data}>${label}</li>`;
    }

    if ($groups.length == 0) {
      content = Array.from(ff.getAll('option', this.select)).map($o => _getOption($o));
    }
    else {
      $groups.forEach(($group, gid) => {
        gid++;
        const options = Array.from(ff.getAll('option', $group)).map($o => _getOption($o, gid));

        if (options.length > 0) {
          content.push(`
            <li class="group" data-checked="0" data-gid=${gid} title="Click to toggle Group Items">
              <i class="group-icon fa far fa-square"></i>
              <label class="group">${$group.label.trim()}</label>
            </li>
          `.trim());
          content.push(options.join(''));
        }
      });
    }

    return content.join('').trim();
  }

  /**
   * Initialize event listeners
   */
  _initEvents() {
    this.menu.div.addEventListener('keydown',   event => this._onKeyDown(event));
    this.dropDown.addEventListener('keydown',   event => this._onKeyDown(event));
    this.dropDown.addEventListener('click',     event => ff.stopEvent(event));
    this.dropDown.addEventListener('mousedown', event => this.toggleMenu(event));

    if (this.filter) this.filter.addEventListener('keyup', event => this._filter(event));

    // allow a label for the select to open the menu
    const label = ff.get(`label[for="${this.select.id}"]`);
    if (label) label.addEventListener('click', event => this.toggleMenu(event));

    ff.addMutationObserver(this.select, this);
  }

  /**
   * Keyup handler for the filter input
   */
  _filter(event, clear=false) {
    if (this.filter) {
      if (clear) this.filter.value = '';
      const val = this.filter.value.trim().toLowerCase();

      ff.getAll('input', this.menu.ul).forEach($input => {
        const $li = $input.closest('li');

        if ((val.length >= 1) && (!$li.dataset.label.toLowerCase().includes(val))) {
          $input.checked  = false;
          $input.disabled = true;
          $li.classList.add('hidden');
        }
        else {
          $input.disabled = false;
          $li.classList.remove('hidden');
        }
      });

      this._refresh();
    }
  }

  /**
   * Keydown Handler for both menu and proxy select elements
   *
   * @param {Event}    event   - Event object
   */
  _onKeyDown(event) {
    const open = this.menu.isOpen();

    switch (event.key) {
      case 'ArrowUp':
        if (open) this.menu.move('previous');
        break;
      case 'ArrowDown':
        if (open) this.menu.move('next');
        else this.toggleMenu(event);
        break;
      case 'PageDown':
        if (open) this.menu.move('nextPage');
        else this.toggleMenu(event);
        break;
      case 'PageUp':
        if (open) this.menu.move('previousPage');
        break;
      case 'Home':
        if (open) this.menu.move('first');
        break;
      case 'Tab':
        if (open) this.menu.close();
        break;
      case 'End':
        if (open) this.menu.move('last');
        break;
      case ' ':
        if (!open) this.toggleMenu(event);
        break;
      case 'Enter':
        if (!open) this.toggleMenu(event);
        else ff.stopEvent(event);
        break;
      default:
        break;
    }
  }

  /**
   * Select callback handler for the Menu
   *
   * @param   {Element}   $el - Selected element
   */
  _onMenuSelect($el) {
    if ($el) {
      const nodeName = $el.nodeName;

      if ($el.matches('input')) this._refresh(); // input clicked directly or received bubbled event
      else {
        const $a  = $el.closest('a');
        const $li = $el.closest('li.group');

        if ($li) {
          ff.stopEvent();
          const $icon = ff.get('i.group-icon', $li);

          if ($li.dataset.checked == '0') {
            $li.dataset.checked = '1';
            $icon.classList.remove('fa-square');
            $icon.classList.add('fa-square-check');
          }
          else {
            $li.dataset.checked = '0';
            $icon.classList.add('fa-square');
            $icon.classList.remove('fa-square-check');
          }

          ff.getAll(`input[data-gid="${$li.dataset.gid}"]:not([disabled])`, this.menu.ul).forEach($input => {
            $input.checked = $li.dataset.checked == '1';
          });
          this._refresh();
        }
        else if ($a) {
          ff.stopEvent();
          if ($a.dataset.select == 'all') this._refresh(true);            // select all
          else if ($a.dataset.select == 'none') this._refresh(false);     // deselect all
          else if ($a.dataset.select == 'close') this.toggleMenu();       // close menu
          else if ($a.dataset.select == 'active' || $a.dataset.select == 'inactive') {
            // select all actives or inactives
            ff.getAll('input:not([disabled])', this.menu.ul).forEach($input => {
              $input.checked = $input.closest('li').dataset.status == $a.dataset.select;
            });
            this._refresh();
          }
          else if ($a.dataset.select == 'trash') this._filter(null, true);
        }
      }
    }
  }

  /**
   * Updates the select options and dropDown label
   *
   * @param   {Boolen}   checked - true/false to force overwrite all values
   */
  _refresh(checked) {
    let totalChecked = 0;
    let values       = {};  // {select value => true/false}

    // get the values of the menu checkboxes
    ff.getAll('input:not([disabled])', this.menu.ul).forEach(input => {
      if (checked !== undefined) input.checked = checked;
      if (input.checked) totalChecked += 1;
      values[input.value] = input.checked;
    });

    // update the hidden select options to match the menu checkbox selections
    ff.getAll('option', this.select).forEach($o => $o.selected = values[$o.value]);

    // check validity of the parent select and report it on the proxy select
    if (this.select.checkValidity()) this.dropDown.setCustomValidity('');
    else this.dropDown.setCustomValidity('A selection is required');

    this.dropDownCount.innerText = `${totalChecked}/${this.select.options.length} Selected`;
    if (this.init && this.select) Rails.fire(this.select, 'change');
  }

  /**
   * Enable or Disable the options associated with the given values
   *
   * @param   {Array/String}  values   - Array of values or single String
   * @param   {Boolen}        disabled - true to disable, false to enable
   */
  _toggleValues(values, disabled) {
    let refresh = false;
    values      = Array(values);

    for (let li of this.menu.ul.children) {
      if (values.includes(li.dataset.value)) {
        let $input      = ff.get('input', li);
        $input.disabled = disabled;
        refresh         = true;

        if (disabled) {
          $input.checked = false;
          li.classList.add('disabled');
        }
        else li.classList.remove('disabled');
      }
    }

    if (refresh) this._refresh();
  }

  //---------------------------------------------------------------------------------------------------------
  // Public Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Close the select
   */
  close() {
    this.menu.close();
  }

  /**
   * Destroy the menu element
   */
  destroy() {
    this.menu.destroy();
    this.dropDown.remove();
  }

  /**
   * Disable the items associated with the given values
   *
   * @param   {Array/String}  values   - Array of values or single String
   */
  disableValues(values) {
    this._toggleValues(values, true);
  }

  /**
   * Disable this select
   */
  disable() {
    [this.select, this.dropDown].forEach(sel => {
      if (!sel.disabled) sel.disabled = true;
    });
  }

  /**
   * Enable the items associated with the given values
   *
   * @param   {Array/String}  values   - Array of values or single String
   */
  enableValues(values) {
    this._toggleValues(values, false);
  }

  /**
   * Enable this select
   */
  enable() {
    [this.select, this.dropDown].forEach(sel => {
      if (sel.disabled) sel.disabled = false;
    });
  }

  /**
   * Returns an array of selected values
   *
   * @returns {Array} - selected values
   */
  getValues() {
    const values = [];
    if (this.select) Array.from(this.select.selectedOptions).forEach(o => values.push(o.value));
    return values;
  }

  /**
   * Hide this entire account input
   */
  hide() {
    ff.toggleElement(this.dropDown, {hidden: true});
    return this;
  }

  /**
   * Unhide this entire account input
   */
  unhide() {
    ff.toggleElement(this.dropDown, {hidden: false});
    return this;
  }

  /**
   * Toggle the date options menu open/closed
   *
   * @param   {Event} event - Event object
   */
  toggleMenu(event) {
    if (this.menu) {
      // stop bubbling so the document listener will not catch the click and close the menu
      if (event) ff.stopEvent(event);
      this.menu.toggle();

      if (this.menu.isOpen()) this.menu.move('first');
    }
  }

  /**
   * Check or Uncheck all options
   *
   * @param   {Boolen}    checked - true/false
   */
  toggleOptions(checked) {
    this._refresh(checked);
  }

}
