// ----------------------------------------------------------------------------
// Functions related to remote AJAX requests
// ----------------------------------------------------------------------------

/**
 * XXX - Unused function
 * Wrapper for Rails.ajax to include default callbacks
 *
 *   example -
 *       ff.railsAjax({
 *         type:     'GET',
 *         data:     ff.toParams({type: this.value}),
 *         url:      '<%= summary_lists_constituent_path(@item) %>',
*          success:  (response, status, xhr)=>{}
 *       });
 *
 * @param   {Object}   options - Options for Rails.ajax
 */
export function railsAjax(options) {
  // merge the given options with some defaults before called Rails.ajax
  const opts = Object.assign({
    beforeSend: function(xhr, options) {
      ff.startSpinner();
      if (options.beforeSend === 'function') options.beforeSend(xhr, options);
      return true;
    },
    complete: function(xhr, status)  {
      ff.stopSpinner();
      if (options.complete === 'function') options.complete(xhr, status);
      return true;
    },
    error: function(response, status, xhr) {
      return Rails.fire(document.body, 'ajax:error', [response, status, xhr]);
    },
    // set default request type to script -
    //   'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript'
    dataType: 'script'
  }, options);

  return Rails.ajax.call(this, opts);
}

/**
 * Fetch Wrapper
 *
 * @param   {String}   url     - request URL
 * @param   {Object}   options - Options for fetch
 * @returns {Object}   fetch result
 */
export async function fetch(url, options={}) {
  // ---------------------------------------------------------------------------------
  // init the request options
  // ---------------------------------------------------------------------------------
  options = Object.assign({
    method:      'POST',                                              // default method
    data:        null,                                                // data to send in the request body or url
    onComplete:  null,                                                // complete callback
    onSuccess:   null,                                                // success callback
    onError:     null,                                                // error callback
    headers:     Object.assign({                                      // additional request headers
      'X-CSRF-Token':     ff.get('meta[name=csrf-token]')?.content,   // CSRF token
      'X-Requested-With': 'XMLHttpRequest'                            // XHR flag for Rails so we can use respond_to
    }, options.headers)
  }, options);

  const fetchOptions = {
    headers:        options.headers,  // request headers
    method:         options.method,   // *GET, POST, PUT, DELETE, etc.
    cache:          'no-cache',       // *default, no-cache, reload, force-cache, only-if-cached
    mode:           'same-origin',    // no-cors, *cors, same-origin
    referrerPolicy: 'same-origin',    // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    credentials:    'same-origin',    // include, *same-origin, omit
    //redirect:     'follow',         // manual, *follow, error
    //body:         null              // for POST only: JSON encoded string, FormData object, URLSearchParams
  };

  if (options.data) {
    if (ff.isString(options.data)) {
      fetchOptions.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
      fetchOptions.headers['Accept']       = 'text/javascript, */*; q=0.01';

      // String data must be in params form: foo=bar&foo2=bar2
      if (options.method == 'GET') {
        // GET with string data is appended to the URL: https://url?foo=bar&foo2=bar2
        url = (url.includes('?')) ? `${url}&${options.data}` : `${url}?${options.data}`;
      }
      else {
        // POST with string data is added to the body
        fetchOptions.body = options.data;
      }
    }
    else if (options.data instanceof FormData) {
      // FormData is handled natively, Content-Type automatically set to 'multipart/form-data' with boundary
      fetchOptions.headers['Accept']  = 'text/javascript, */*; q=0.01';
      fetchOptions.body               = options.data;
    }
    else {
      // anything that's not a String or FormData is stringified and sent as JSON
      fetchOptions.headers['Content-Type'] = 'application/json; charset=UTF-8';
      fetchOptions.headers['Accept']       = 'application/json, text/javascript, */*; q=0.01';
      fetchOptions.body                    = JSON.stringify(options.data);
    }
  }

  //console.log(options);
  //console.log(fetchOptions);
  // ---------------------------------------------------------------------------------
  // start the request
  // ---------------------------------------------------------------------------------
  let response, data;
  try {
    ff.startSpinner();
    response = await window.fetch(url, fetchOptions);

    if (!response.ok) throw response.status;
    else {
      const contentType = response.headers.get('content-type');
      // only return json data if status is 200 and contentType is json, text content otherwise
      if (response.status == 200 && /\bjson\b/.test(contentType)) data = await response.json();
      else data = await response.text();
      // process the response data
      if (/\b(?:java|ecma)script\b/.test(contentType)) {
        // if the response contentType is javascript and data is a non-empty string,
        // append a script tag with the content to HEAD, execute, then remove it
        if (ff.isString(data) && !ff.isEmpty(data)) ff.execJS(data);
      }
      // onSuccess callback
      if (typeof options.onSuccess === 'function') options.onSuccess(data);
    }
  }
  catch (error) {
    data = null;
    console.error(error);
    if (response) {
      // onError callback
      if (typeof options.onError === 'function') options.onError(response);
      // trigger the ajax:error event with an XHR-like object to be consumed as if it came from Rails.ajax
      const xhr = {status: response.status, responseURL: response.url};
      return Rails.fire(document.body, 'ajax:error', [fetchOptions, response.statusText, xhr]);
    }
  }
  finally {
    // ensure the spinner is stopped
    ff.stopSpinner();
    if (typeof options.onComplete === 'function') options.onComplete(data);
  }

  return data;
}

/**
 * Start the AJAX spinner
 *
 * @param   {Event}   event - Event object
 */
export function startSpinner(event) {
  if (!ff.GLOBALS.ajaxTimeout) {
    ff.GLOBALS.ajaxTimeout = setTimeout(e => {
      ff.get('#araize_logo')?.classList?.add('spinner');
      // only display the page overlay if a modal is not present, modals display their own overlay when opened
      if (!FastFundModal.exists) ff.get('#overlay')?.classList?.remove('hide');
    }, 120);
  }

  // listen for a complete on the target if it's not the document.body (body is already listening)
  if (event && event.target != document.body) {
    event.target.addEventListener('ajax:complete', function() { ff.stopSpinner() });
  }
}

/**
 * Stop the AJAX spinner
 */
export function stopSpinner() {
  clearTimeout(ff.GLOBALS.ajaxTimeout);
  delete ff.GLOBALS.ajaxTimeout;
  ff.get('#overlay')?.classList?.add('hide');
  ff.get('#araize_logo')?.classList?.remove('spinner');
}
