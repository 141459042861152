'use strict';

/**
 * Selector Class - Allows adding/removing options from one select to another
 */
export default class FastFundSelector {
  //---------------------------------------------------------------------------------------------------------
  // Static Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * @returns {String}    HTML content for the menu UL
   */
  static get icons() {
    return `
      <i class="far fa-chevron-double-right" title="Add All" data-type="left:all"></i>
      <i class="far fa-chevron-right" title="Add Selected" data-type="left"></i>
      <i class="far fa-chevron-left" title="Remove Selected" data-type="right"></i>
      <i class="far fa-chevron-double-left" title="Remove All" data-type="right:all"></i>
    `.trim();
  }

  //---------------------------------------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------------------------------------

  /**
   * Constructor
   *
   * @param   {Element/String}   container  - ff-select div selector or Element
   * @param   {Element/String}   input      - Optional input selector or Element for serialized data
   */
  constructor(container, input) {
    // members
    this.container     = ff.isElement(container) ? container : ff.get(container);
    this.input         = input ? (ff.isElement(input) ? input : ff.get(input)) : null;
    this.iconsDiv      = null;    // icon container
    this.leftLabel     = null;    // label element for left title and count
    this.rightLabel    = null;    // label element for right title and count
    this.leftList      = null;    // ul element for left item display
    this.rightList     = null;    // ul element for right item display
    this.tapTimeout    = null;    // tap event timeout id
    this.tapLast       = 0;       // last tap time counter

    // get the first two selects within the container, left/first - unselected, right/last - selected
    [this.left, this.right] = Array.from(ff.getAll('select', this.container)).slice(0,2);

    this.form               = this.input ? this.input.form : this.left.form; // find the form using the input or first select
    this.right.selector     = this;                                          // reference to this
    this.left.selector      = this;                                          // reference to this
    //this.refresh            = ff.debounce(this._refresh, 300);               // debounced refresh function

    // init
    this._initSelects();
    this._initEvents();
    this._refresh();
  }

  //---------------------------------------------------------------------------------------------------------
  // Private Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Initialize the new selector elements for the container grid (1fr auto 1fr)
   */
  _initSelects() {
    // new elements
    const list     = document.createElement('ul');
    list.className = 'ff-menu ff-selector';

    if (this.container.style.height) list.style.height = this.container.style.height;
    else {
      // set a height and max-height if the container doesn't have a height set
      const pos          = ff.getPosition(this.container);
      const height       = window.innerHeight - pos.top - ff.FOOTER_PADDING - 25; // extra 25 for label height
      list.style.cssText = `height: ${height}px; max-height: ${height}px`;
    }

    this.leftList           = list.cloneNode();
    this.rightList          = list.cloneNode();
    this.leftLabel          = document.createElement('label');
    this.rightLabel         = document.createElement('label');
    this.iconsDiv           = document.createElement('div');
    this.iconsDiv.className = 'ff-selector-icons';
    this.iconsDiv.innerHTML = FastFundSelector.icons;

    // fragment will append to the container which is a 3 column grid - 1fr auto 1fr
    const fragment = document.createDocumentFragment();
    fragment.appendChild(this.leftLabel);
    fragment.appendChild(document.createElement('div'));  // spacer
    fragment.appendChild(this.rightLabel);
    fragment.appendChild(this.leftList);
    fragment.appendChild(this.iconsDiv);
    fragment.appendChild(this.rightList);
    this.container.appendChild(fragment);
  }

  /**
   * Initialize event listeners
   */
  _initEvents() {
    if (this.form) this.form.addEventListener('submit', () => this._onSubmit());
    this.iconsDiv.addEventListener('click',     event => this._onIconClick(event));
    this.leftList.addEventListener('click',     event => this._onListClick(event));
    this.rightList.addEventListener('click',    event => this._onListClick(event));
    this.leftList.addEventListener('dblclick',  event => this._onDblClick(event, 'left'));
    this.rightList.addEventListener('dblclick', event => this._onDblClick(event, 'right'));

    this.leftList.addEventListener('touchend',  event => this._onTapEnd(event, 'left'));
    this.rightList.addEventListener('touchend', event => this._onTapEnd(event, 'right'));
  }

  /**
   * Option Dbl Click Handler
   *
   * @param   {Event}    event  - Event object
   * @param   {String}   type   - 'right' or 'left'
   */
  _onTapEnd(event, type) {
    const tapNow  = new Date().getTime();
    const tapDiff = tapNow - this.tapLast;

    this.tapLast = tapNow;
    clearTimeout(this.tapTimeout);

    if (tapDiff < 500 && tapDiff > 0) {
      // double tap
      ff.stopEvent(event);
      this._onDblClick(event, type);
    }
    else {
      // single tap - start the tap timer
      const self = this;
      this.tapTimeout = setTimeout(function() { clearTimeout(self.tapTimeout) }, 500);
    }
  }

  /**
   * Clear all options
   */
  _emptyOptions(select) {
    ['left', 'right'].forEach(type => {
      this[type].options.length = 0;
      this[type].options.length = 0;
    });

    this._refresh();
    if (this.form) ff.formChange(this.form, true);
  }

  /**
   * Option Dbl Click Handler
   *
   * @param   {Event}    event  - Event object
   * @param   {String}   type   - 'right' or 'left'
   */
  _onDblClick(event, type) {
    const el = event.target;
    if (el && el.nodeName == 'LI') {
      // only add/remove the double clicked item
      Array.from(this[`${type}List`].children).forEach(li => {
        //if (el.dataset.value == li.dataset.value) li.classList.add(FastFundMenu.SelectedCSS);
        if (el == li) li.classList.add(FastFundMenu.SelectedCSS);
        else li.classList.remove(FastFundMenu.SelectedCSS);
      })
      this._updateOptions(type);
    }
  }

  /**
   * List Click Handler
   *
   * @param   {Event}   event - Event object
   */
  _onListClick(event) {
    const el = event.target;
    if (el && el.nodeName == 'LI') {
      const ul = el.closest('ul');
      if (ul) {
        if (event.shiftKey) {
          // duplicate a shift click - highlight every LI from the clicked LI back to the previously selected LI or 0
          let found = false;

          for (let i = ul.children.length-1; i >= 0; i--) {
            let li = ul.children[i];

            if (!found) found = (li == el);
            if (found) {
              // we found the clicked LI, start selecting until we reach another selected LI or 0
              if (li.classList.contains(FastFundMenu.SelectedCSS)) break;
              else if (!li.classList.contains('disabled')) li.classList.add(FastFundMenu.SelectedCSS);
            }
          }
        }
        else {
          // regular click - toggle the target
          el.classList.toggle(FastFundMenu.SelectedCSS);
          /*
          // unselect everything but the target
          for (let i = 0; i <= ul.children.length-1; i++) {
            let li = ul.children[i];
            if (li == el) li.classList.add(FastFundMenu.SelectedCSS);
            else li.classList.remove(FastFundMenu.SelectedCSS);
          }
          */
        }
      }
    }
  }

  /**
   * Icon Click Handler
   *
   * @param   {Event}   event - Event object
   */
  _onIconClick(event) {
    const el = event.target;

    if (el && el.nodeName == 'I') {
      const [type, all] = el.dataset.type.split(':');
      this._updateOptions(type, !!all);
    }
  }

  /**
   * Submit Handler - update the designated form input with serialized data
   */
  _onSubmit() {
    if (this.input) this.input.value = this.serialize();
  }

  /**
   * Update the select lists and labels
   */
  _refresh(list) {
    ['left', 'right'].forEach(type => {
      const label = (type == 'left') ? 'Unselected' : 'Selected';
      let count = 0, disabled;
      // generate menu content from the selects
      const options = Array.from(this[type].options).map(o => {
        if (o.disabled) disabled = 'class="disabled"';
        else {
          disabled = '';
          count++;
        }
        return `<li data-value="${o.value}" ${disabled}>${o.text}</li>`;
      });

      ff.empty(this[`${type}List`]);
      if (options.length > 0) this[`${type}List`].innerHTML = options.join('');
      this[`${type}Label`].innerText = `${label} ${this.container.title} (${count})`;
    });
  }

  /**
   * Update options after a list interaction
   *
   * @param   {String}    type - 'right' or 'left'
   * @param   {Boolean}   all  - all options flag
   */
  _updateOptions(type, all) {
    const values  = {}; // cache of LI values that were right from the list
    const sibling = (type == 'right') ? 'left' : 'right';

    Array.from(this[`${type}List`].children).forEach(li => {
      if (!li.classList.contains('disabled') && (all || li.classList.contains(FastFundMenu.SelectedCSS))) {
        values[li.dataset.value] = true;
      }
    });

    for (let i = this[type].length-1; i >=0; i--) {
      const o = this[type].options[i];
      if (values[o.value]) {
        this[type].remove(i);
        this[sibling].add(o);
      }
    }

    if (this[type].length > 0) ff.sortSelectOptions(this[type]);
    if (this[sibling].length > 0) ff.sortSelectOptions(this[sibling]);
    if (this.form) ff.formChange(this.form, true);
    this._refresh();
  }

  //---------------------------------------------------------------------------------------------------------
  // Public Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Remove all options from the selects
   */
  empty() {
    this._emptyOptions();
  }

  /**
   * Force a refresh of the lists
   */
  refresh() {
    this._refresh();
  }

  /**
   * Serialize and return the right option data
   *
   * @returns   {JSON}  data
   */
  serialize() {
    const opts = Array.from(this.right.options).map(o => { return {id: o.value, name: o.text} });
    return JSON.stringify(opts);
  }

  /**
   * Returns the selected (right side) values
   *
   * @returns   {Array}  data
   */
  selected() {
    return Array.from(this.right.options).map(o => o.value);
  }

}
