// ----------------------------------------------------------------------------
// Misc. Utility Functions
// ----------------------------------------------------------------------------

// Returns true if the given obj matches the function
export function isArray(obj)     { return Array.isArray(obj); }
export function isArguments(obj) { return toString.call(obj) == `[object Arguments]`; }
export function isBoolean(obj)   { return toString.call(obj) == `[object Boolean]`; }
export function isDate(obj)      { return toString.call(obj) == `[object Date]`; }
export function isFunction(obj)  { return toString.call(obj) == `[object Function]`; }
export function isRegExp(obj)    { return toString.call(obj) == `[object RegExp]`; }
export function isString(obj)    { return toString.call(obj) == `[object String]`; }
export function isNumber(obj)    { return !isNaN(obj) && toString.call(obj) == `[object Number]`; }

/**
 * Returns true if the given element is not disabled and not hidden
 *
 * @param {Object} obj - javascript object
 */
export function isActive(el) {
  return (ff.isElement(el) && !el.disabled && !ff.isHidden(el));
}

/**
 * Returns true if the given object is a DOM Element
 *
 * @param {Object} obj - javascript object
 */
export function isElement(obj) {
  return (obj instanceof Element);
}

/**
 * Returns true if the given object is empty or null
 *
 * @param {Object} obj - javascript object
 */
export function isEmpty(obj) {
  if (obj == null) return true;  // handles nulls and undefined
  else if (ff.isBoolean(obj)) return !obj;
  else if (obj.hasOwnProperty('length')) return obj.length === 0;
  else if (ff.isObject(obj)) return Object.keys(obj).length === 0;
  else return false;
}

/**
 * Returns true if the given element is a useable form element
 *
 * @param {Element} el       - Element object
 * @param {Boolean} buttons  - True to include submit buttons
 */
export function isFormInput(el, buttons) {
  if (el instanceof Element) {
    if (el && el.clientHeight > 0 && ['input', 'select', 'textarea'].includes(el.nodeName.toLowerCase())) {
      // exclude buttons
      if (buttons || !['submit','reset'].includes(el.type)) {
        if ((typeof el.disabled === 'undefined' || el.disabled === false) && (typeof el.readOnly === 'undefined' || el.readOnly === false)) {
          return true;
        }
      }
    }
  }
  return false;
}

/**
 * Returns true if the given value is numeric
 *
 * @param   {Number/String}  value - value to check
 * @returns {Boolean}
 */
export function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

/**
 * Returns true if the given object is an Object
 *
 * @param   {Object}  obj - javascript object
 * @returns {Boolean}
 */
export function isObject(obj) {
  const type = typeof obj;
  return type === 'function' || type === 'object' && !!obj;
}

/**
 * Returns true if the given element is visible
 *
 * @param   {Element} el - Element object
 * @returns {Boolean}
 */
export function isHidden(el) {
  return (el.hidden === true || el.offsetParent === null);
}

/**
 * Returns the given string with any URLs replaced as Anchor tags
 *
 * @param   {String}  text - Text to modify
 * @returns {String}
 */
export function autoLink(text) {
  if (ff.isString(text)) {
    // simple URL regex, matches http|https + anything to boundary end
    const regex = /\b(https?:\/\/\S*\b)/gi;
    text = text.replace(regex, '<a href="$1" target="_blank">$1</a>');
  }

  return text;
}

/**
 * Returns a human friendly file size string
 *
 * @param   {Number} a - Number of bytes
 * @returns {String}
 */
export function fileSize(a,b,c,d,e){
 return (b=Math,c=b.log,d=1024,e=c(a)/c(d)|0,a/b.pow(d,e)).toFixed(e?2:0)+' '+(e?'KMGTPEZY'[--e]+'B':'Bytes')
}

/**
 * Serialize a form element using a FormData object
 *
 * @param   {Element}   form - Form Element object
 * @returns {String}    search params
 */
export function serializeForm(form) {
  // https://stackoverflow.com/a/44033425/1869660
  // const data = new FormData(form);
  // return new URLSearchParams(data).toString();

  // polyfill version uses Array.from(FormData object) for the URLSearchParams constructor
  return new URLSearchParams(Array.from(new FormData(form))).toString();
}

/**
 * Deserialize/Populate a form using matching FormData or Object key/value pairs
 *
 * @param {Element}   form  - Form Element object
 * @param {Object}    data  - Object or FormData with form values
 */
export function deserializeForm(form, data) {
  let setFormValue = function(input, val) {
    if (input) {
      switch(input.type) {
        case 'checkbox': input.checked = !!val; break;
        default:         input.value   = val;   break;
      }
    }
  }

  if (data instanceof FormData) {
    // populate via FormData
    const entries = (new URLSearchParams(Array.from(data))).entries();
    let   entry;

    while (!(entry = entries.next()).done) {
      const [key, val] = entry.value;
      setFormValue(form.elements[key], val);
    }
  }
  else {
    // populate via Object
    Object.entries(data).forEach(([key, val]) => {
      setFormValue(form.elements[key], val);
    });
  }
}

/**
 * Fetch a value from a nested object
 *   ex. dig(object, ['name']), dig(object, ['address','city'])
 *
 * @param   {Object}    obj   - Object to dig
 * @param   {Object}    keys  - Array of keys or path string (eg. "address.city")
 * @returns {String}    value or null
 */
export function dig(obj, keys) {
  obj  = obj || {};
  keys = ff.isArray(keys) ? keys : keys.split('.');

  for (let i=0; i < keys.length; i++) {
    if (obj && (obj.hasOwnProperty([keys[i]]) || obj[keys[i]])) obj = obj[keys[i]];
    else {
      obj = null;
      break;
    }
  }

  return obj;
}

/**
 * Serialize fields within the given element to an encoded parameter string
 *
 * @param   {Element}   parent - Element or Form containing form fields, or a single form input
 * @returns {String}    encoded params
 */
export function serialize(parent) {
  let serialized = [];
  let elements   = [];

  if (parent && ff.isElement(parent)) {
    if (ff.isFormInput(parent)) elements.push(parent);  // single form element
    else if (parent.formData === undefined) elements = ff.getAll('[name]:not([disabled])', parent);
    else elements = parent.elements;

    for (let i=0; i < elements.length; i++) {
      let el = elements[i];

      // ignore fields without a name, buttons and disabled fields
      if (el.name && !el.disabled && !['file','reset','submit','button'].includes(el.type)) {
        // if a multi-select, get all selections
        if (el.type === 'select-multiple') {
          for (let n=0; n < el.options.length; n++) {
            if (el.options[n].selected) {
              serialized.push(encodeURIComponent(el.name) + '=' + encodeURIComponent(el.options[n].value));
            }
          }
        }

        // convert field data to a query string
        else if ((el.type !== 'checkbox' && el.type !== 'radio') || el.checked) {
          serialized.push(encodeURIComponent(el.name) + '=' + encodeURIComponent(el.value));
        }
      }
    }
  }

  return serialized.join('&');
}

/**
 * Returns a deep clone of the given object
 *
 * @param   {Object}  obj - Object to clone
 *
 * @returns {Object}
 */
export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Returns a shallow clone of the given object
 *
 * @param   {Object}  obj - Object to clone
 *
 * @returns {Object}
 */
export function shallowClone(obj) {
  return Object.assign({}, obj);
}

/**
 * Formats the given number for accounting and returns a <span> string for innerHTML
 *
 * @param   {Number} amount - Numeric value
 * @param   {String} klass  - CSS class for negative values
 * @returns {String}
 */
export function toAccounting(amount, klass='alert') {
  const curr = ff.toCurrency(amount);

  if (ff.toDecimal(amount) >= 0) return `<span>${curr}</span>`;
  else return `<span class="${klass}">${curr.replace('-', '(')})</span>`;
}

/**
 * Returns a value formatted as currency
 *
 * @param   {Object}          value   - Numeric value
 * @param   {Object}          scale   - Decimal digits to use
 * @param   {Boolean/String}  symbol  - Include the company currency symbol or string
 *
 * @returns {String}
 */
export function toCurrency(value, scale, symbol) {
  let str       = String(ff.toFixed(value, scale));
  let currency  = null;
  let parts     = str.split(currentCompany.currency_separator);
  let a         = parts[0].replace(/\D/g, '');  // get rid of negative
  let b         = parts[1] || '0000000000'.slice(0, scale);

  if (a.length > 3) {
    // Add group symbol to a
    const re = new RegExp('(\\d+)(\\d{3})');
    while(re.test(a)) a = a.replace(re, `$1${currentCompany.currency_delimiter}$2`);
  }

  currency = a + currentCompany.currency_separator + b;
  if (/[(-]/.test(str)) currency = '-'+currency; // add negative back if the str contained '-' or '('
  if (symbol === true)  currency = currentCompany.currency_symbol + currency;
  else if (ff.isString(symbol)) currency = symbol + currency;

  return currency;
}

/**
 * Returns a fixed decimal numeric value
 *
 * @param   {Object} value - Numeric value
 * @param   {Object} scale - Decimal digits to use
 * @returns {Number}
 */
export function toDecimal(value, scale) {
  return Number(ff.toFixed(value, scale));
}

/**
 * Returns a sanely round decimal number as a String using toFixed,
 * multiplying by <multiplier> then dividing by <multiplier> gets <scale> decimals.
 *
 * @param   {Object} value - Numeric value
 * @param   {Object} scale - Decimal digits to use
 * @returns {String}
 */
export function toFixed(value, scale) {
  value            = (value || 0).toString().replace(/[^e\.\-\d]/g,'');     // remove any invalid chars
  scale            = scale || 2;                                            // numeric scale
  const multiplier = Math.pow(10, scale);                                   // get the multipler
  return (Math.round(value*multiplier)/multiplier).toFixed(scale);          // return sanely rounded decimal
}

/**
 * Convert FormData to an Object
 *
 * @param   {Element}   form - Form Element object
 * @returns {Object}
 */
export function toObject(form) {
  let obj = {};
  if (form.nodeName == 'FORM') obj = Object.fromEntries(new FormData(form));
  return obj;
}

/**
 * Convert an object to a URL safe param string (supports nested objects)
 * eg. {name:'foo', age:20} => 'name=foo&age=20'
 *
 * @param {Object} obj      - javascript object
 * @param {String} prefix   - param prefix string
 */
export function toParams(obj, prefix) {
  if (!ff.isObject(obj)) return '';
  else {
    let str = [];

    for (let [key, val] of Object.entries(obj)) {
      let k = prefix ? `${prefix}[${key}]` : key;
      if (val == null) val = '';

      if (ff.isObject(val)) str.push(ff.toParams(val, k));
      else str.push(new URLSearchParams([[k, val]])).toString();
    }

    return str.join('&');
  }
}

/**
 * Returns a random alphanumeric string of the given length
 *
 * @param   {Number} length - string length
 * @returns {String}
 */
export function randomString(length=8) {
  return [...Array(length)].map(_=>(Math.random()*36|0).toString(36)).join('');
}
