'use strict';

/**
 * DropDown Class - Alternative to native HTML single select widget
 *
 * @param   {Element}  select   - parent select Element
 */
export default class FastFundDropDown {
  //---------------------------------------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------------------------------------

  /**
   * Constructor
   *
   * @param   {Element}   select - parent select Element
   */
  constructor(select) {
    // members
    this.select          = select;                            // parent select Element
    this.select.dropdown = this;                              // reference to this instance
    this.menu            = null;                              // FastFundMenu instance
    this.dropDown        = null;                              // the proxy dropdown element
    this.sideMenu        = select.dataset.sidemenu;           // sidemenu dropdown flag
    this.persistent      = select.dataset.persistent;         // persistent object flag for ff.OBJECTS
    this.position        = this.sideMenu ? 'right' : 'auto';  // FastFundMenu position
    this.titleSpan       = null;                              // Title SPAN element
    this.titleText       = select.dataset.label || select.title || 'Action';

    // bind 'this' to the method so 'this' always refers to our instance
    this._onMenuSelect   = this._onMenuSelect.bind(this);

    // initialize elements and events
    this._initDropDown();
    this.dropDown.addEventListener('click', event => this.toggleMenu(event));

    if (select.dataset.table) this._initCounter(select.dataset.table);

    ff.updateObjects(select, this);
  }

  //---------------------------------------------------------------------------------------------------------
  // Private Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Initialize the Drop Down counter - If the parent select has a dataset.table defined, add a change
   * listener to handle and count selected checkboxes.
   *
   * @param   {String}   table - table to use for counting selected checkboxes
   */
  _initCounter(table) {
    const $table = ff.get(`#${table}`);

    if ($table) {
      // anonymous function to get the latest count from selected checkboxes
      const items = ()=>{ return Array.from(ff.getAll('input[type=checkbox]', $table)).slice(1) };
      const self  = this;

      $table.addEventListener('change', event => {
        if (event.target.matches('input[type=checkbox]')) setTimeout(()=>{ self.updateCount(items()) }, 100);
      });
      // update the count label immediately after init
      ff.initOnce(()=>self.updateCount(items()));
    }
  }

  /**
   * Initialize the Drop Down element
   */
  _initDropDown() {
    const icon        = this.position == 'right' ? 'fa-chevron-right' : 'fa-chevron-down';
    const menuOptions = {content: '', position: this.position, onSelect: this._onMenuSelect};
    let   disabled    = '';
    let   menuHTML    = '';

    // create menu items from the select options
    this.select.childNodes.forEach(el => {
      if (el.nodeName == 'OPTGROUP') {
        // Add an optgroup label and its child options
        menuHTML += `<li class="disabled menu-header">${el.label}</li>`;
        el.childNodes.forEach(opt => {
          if (opt.nodeName == 'OPTION') {
            disabled = opt.disabled ? 'class="disabled"' : '';
            menuHTML += `<li data-value="${opt.value}" ${disabled}>${opt.innerText}</li>`;
          }
        })
      }
      else if (el.nodeName == 'OPTION') {
        let klass = el.className;
        if (el.disabled) klass += ' disabled';
        menuHTML += `<li class="${klass.trim()}" data-value="${el.value}">${el.innerText}</li>`;
      }
    });

    if (this.sideMenu) {
      Object.assign(menuOptions, {
        onClose: menu=>{ menu.control.classList.remove('sidemenu-item-selected') },
        onOpen:  menu=>{ menu.control.classList.add('sidemenu-item-selected'); menu.div.classList.add('sidemenu-menu') }
      });
    }

    // add the new element to the DOM and instantiate the menu
    const fragment            = document.createDocumentFragment();
    const dropDownIcon        = document.createElement('i');
    this.dropDown             = document.createElement('div');
    this.titleSpan            = document.createElement('span');
    this.titleSpan.innerText  = this.titleText;
    this.dropDown.title       = this.titleText;
    this.dropDown.className   = [...new Set(['ff-dropdown'].concat(this.select.className.split(' ')))].join(' ');
    dropDownIcon.className    = `fas ${icon}`;

    this.dropDown.appendChild(this.titleSpan);
    this.dropDown.appendChild(dropDownIcon);
    fragment.appendChild(this.dropDown);

    this.select.parentElement.insertBefore(fragment, this.select);
    this.menu = new FastFundMenu(this.dropDown, Object.assign(menuOptions, {content: menuHTML}));
  }

  /**
   * Select callback handler for the Menu
   *
   * @param   {Element}   el - Selected element
   */
  _onMenuSelect(el) {
    if (el && el.dataset.value) {
      // set the hidden select's value to the menu item's selected value
      this.select.value = el.dataset.value;
      // manually trigger a change but do not bubble up because the parent form will be marked changed
      this.select.dispatchEvent(new Event('change', {bubbles: false}));
      this.menu.close();

      if (this.sideMenu) {
        // redirect to href listed in the selected option for sidemenu items
        const option = this.select.options[this.select.selectedIndex];
        if (option && option.dataset.href) window.location.href = option.dataset.href;
      }
    }
  }

  //---------------------------------------------------------------------------------------------------------
  // Public Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Destroy the menu element
   */
  destroy() {
    this.menu.destroy();
    this.dropDown.remove();
  }

  /**
   * Toggle the menu open/closed
   *
   * @param   {Event} event - Event object
   */
  toggleMenu(event) {
    if (this.menu) {
      // stop bubbling so the document listener will not catch the click and close the menu
      event.stopPropagation();
      this.menu.toggle();
    }
  }

  /**
   * Update the title counter
   *
   * @param   {Array}  items - Array of checkbox elements for count data
   */
  updateCount(items) {
    const selected = items.filter(i => i.checked).length;
    this.titleSpan.innerText = `${this.titleText} ${selected}/${items.length}`;
  }

}
