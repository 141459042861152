'use strict';

/**
 * Masked Input Base Class / Mixin
 */
export default class FastFundMaskedInput {
  //---------------------------------------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------------------------------------

  /**
   * Constructor
   *
   * @param   {Element}   input     - input Element
   * @param   {Object}    options   - options
   */
  constructor(input, options={}) {
    this.input              = input;
    this.input.mask         = this;  // save a reference to this mask instance
    this.input.spellcheck   = false;
    this.input.autocomplete = 'off';
    // options
    this.mask               = options.mask       || input.dataset.mask;                       // the mask string
    this.maskChars          = options.maskChars  || input.dataset.maskchars  || '_';          // for dates it's "ymd"
    this.validChars         = options.validChars || input.dataset.validchars || '1234567890'; // valid input chars

    // unique static (non-mask/empty) characters
    this.staticChars = this.mask.replace(new RegExp(`[${this.maskChars}]`, 'g'), '');
    this.staticChars = [...new Set([...this.staticChars])].join('');  // remove duplicates

    // init for standalone instances (dates and accounts extend this class and handle their own init)
    if (options.init) {
      this.input.addEventListener('blur',     event => this._onBlur(event));
      this.input.addEventListener('keydown',  event => this._onKeyDown(event));
      this.input.addEventListener('keypress', event => this._onKeyPress(event));
      this.input.addEventListener('paste',    event => this._onPaste(event));
      this._applyMask();
    }

    this.previous = this.input.value;
    ff.updateObjects(input, this);
  }

  //---------------------------------------------------------------------------------------------------------
  // Private Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Apply the mask to the input
   */
  _applyMask() {
    const value = this.input.value;
    let output  = '';

    for (let i = 0, len = this.mask.length; i < len; i++) {
      const valueChar = (value[i] || '').toLowerCase();
      const maskChar  = this.mask[i];

      if (this.maskChars.includes(maskChar)) {
        if (this.validChars.includes(valueChar)) {
          if (!value[i]) output += maskChar;
          else output += value[i] || '';
        }
        else output += maskChar;
      }
      else output += maskChar;
    }

    this.input.value = output;
    this.input.setSelectionRange(0,1);
  }

  /**
   * Ensure the caret position is on a valid input
   */
  _checkPosition() {
    const pos = this.input.selectionStart || 0;
    if (pos >= this.mask.length || !this._validPosition(pos)) this._selectNextChar();
  }

 /**
   * Delete the character at the given position and shift remaining left
   *
   * @param   {Integer}   pos - Input position
   */
  _deleteChar(pos) {
    const blank   = this.maskChars[0];
    const currVal = this.input.value;
    const newVal  = currVal.substr(0, pos).split('');

    for (let i = pos; i < this.mask.length; i++) {
      if (this.staticChars.includes(currVal[i])) newVal.push(currVal[i]);
      else {
        const nextChar = currVal[i+1];

        if (nextChar) {
          if (this.staticChars.includes(nextChar)) newVal.push(currVal[i+2] || blank);
          else newVal.push(nextChar);
        }
        else newVal.push(blank);
      }
    }

    // replace blanks/mask chars with properly placed mask chars
    for (let i = 0; i < newVal.length; i++) {
      if (this.maskChars.includes(newVal[i])) newVal[i] = this.mask[i];
    }

    this.input.value = newVal.join('');
    this.input.setSelectionRange(pos, pos+1);
  }

  /**
   * Handle a blur event
   *
   * @param   {Event}   event - Event object
   */
  _onBlur(event) {
    if (this.input.value != this.previous) {
      // dispatch a change event since it was prevented in onKeyPress
      this.previous = this.input.value;
      this.input.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}));
    }
  }

  /**
   * Handle a keydown event
   *
   * @param   {Event}   event - Event object
   */
  _onKeyDown(event) {
    const pos = this.input.selectionStart || 0;
    let stop  = true;

    switch (event.key) {
      case 'Backspace':
        this._removeChar(pos);
        this._selectPreviousChar(pos);
        break;
      case 'Delete':
        this._deleteChar(pos);
        break;
      case 'Home':
        this.input.setSelectionRange(0, 1);
        break;
      case 'End':
        this.input.setSelectionRange(this.mask.length-1, this.mask.length);
        break;
      case 'ArrowLeft':
        this._selectPreviousChar();
        break;
      case 'ArrowRight':
        this._selectNextChar();
        break;
      case ' ':
        this._removeChar(pos);
        this._selectNextChar();
        break;
      default:
        stop = false;
        break;
    }

    if (stop) ff.stopEvent(event);
    this._checkPosition();
  }

  /**
   * Handle a keypress event on the target input
   *
   * @param   {Event}   event - Event object
   */
  _onKeyPress(event) {
    const key = String(event.key).toLowerCase();

    if (key.length == 1) {
      const pos   = this.input.selectionStart || 0;
      const valid = (this.maskChars.includes(this.mask[pos]) && (this.validChars.includes(key)));

      event.preventDefault();

      if (valid) {
        this._setChar(pos, key);
        this._selectNextChar();
      }
    }
  }

  /**
   * Handle a paste event
   *
   * @param   {Event}   event - Event object
   */
  _onPaste(event) {
    ff.stopEvent(event);
    const text  = ((event.originalEvent || event).clipboardData.getData('text/plain')).trim();
    let   paste = true;

    // only allow a paste if the clipboard data matches the mask
    if (text.length != this.mask.length) paste = false;
    else {
      for (let i = 0; i < text.length; i++) {
        if (!this.validChars.includes(text[i])) {
          paste = false;
          break;
        }
      }
    }

    if (paste) this.input.value = text;
    else ff.highlightEffect(this.input, 'error');
  }

  /**
   * Remove the character at the given position
   *
   * @param   {Integer}   pos - Input position
   */
  _removeChar(pos) {
    if (pos < 0) pos = 0;
    else if (pos >= this.mask.length) pos = this.mask.length-1;

    if (pos == 0 && ((this.input.selectionEnd || 0) >= this.mask.length)) {
      // clear the input if entire value was selected when backspace/del
      this.input.value = this.mask;
    }
    else this._setChar(pos, this.mask[pos]);
  }

  /**
   * Select the first valid input character
   */
  _selectFirstChar() {
    for (let i = 0, len = this.mask.length; i < len; i++) {
      if (this._validPosition(i)) {
        this.input.setSelectionRange(i, i+1);
        break;
      }
    }
  }

  /**
   * Select the next valid input character
   *
   * @param   {Integer}   pos - Input position
   */
  _selectNextChar(pos) {
    pos = (pos >= 0) ? pos : this.input.selectionEnd || 0;
    if (pos >= this.mask.length) this.input.setSelectionRange(this.mask.length-1, this.mask.length);
    else {
      if (this._validPosition(pos)) this.input.setSelectionRange(pos, pos+1);
      else this._selectNextChar(pos+1);
    }
  }

  /**
   * Select the previous valid input character
   *
   * @param   {Integer}   pos - Input position
   */
  _selectPreviousChar(pos) {
    pos = (pos >= 0) ? pos : this.input.selectionStart || 0;
    if (pos <= 0) this.input.setSelectionRange(0, 1);
    else {
      if (this._validPosition(pos-1)) this.input.setSelectionRange(pos-1, pos);
      else this._selectPreviousChar(pos-1);
    }
  }

  /**
   * Set the character(s) at the given position
   *
   * @param   {Integer}   pos - Input position
   * @param   {String}    ch  - Input character
   */
  _setChar(pos, ch) {
    const value = this.input.value || '';

    if (pos < 0) pos = 0;
    else if (pos >= this.mask.length) pos = this.mask.length-1;

    //alert(`pos: ${pos}  0: ${value.substring(0, pos)}  1: ${ch}  2: ${value.substring(pos+1)}`);

    this.input.value = `${value.substring(0, pos)}${ch}${value.substring(pos+1)}`;
    this.input.setSelectionRange(pos, (pos+1) >= this.mask.length ? pos : pos+1);
  }

  /**
   * Returns true if the given character and position are valid against the mask
   *
   * @param    {Integer}   pos - Input position
   * @param    {String}    ch  - Input character (lower cased)
   * @returns  {Boolean}   true if valid
   */
  _validChar(pos, ch) {
    return (this.maskChars.includes(this.mask[pos]) && this.validChars.includes(ch));
  }

  /**
   * Returns true if the given integer is a valid input position
   *
   * @param    {Integer}   pos - Input position
   * @returns  {Boolean}   true if valid
   */
  _validPosition(pos) {
    return (this.maskChars.includes(this.mask[pos]));
  }

  //---------------------------------------------------------------------------------------------------------
  // Public Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Returns the unmasked value
   *
   * @returns  {String}   unmasked value
   */
  unmaskedValue() {
    const value = this.input.value.trim().split('');

    for (let i = 0, len = value.length; i < len; i++) {
      if (this.maskChars.includes(value[i])) value[i] = null;
    }

    return value.filter(c => c).join('');
  }

  /**
   * Destroy
   */
  destroy() {}

  /**
   * Return the unmasked value
   */
  clear() {
    this.input.value = '';
    this._applyMask();
  }

}
