'use strict';
//---------------------------------------------------------------------------------------------------------
// Normalize/Reset CSS - import before all other stylesheets
//---------------------------------------------------------------------------------------------------------
//import 'modern-normalize/modern-normalize.css';

//---------------------------------------------------------------------------------------------------------
// Rails UJS
//---------------------------------------------------------------------------------------------------------
import Rails from '../js/rails-ujs.js';  window.Rails = Rails; Rails.start();

// Remove form exclusion from Rails UJS button selector so we can use
// remote buttons within forms (./actionview-x.x.x/lib/assets/compiled/rails-ujs.js)
// exclude: 'form button'
Rails.buttonClickSelector.exclude = 'none';
// XXX Override rails data-confirm dialog - https://github.com/rails/rails/pull/32404
// Rails.confirm = function(message, element) { custom confirm }

//---------------------------------------------------------------------------------------------------------
// External Libs
//---------------------------------------------------------------------------------------------------------

// SortableJS - https://github.com/SortableJS/Sortable
import {Sortable, MultiDrag} from 'sortablejs'; window.Sortable = Sortable; Sortable.mount(new MultiDrag());

// FlatPickr  - https://github.com/flatpickr/flatpickr
import flatpickr from 'flatpickr'; // adds itself to window
import 'flatpickr/dist/flatpickr.min.css';
import '../js/polyfills/nodelist_foreach.js';

// Ace Editor and HTML mode -  https://github.com/ajaxorg/ace-builds
// ace import for esbuild
import ace from 'ace-builds/src-min-noconflict/ace';
import 'ace-builds/src-min-noconflict/mode-html';
// liquid mode works good for highlighting html/liquid syntax in templates but doesn't have a worker
//import  'ace-builds/src-min-noconflict/mode-liquid';
import htmlWorker from 'ace-builds/src-min-noconflict/worker-html';
ace.config.setModuleUrl('ace/mode/html_worker', htmlWorker);

// no longer needed, fix is in webpack.config  -
//ace.config.setModuleUrl('ace/mode/html_worker', new URL('https://cdn.jsdelivr.net/npm/ace-builds@1.12.0/src-min-noconflict/worker-html.js'));
//ace.config.setModuleUrl('ace/mode/html_worker', new URL('https://cdnjs.cloudflare.com/ajax/libs/ace/1.12.3/worker-html.min.js'));

// ace import for babel
/*
import ace        from 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-html';
ace.config.setModuleUrl('ace/mode/html_worker', new URL('ace-builds/src-noconflict/worker-html.js', import.meta.url));
*/

// Floating-UI - https://github.com/floating-ui/floating-ui
import {computePosition, flip, offset, shift, size} from '@floating-ui/dom';
window.FloatingUI                 = {};
window.FloatingUI.computePosition = computePosition;
window.FloatingUI.flip            = flip;
window.FloatingUI.offset          = offset;
window.FloatingUI.shift           = shift;
window.FloatingUI.size            = size;

// Chart.js - https://github.com/chartjs/Chart.js
import {
  Chart,
  ArcElement,
  LineElement,
  PointElement,
  LineController,
  PieController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip
} from 'chart.js';
Chart.register(
  ArcElement,
  LineElement,
  PointElement,
  LineController,
  PieController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip
);

window.Chart = Chart;

//---------------------------------------------------------------------------------------------------------
// FastFund Libs
//---------------------------------------------------------------------------------------------------------
import * as ff              from '../js/fastfund.js';               window.ff       = ff;       // main functions
import * as ajax            from '../js/ajax_functions.js';         window.ajax     = ajax;     // ajax related
import * as pr              from '../js/pr_functions.js';           window.pr       = pr;       // payroll related
import * as reports         from '../js/report_functions.js';       window.reports  = reports;  // reports related
import * as txn             from '../js/txn_functions.js';          window.txn      = txn;      // txn related
import FastFundAccount      from '../js/fastfund_account.js';       window.FastFundAccount      = FastFundAccount;
import FastFundDate         from '../js/fastfund_date.js';          window.FastFundDate         = FastFundDate;
import FastFundDecimalInput from '../js/fastfund_decimal_input.js'; window.FastFundDecimalInput = FastFundDecimalInput;
import FastFundDropDown     from '../js/fastfund_dropdown.js';      window.FastFundDropDown     = FastFundDropDown;
import FastFundIdle         from '../js/fastfund_idle.js';          window.FastFundIdle         = FastFundIdle;
import FastFundMaskedInput  from '../js/fastfund_masked_input.js';  window.FastFundMaskedInput  = FastFundMaskedInput;
import FastFundMenu         from '../js/fastfund_menu.js';          window.FastFundMenu         = FastFundMenu;
import FastFundModal        from '../js/fastfund_modal.js';         window.FastFundModal        = FastFundModal;
import FastFundMultiSelect  from '../js/fastfund_multiselect.js';   window.FastFundMultiSelect  = FastFundMultiSelect;
import FastFundReportSet    from '../js/fastfund_report_set.js';    window.FastFundReportSet    = FastFundReportSet;
import FastFundSearch       from '../js/fastfund_search.js';        window.FastFundSearch       = FastFundSearch;
import FastFundSelector     from '../js/fastfund_selector.js';      window.FastFundSelector     = FastFundSelector;
import FastFundToolTip      from '../js/fastfund_tooltip.js';       window.FastFundToolTip      = FastFundToolTip;

//---------------------------------------------------------------------------------------------------------
// Images
//---------------------------------------------------------------------------------------------------------

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images    = require.context('../images', true);
const imagePath = (name) => images(name, true);
