// ----------------------------------------------------------------------------
// Misc. Payroll Functions
// ----------------------------------------------------------------------------

/**
 * Calculate a payment amount for the given items
 *
 * @param   {Boolean}  hourly    - hourly allocation method flag (vs percentage)
 * @param   {Object}   employee  - Employee object
 * @param   {Object}   dist      - Distribution object
 * @param   {Object}   payType   - PayType object
 * @param   {Object}   totals    - Totals from pr.getDistributionTotals without current dist amounts
 */
export function calculatePay(hourly, employee, dist, payType, totals) {
  let rate  = ff.toDecimal(dist.rate);     // use rate from the distribution by default
  let hours = ff.toDecimal(dist.hours);
  let units = ff.toDecimal(dist.units);

  if (employee.batch) {
    // Batch editing
    dist.percentage = 0; // updated later in updateBatchTotals

    if (payType.regular) {
      dist.units   = 0;
      dist.amount  = ff.toFixed(rate * hours);
    }
    else {
      // piecemeal
      dist.hours  = 0;
      dist.amount = ff.toFixed(rate * units);
    }
  }
  else {
    // Employee distribution editing
    let empHours = employee.hours;
    let amount   = ff.toDecimal(dist.amount);
    let pct      = ff.toDecimal(dist.percentage, 3);

    if (payType.regular) {
      dist.units = 0;                          // unset units for regular pay
      dist.rate  = employee[payType.rate];     // reset rate to the payType rate
      rate       = ff.toDecimal(dist.rate);

      if (employee.salaried) {
        // use base rate calculation
        if (hourly) pct = (hours/empHours)*100;
        else hours = empHours*(pct/100);
        amount = amount || (employee.base_rate*(pct/100));
      }
      else {
        if (hourly) {
          // calculate via hours
          amount = hours*rate;
          pct    = (hours/empHours)*100;
        }
        else {
          // calculate via pct
          amount = (empHours*(pct/100))*rate;
          hours  = amount/rate;
        }
      }
    }
    else {
      // piecemeal
      amount = amount || (units*rate);
      hours  = 0;
      pct    = 0;
    }

    // check hours and percentage for rounding errors
    // the totals object contains totals for all rows in the distribution
    // table except for the current distribution object
    let newPct = ff.toDecimal(totals.percentage + pct, 3);  // total pct + this distribution pct
    let newHrs = ff.toDecimal(totals.hours + hours);        // total hours + this distribution hours
    //console.log(`curr %: ${totals.percentage} curr hrs: ${totals.hours}  new %: ${newPct} new hrs: ${newHrs}`);

    if (newPct == 100 && newHrs != empHours) {
      // ensure total dist hours == total employee hours when pct == 100
      hours = empHours - totals.hours;
    }
    else if (newHrs == empHours && newPct != 100) {
      // ensure pct == 100 when total dist hours == total employee hours
      pct = 100.0 - totals.percentage;
    }

    // save calculated values
    dist.amount     = ff.toFixed(amount);
    dist.hours      = ff.toFixed(hours);
    dist.percentage = ff.toFixed(pct, 3);
  }
}

/**
 * Return an object of distribution column totals
 *
 * @param   {Element}   $distBody   - tbody Element of distributions
 * @param   {Element}   $trExclude  - tr Element to exclude from totals (when editing)
 * @returns {Object}
 */
export function getDistributionTotals($distBody, $trExclude) {
  let totals   = {amount: 0.0, percentage: 0.0, hours: 0.0};
  let distRows = ff.getAll('tr', $distBody);

  Object.keys(totals).forEach(type => {
    let scale = type == 'percentage' ? 3 : 2;

    // sum each column for totals
    distRows.forEach($tr => {
      if ($trExclude && $tr === $trExclude) {} // skip adding this row's values to the totals
      else totals[type] += ff.toDecimal($tr.dataset[type], scale);
    });
  });

  return totals;
}

/**
 * Return a TD element for a sortable handle
 *
 * @returns {Element}
 */
export function sortableHandle() {
  let td       = document.createElement('td');
  td.innerHTML = '<i class="far fa-arrows-v sortable-handle" title="Drag to Sort"></i>';
  return td;
}
