'use strict';

/**
 * Decimal Mask Class
 */
export default class FastFundDecimalInput {
  //---------------------------------------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------------------------------------

  /**
   * Constructor
   *
   * @param   {Element}   input     - parent input Element
   * @param   {Object}    options   - options
   */
  constructor(input, options) {
    this.options = Object.assign({
      decimalChar:  currentCompany.currency_separator,
      min:          -999999999999.99,
      max:          999999999999.99,
      scale:        2,
      signed:       'true'  // dataset values are strings
    }, input.dataset);

    // members
    this.input      = input;  // parent input Element
    this.input.mask = this;   // add a reference to this instance
    // events
    this.input.addEventListener('keydown', event => this._onKeyDown(event));
    this.input.addEventListener('blur',    event => this._onBlur(event));
    this.input.addEventListener('focus',   event => this._onFocus(event));
    // initialize
    input.classList.add('ff-decimal');
    input.classList.add('right');
    input.setAttribute('autocomplete', 'off');
    input.setAttribute('spellcheck', false);
    input.getValue = this.getValue.bind(this);
    input.setValue = this.setValue.bind(this);
    this.setValue();
    ff.updateObjects(input, this);
  }

  //---------------------------------------------------------------------------------------------------------
  // Private Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Handle a blur event
   *
   * @param   {Event}   event - Event object
   */
  _onBlur(event) {
    let val = this.getValue();

    if (val > Number(this.options.max)) val = this.options.max;
    else if (val < Number(this.options.min)) val = this.options.min;

    this.setValue(val);
  }

  /**
   * Handle a focus event
   *
   * @param   {Event}   event - Event object
   */
  _onFocus(event) {
    const val  = ff.toFixed(this.input.value, this.options.scale);
    const self = this;

    // use a timeout when reformatting the value to ensure correct cursor positioning
    setTimeout(()=>{
      let selStart = self.input.selectionStart || 0;
      let selEnd   = self.input.selectionEnd;
      let nondigs  = self.input.value.slice(0,selStart).match(/[^\d\.]/g);

      selStart = selStart-(nondigs ? nondigs.length : 0);

      self.input.dataset.previous = val;        // save previous value
      self.input.value            = val;

      if (selEnd-selStart < val.length) selEnd = selStart;
      self.input.selectionStart   = selStart;
      self.input.selectionEnd     = selEnd;
    }, 5);
  }

  /**
   * Handle a keydown event
   *
   * @param   {Event}   event - Event object
   */
  _onKeyDown(event) {
    let stop = false;

    if (!isNaN(event.key)) {
      // ok - digit
    }
    else if (event.key === '-') {
      // negative
      const startPos = this.input.selectionStart || 0;
      const endPos   = this.input.selectionEnd || this.input.value.length;

      if ((endPos - startPos) != this.input.value.length) {
        // only handle negative when the entire value is NOT selected
        stop = true;
        if (this.options.signed == 'true') {
          let pos;

          if (this.input.value.includes(event.key)) {
            pos = 0;
            this.input.value = this.input.value.replace('-','');
          }
          else {
            pos = 1;
            this.input.value = `-${this.input.value}`;
          }

          this.input.selectionStart = pos;
          this.input.selectionEnd   = pos;
        }
      }
    }
    else if (event.key === this.options.decimalChar) {
      // only stop event if the value already includes a decimal
      if (this.input.value.includes(event.key)) {
        const pos = this.input.value.indexOf(this.options.decimalChar);
        this.input.selectionStart = pos+1;
        this.input.selectionEnd   = pos+1;
        stop = true;
      }
    }
    else if (event.key.length == 1) {
      // invalid character
      stop = true;
    }

    if (stop) ff.stopEvent(event);
  }

  //---------------------------------------------------------------------------------------------------------
  // Public Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Destroy
   */
  destroy() {}

  /**
   * Return the unformatted, numeric value
   *
   * @returns {Number} - unformatted value
   */
  getValue() {
    return ff.toDecimal(this.input.value, this.options.scale);
  }

  /**
   * Format and set the input's value and return it
   *
   * @param   {Object}   value - Value to format and set or null for current value
   * @returns {String}         - formatted value
   */
  setValue(value) {
    value = (value === undefined) ? this.input.value : value;
    this.input.value = ff.toCurrency(value, this.options.scale);
    return this.input.value;
  }

}
