// ----------------------------------------------------------------------------
// Misc. Functions
// ----------------------------------------------------------------------------

/**
 * Create an event listener with callback to run once then remove itself
 *
 * @param   {Element}   el        - Element object
 * @param   {String}    type      - Event type
 * @param   {Function}  callback  - Callback function
 */
export function callOnce(el, type, callback) {
  el.addEventListener(type, callOnceCaller);
  function callOnceCaller() {
    el.removeEventListener(type, callOnceCaller);
    return callback();
  }
}

/**
 * Wrap callOnce with the document onload event
 *
 * @param   {Element}   el        - Element object
 * @param   {String}    type      - Event type
 * @param   {Function}  callback  - Callback function
 */
export function initOnce(callback) {
  callOnce(document, ff.EVENTS.contentLoaded.type, callback);
}

/**
 * Form Initialization for any form with the 'ff-form' class
 *
 * @param   {Element} form      - Specific Form to initialize, or all forms if null
 * @returns {Element} form to receive focus
 */
export async function initForms(form=null) {
  const forms = form ? [form] : document.forms;
  let focus   = null;

  for (const form of forms) {
    if (!form.initialized) {
      // attach some custom form functionality to all form elements
      form.getClear   = ()=>{ return form.dataset.clear   === 'true' };
      form.getChanged = ()=>{ return form.dataset.changed === 'true' };
      form.setClear   = (clear=true)=>{ form.dataset.clear = clear };
      form.setChanged = (changed=true)=>{
        form.dataset.changed = changed; // dataset values are stored as String
        if (changed && form.getClear()) form.flashTimeout = setTimeout(()=> ff.flash(), 500);
        else clearTimeout(form.flashTimeout);
      };

      if (form.classList.contains('ff-form')) {
        // TAB key listener so we can focus SUBMIT when tabbing off the last input
        form.addEventListener('keydown', event => {
          if (event.key == 'Tab' && ff.isFormInput(event.target)) {
            const el = event.target;
            const f  = el.form;

            // only check when the element is not part of a tab order
            if (el.tabIndex <= 0 || ff.isEmpty(el.tabIndex)) {
              for (let i=f.length; i >= 0; i--) {
                const input = f[i];

                if (input) {
                  if (el != input) {
                    if (ff.isFormInput(input)) break; // valid input after the target, break
                    else continue;                    // this input was hidden or disabled, keep looking
                  }
                  else {
                    // tabbed element == the last valid form input
                    if (ff.focusFormSubmit(f)) event.preventDefault();
                    // dispatch custom lastTab event
                    input.dispatchEvent(ff.EVENTS.lastTab);
                    break;
                  }
                }
              }
            }
          }
        });

        // prevent multiple form submissions
        if ('multiple' in form.dataset === false) {
          form.addEventListener('submit', event => {
            const submitted = parseInt(form.dataset.submitted || 0) + 1;
            form.dataset.submitted = submitted;
            if (submitted > 1) {
              console.warn(`multiple submit: ${submitted}`);
              ff.stopEvent(event);
            }
          });

          // reset the submit count after an ajax request
          form.addEventListener('ajax:complete', event => { form.dataset.submitted = 0 });
        }
      }
    }

    if (form.classList.contains('ff-observable')) {
      focus = form; // observable forms imply focusing
      // add event handlers for observed forms
      form.addEventListener('reset',  ()=>{ ff.formChange(form, false); ff.focusForm(form) });
      form.addEventListener('submit', ()=> ff.formChange(form, false));
      form.addEventListener('change', ()=> ff.formChange(form, true));
    }
    else if (form.classList.contains('ff-focus')) focus = form; // always focus forms with the ff-focus class

    if (form.dataset.focus) focus = ff.get(form.dataset.focus); // override focus if an element was specified
    form.initialized = true;                                    // flag to indicate that a form was initialized
    form.setClear(true);                                        // flag to prevent flash clearing when changes occur after the contentLoaded event
    form.setChanged(false);                                     // form change event flag
  }

  return focus;
}

/**
 * Initialize a currency object via IMask
 *
 * @param   {Element}   el - parent input element for the currency object
 */
/*
export function initCurrencyIMask(el, init=false) {
  const signed = el.dataset.signed == 'false' ? false : true;
  el.value     = el.value || 0;                               // ensure 0 if blank on init

  if (!init) {
    // delay Inputmask initialization until the input is focused
    ff.callOnce(el, 'focus', ()=>{ ff.initCurrency(el, true) });
    // stub functions to mimic the behavior once initialized
    el.getValue = function()    { return ff.toDecimal(this.value, this.dataset.scale) }
    el.setValue = function(val) { this.value = ff.toCurrency(val, el.dataset.scale || 2) }
    el.setValue(el.value);
    el.classList.add('right');  // ensure values are right justified
    el.spellcheck   = false;
    el.autocomplete = 'off';

    el.addEventListener('focus', ()=>{ el.dataset.previous = el.value }); // save values on focus
    el.addEventListener('blur',  ()=>{
      const val = Number(el.value.trim().replace(/[^\d.]/g,''));
      if (val == 0 || isNaN(val)) el.setValue(0);                         // ensure 0 if blank or -0
    });
    if (!signed) {
      // squash the negative key
      el.addEventListener('keypress', (event)=>{ if (event.key == '-') event.preventDefault() });
    }
  }
  else {
    el.mask = new IMask(el, Object.assign({
      mask:                 Number,
      thousandsSeparator:   ',',
      radix:                '.',
      padFractionalZeros:   true,
      signed:               signed,
      scale:                el.dataset.scale  || 2,
      max:                  el.dataset.max    || 999999999999.99,
      min:                  (signed ? (el.dataset.min || -999999999999.99) : 0)
    }, el.dataset));
    el.getValue = function() { return Number(this.mask.unmaskedValue) } // get unmasked numeric value
    el.setValue = function(val) {
      // set and mask a value by formatting the given value as a fixed decimal string
      this.mask.typedValue = ff.toFixed(val, this.mask.masked.scale);
    }
    ff.updateObjects(el, el.mask);
  }
}
*/

/**
 * Initialize a currency object via Inputmask
 *
 * @param   {Element}   el    - parent input element for the currency object
 * @param   {Boolean}   init  - true to force immediate init, false to delay until focus
 */
/*
export function initCurrencyInputmask(el, init=false) {
  el.classList.add('right'); // ensure values are right justified
  el.addEventListener('blur',  event => {
    if (el.value.trim() == '') {
      el.setValue(0);
      el.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}));
    }
    else if (el.value != el.dataset.previous) {
      el.setValue(el.value);
      el.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}));
    }
  });
  el.addEventListener('focus', event => {
    el.dataset.previous = el.value // save values on focus
  });

  if (!init) {
    // delay Inputmask initialization until the input is focused
    ff.callOnce(el, 'focus', function() { ff.initCurrency(el, true) });

    // stub functions to mimic the behavior once initialized with Inputmask
    el.getValue = function()    { return ff.toDecimal(this.value) }
    el.setValue = function(val) { this.value = ff.toCurrency(val, el.dataset.scale || 2) }
    el.setValue(el.value);
  }
  else {
    //el.mask = new Inputmask('decimal', Object.assign({
    el.mask = new Inputmask('numeric', Object.assign({
      rightAlign:           false,      // we add a class to handle right alignment
      positionCaretOnClick: 'select',   // select the entire value on focus/click
      allowMinus:           'signed'    in el.dataset ? el.dataset.signed    : true,
      groupSeparator:       'delimiter' in el.dataset ? el.dataset.delimiter : ',',
      radixPoint:           '.',
      digits:               el.dataset.scale || 2,
      min:                  el.dataset.min   || -999999999999.99,
      max:                  el.dataset.max   || 999999999999.99,
    }, el.dataset)).mask(el);

    el.mask.destroy = function() { el.mask.remove() }                         // add a destroy function for Inputmask
    el.getValue     = function() { return Number(this.mask.unmaskedvalue()) } // get unmasked numeric value
    el.setValue     = function(val) {
      // set and mask a value by formatting the given value as a fixed decimal string
      this.mask.setValue(ff.toFixed(val, this.mask.option('digits')));
    }

    ff.updateObjects(el, el.mask);
  }
}
*/

/**
 * Apply CSS to selected menu items
 *
 * @param   {String}   rootPath - the current root path
 */
export function initMenus(rootPath) {
  // find the selected top menu link
  ff.getAll(`a[href="${rootPath}"]`, ff.get('#top_menu')).forEach(a => a.classList.add('selected'));

  // find the selected side menu link, written as an IIFE so we
  // can return early from the nested loop when a match is found
  (()=>{
    for (const a of ff.getAll('a.sidemenu-item-link', ff.get('section.ff-sidemenu'))) {
      // search the combination of a.pathname and its subs
      const paths = [a.pathname].concat(a.dataset.subs ? a.dataset.subs.split(';') : []);

      //console.log(`win: ${window.location.pathname}  paths: ${paths}`);
      for (const path of paths) {
        const re = new RegExp(`^${path}`, 'i');

        if (re.test(window.location.pathname)) {
          a.classList.add('sidemenu-item-selected');
          return;
        }
      }
    }
  })();
}

/**
 * Initialize objects for elements with Masks or special classes
 *
 * @param   {Element}   container - Container element to search in
 */
export async function initObjects(container) {
  // Cleanup old objects whenever page content is loaded unless a container is given.
  // If a container is given then we assume content is being dynamically added/removed
  // via the client in which case we don't want to destroy pre-existing objects.
  if (container === undefined) {
    for (const [id, obj] of Object.entries(ff.OBJECTS)) {
      // destroy all non-persistent objects
      if (!ff.isEmpty(obj.persistent)) {
        if (obj.hasOwnProperty('destroy')) obj.destroy();
        delete ff.OBJECTS[id];
      }
    }
  }

  ff.getAll(ff.OBJECTS_SELECTOR, container).forEach(function(el, idx, obj) {
    if (el.matches('input.ff-account') && !el.account) new FastFundAccount(el);
    else if (el.matches('input.ff-date') && !el.date) new FastFundDate(el);
    else if (el.matches('input.ff-search') && !el.search) new FastFundSearch(el);
    else if (el.matches('input.ff-decimal') && !el.mask) new FastFundDecimalInput(el);
    else if (el.matches('i.ff-tooltip') && !el.tooltip) new FastFundToolTip(el);
    else if (el.matches('select.ff-dropdown') && !el.dropdown) new FastFundDropDown(el);
    else if (el.matches('select.ff-multiselect') && !el.multiselect) new FastFundMultiSelect(el);
    else if (el.matches('input.ff-number-mask') && !el.mask) new FastFundMaskedInput(el, {init: true});
    /*
      OLD masking methods -

      // Inputmask
      // ---------
      // calculate a regex for the account mask, allow '_' as a valid input so that
      // partially masked values (ie. __-100-____) can be initially formatted correctly
      // ex. 99-999-9999 => [0-9_]{2}-[0-9_]{3}-[0-9_]{4}
      let mask = el.dataset.mask.split('-').map(x => `[0-9_]{${x.length}}`).join('-');
      el.mask = new Inputmask({insertMode: false, regex: mask}).mask(el);
      el.mask.destroy = function() { el.mask.remove() };  // add a destroy function for Inputmask
      ff.updateObjects(el, el.mask);

      // IMask
      // -----
      el.mask = new IMask(el, {
        mask:         el.dataset.mask.replace(/\d/g, '#'),
        definitions:  {'#': /[0-9_]/},
        lazy:         false,
        overwrite:    true
      });
      ff.updateObjects(el, el.mask);
    */
  });
  //console.log(ff.OBJECTS);
}

/**
 * Adjust the height for scrollable elements
 */
export async function initScrollables() {
  const minHeight = 125;  // minimum element height in order to adjust
  const bottom    = window.innerHeight - ff.FOOTER_PADDING;

  ff.getAll('.scrollable').forEach(el => {
    // only set a maxheight for scrollables if we have enough vertical space
    if ((bottom - el.offsetTop) > minHeight) {
      // set maxHeight to 1px initially, then set a delay
      // before resizing to allow inner items to display
      //console.log(`scrollable maxheight YES: bottom: ${bottom}  top: ${el.offsetTop}  diff: ${bottom-el.offsetTop}`);
      el.style.maxHeight = '1px';
      setTimeout(() => { ff.setMaxHeight(el) }, 100);
    }
    //else console.log(`scrollable maxheight NO: bottom: ${bottom}  top: ${el.offsetTop}  diff: ${bottom-el.offsetTop}`);
  });

  // adjust the items to stretch after scrollables so
  // resizing won't affect other element positions
  ff.getAll('.stretchy').forEach(el => {
    if ((bottom - el.offsetTop) > minHeight) {
      //console.log(`stretchy maxheight YES: bottom: ${bottom}  top: ${el.offsetTop}  diff: ${bottom-el.offsetTop}`);
      const maxHeight = window.innerHeight - el.offsetTop - ff.FOOTER_PADDING;
      el.style.height = `${maxHeight}px`;
    }
    //else console.log(`stretchy maxheight NO: bottom: ${bottom}  top: ${el.offsetTop}  diff: ${bottom-el.offsetTop}`);
  });
}

/**
 * Mark a form as changed/dirty
 *
 * @param   {Element}   form - Form to flag
 * @param   {Boolean}   flag - true (changed/dirty), false (not changed/not dirty)
 */
export function formChange(form, flag) {
  if (form && form.tagName == 'FORM') {
    if (!form.hasOwnProperty('setChanged')) ff.initForms(form);
    form.setChanged(flag);
  }
}

/**
 * Handles the Show/Hide Password function
 *
 * @param   {Event}    event - Click Event that triggered the Show/Hide link
 */
export function passwordToggle(event) {
  const $link = event.target;
  const $pwd  = ff.get('input.password', $link.form);

  ff.stopEvent(event);

  if ($link && $pwd) {
    if ($pwd.type == 'text') {
      $link.innerText    = 'Show Password';
      $pwd.type          = 'password';
      $pwd.form.onsubmit = null;
    }
    else {
      $link.innerText    = 'Hide Password';
      $pwd.type          = 'text';
      $pwd.form.onsubmit = function(){ $pwd.type = 'password' };
    }
  }
}

/**
 * Checks the current page for unsaved forms and displays a native confirmation dialog
 *
 * @param   {Event}   event - Event that triggered the check
 * @returns {Boolean}       - false if the event should be or was stopped
 */
export function unsavedFormCheck(event) {
  let rc = true;

  Array.from(ff.getAll('form.ff-observable')).every(form => {
    if (form.initialized && form.getChanged()) {
      const el = event.target;

      if (!el || (el && el.target != '_blank')) {
        // no need to display unsaved warning if the target is opening in a new tab
        if (!window.confirm('The current page has unsaved changes, continue without saving?')) {
          ff.stopEvent(event);
          rc = false;
          return false; // break out of every() loop
        }
        else {
          // unset the changed flag to prevent subsequent warnings
          form.setChanged(false);
        }
      }
    }
  });

  return rc;
}

/**
 * Update the OBJECTS object
 *
 * @param   {Element}  $el    - Element attached to the object
 * @param   {Object}   object - FastFund object instance
 */
export async function updateObjects($el, object) {
  ff.OBJECTS[$el.id || ff.randomString()] = object;
}

/**
 * Sort an Array of arbitrary Objects and update the table column header icons
 * This function should update the sort columns using the same styles as
 * the sort_link helper method.
 *
 * @param   {Element}  $el    - link Element for the selected sort column
 * @param   {Array}    items  - Array of objects to sort
 * @param   {Boolean}  swap   - True to swap sort direction
 * @returns {Array}    sorted items
 */
export function sortItems($el, items, swap=true) {
  const order   = $el.dataset.order;
  let   reverse = true; // sort direction is DESC

  // update the sortable table column icons
  ff.getAll('a.sort', $el.closest('tr')).forEach($col => {
    if ($col.dataset.order == $el.dataset.order) {
      $col.classList.add('sorted');

      // swap the sort direction on previously selected columns, default is descending
      if (swap) {
        if ($col.dataset.icon == 'fa-sort-down') $col.dataset.icon = 'fa-sort-up';
        else $col.dataset.icon = 'fa-sort-down';
      }
      reverse = ($col.dataset.icon == 'fa-sort-down');
    }
    else {
      // reset the column to default icon and not sorted
      $col.dataset.icon = 'fa-sort';
      $col.classList.remove('sorted');
    }

    ff.get('i', $col).className = `fas ${$col.dataset.icon}`;
  });

  // sort the given array - use basic comparison if items are numeric/boolean, otherwise use localCompare
  if (ff.isNumber(items[0][order]) || ff.isBoolean(items[0][order])) {
    items = items.sort((a, b) => b[order]-a[order]);
  }
  else if (ff.isArray(items[0][order])) {
    items = items.sort((a, b) => b[order].length-a[order].length);
  }
  else {
    const options = {numeric: $el.dataset.hasOwnProperty('numeric')};
    items = items.sort((a, b) => (a[order] || '').localeCompare((b[order] || ''), undefined, options));
  }

  return (reverse ? items.reverse() : items);
}
