'use strict';

import FastFundMaskedInput from './fastfund_masked_input.js';

/**
 * Date Class
 *
 * @param   {Element}  input   - Date input Element object
 */
export default class FastFundDate extends FastFundMaskedInput {
  //---------------------------------------------------------------------------------------------------------
  // Static Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * @returns {Object}    Date Format Data
   */
  static get formatData() {
    if (!this._formatData) {
      this._formatData = {
        'mm/dd/yyyy': {
          format:           'm/d/Y',
          emptyPattern:     '([0-9]{1,2}|mm)/([0-9]{1,2}|dd)/([0-9]{4}|yyyy)',
          requiredPattern:  '([0-9]{1,2})/([0-9]{1,2})/([0-9]{4})',
          regexp:           new RegExp('([0-9]{1,2})/([0-9]{1,2})/([0-9]{4})')
        },
        'dd/mm/yyyy': {
          format:           'd/m/Y',
          emptyPattern:     '([0-9]{1,2}|dd)/([0-9]{1,2}|mm)/([0-9]{4}|yyyy)',
          requiredPattern:  '([0-9]{1,2})/([0-9]{1,2})/([0-9]{4})',
          regexp:           new RegExp('([0-9]{1,2})/([0-9]{1,2})/([0-9]{4})')
        },
        'yyyy-mm-dd': {
          format:           'Y-m-d',
          emptyPattern:     '([0-9]{4}|yyyy)-([0-9]{1,2}|mm)-([0-9]{1,2}|dd)',
          requiredPattern:  '[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}',
          regexp:           new RegExp('[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}')
        }
      }
    }

    return this._formatData;
  }

  /**
   * @returns {String}    HTML content for the menu UL
   */
  static get menuOptions() {
    return `
      <li data-value="0">Today</li>
      <li data-value="month">This Month</li>
      <li data-value="mlast">Last Month</li>
      <li data-value="3">Last 3 Months</li>
      <li data-value="6">Last 6 Months</li>
      <li data-value="12">Last 12 Months</li>
      <li data-value="fytd">Fiscal YTD</li>
      <li data-value="flast">Last Fiscal Year</li>
      <li data-value="cytd">Calendar YTD</li>
      <li data-value="clast">Last Calendar Year</li>
      <li data-value="bbtd">Beginning Balance To Date</li>
    `.trim();
  }

  /**
   * Parse a given ISO date string into a Date object
   *
   * @param   {String} str  ISO date string ('2010-01-01')
   * @returns {Date}        Date object or null
   */
  static parseISO(str) {
    const iso = FastFundDate.formatData['yyyy-mm-dd'].regexp;
    if (iso.test(str)) {
      const parts = str.split('-');
      const date  = new Date(parts[0], parts[1]-1, parts[2]);
      if (date.getFullYear() == parts[0] && date.getMonth()+1 == parts[1] && date.getDate() == parts[2]) {
        return date;
      }
    }

    return null;
  }

  /**
   * Parse a given date string into an ISO string
   *
   * @param   {String} date  arbitrary date string
   * @returns {String}       ISO formatted string
   */
  static toISO(date) {
    if (!ff.isString(date) || ff.isEmpty(date)) date = '';
    else {
      if (!FastFundDate.formatData['yyyy-mm-dd'].regexp.test(date)) {
        const parts = date.split(/\D/);
        if (parts.length == 3) {
          if (currentCompany.date_format == 'US') date = FastFundDate.formatDate(`${parts[2]}-${parts[0]}-${parts[1]}`, 'ISO');
          else date = FastFundDate.formatDate(`${parts[2]}-${parts[1]}-${parts[0]}`, 'ISO');
        }
      }
    }

    return date;
  }

  /**
   * Format a given date object or ISO string
   *
   * @param   {Date}      date   - Date object or ISO string
   * @param   {String}    format - Date format type (US, INTL or ISO), null for currentCompany.date_format
   * @returns {String}           - Formatted date
   */
  static formatDate(date, format) {
    let str = '';
    date    = ff.isDate(date) ? date : this.parseISO(date);

    if (date) {
      if (format) format = format.toUpperCase();
      else format = (typeof currentCompany == 'undefined') ? 'US' : currentCompany.date_format;

      if (format == 'US') str = flatpickr.formatDate(date, this.formatData['mm/dd/yyyy'].format);
      else if (format == 'INTL') str = flatpickr.formatDate(date, this.formatData['dd/mm/yyyy'].format);
      else str = flatpickr.formatDate(date, this.formatData['yyyy-mm-dd'].format);
    }

    return str;
  }

  /**
   * Return content for date inputs, see views/global/_dates.erb
   * NOTE - date objects need to be initialized after content is generated,
   *        eg. `new FastFundDate(element)`
   *
   * @param   {Object}    date1  - Date input attributes and settings
   * @param   {Object}    date2  - Linked Date input attributes and settings
   * @returns {String}           - Form content for Date inputs
   */
  static createFormData(date1={}, date2={}) {
    const INPUT  = `<input type="text" autocomplete="off" spellcheck="false" class="ff-mask ff-date" %attrs>`;
    const ICON   = `far fw ff-mask-icon`;
    let content  = [], attrs, menu, style;

    date1.id = date1.id || ff.randomString(); // date inputs require a unique ID

    if (ff.isEmpty(date2)) {
      // single date input
      style       = 'width: 10rem !important';
      date1.link  = null;
      date2       = {};
    }
    else {
      // 2 linked date inputs
      style       = 'width: 11.5rem !important';
      date2.id    = date2.id   || ff.randomString();
      date2.link  = date2.link || date1.id;
      date1.link  = date1.link || date2.id;
    }

    [date1, date2].filter(d => d.id).forEach((date, index) => {
      // loop through each date and create content
      menu  = date.link ? `<i id="${date.id}_menu_icon" title="Date Range Menu" class="${ICON} fa-bars" style="left: 9.65rem"></i>` : '';
      attrs = {
        id:           date.id,
        name:         date.name,
        value:        date.value,
        required:     date.required ? 'required' : null,
        style:        style,
        'data-index': index,
        'data-link':  date.link,
        'data-mask':  currentCompany.date_mask
      };
      // add any additional data attributes
      if (date.hasOwnProperty('data')) Object.keys(date.data).forEach(k => attrs[`data-${k}`] = date.data[k]);
      // convert attrs object to key=value attribute pairs for the input
      attrs = Object.keys(attrs).filter(k => !ff.isEmpty(attrs[k])).map(k => `${k}="${attrs[k]}"`).join(' ');

      content.push(`
        <div class="ff-mask-container">
          <div class="icon-wrapper">
            ${INPUT.slice().replace('%attrs', attrs)}
            <i id="${date.id}_clear_icon" title="Clear Date" class="${ICON} fa-trash-can" style="left: 7rem"></i>
            <i id="${date.id}_calendar_icon" title="Calendar" class="${ICON} fa-calendar-lines" style="left: 8.25rem"></i>
            ${menu}
          </div>
        </div>
      `.trim());

      // add separator
      if (index == 0 && date.link) content.push(`<span class="ff-mask-separator noclick"><i class="fas fa-caret-right"></i></span>`);
    });

    return content.join('');
  }

  //---------------------------------------------------------------------------------------------------------
  // Main Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Constructor
   *
   * @param   {Element}  input   - Date input Element object
   */
  constructor(input) {
    super(input, {mask: input.dataset.mask || 'yyyy-mm-dd', maskChars: 'ymd'});

    this.hiddenInput      = input;                                // original DOM input element that will be hidden for submit
    this.hiddenInput.date = this;                                 // Add a reference to this instance
    this.id               = input.id;                             // Input element ID
    this.index            = input.dataset.index || 0;             // Index of this element when linked (0, 1)
    this.txnDate          = !ff.isEmpty(input.dataset.txn);       // Transaction date flag, enforce posting range rules
    this.disabled         = input.disabled || input.readOnly;     // Disabled flag
    this.datePicker       = null;                                 // Date Picker object
    this.input            = null;                                 // Date Picker input field
    this.link             = null;                                 // Linked date input element
    this.menu             = null;                                 // Menu element if linked
    this.menuIcon         = null;                                 // Menu icon element if linked
    this.maxDate          = null;                                 // Maximum date
    this.minDate          = null;                                 // Minimum date
    this.minPrefDate      = null;                                 // Minimum date for posting preference warning
    this.maxPrefDate      = null;                                 // Maximum date for posting preference warning
    this.invalidText      = null;                                 // Date validation text
    this.calendarIcon     = ff.get(`#${this.id}_calendar_icon`);  // Calendar icon element
    this.clearIcon        = ff.get(`#${this.id}_clear_icon`);     // Clear icon element

    // bind 'this' to the method so 'this' always refers to our instance
    this._parseDateString = this._parseDateString.bind(this);
    this._onMenuSelect    = this._onMenuSelect.bind(this);

    this._initPicker();
    this._initLink();
    this._initRange();
    this._initEvents();
    this._applyMask();
    this._setRequired(this.hiddenInput.required);
    ff.updateObjects(input, this);
  }

  //---------------------------------------------------------------------------------------------------------
  // Private Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Ensure start date is less than the end date when linked
   *
   * @returns {Boolean} - true if range is valid
   */
  _checkDate() {
    let valid = true;

    if (this.link) {
      let startObj = null, startDate = null, startVal = null,
          endObj   = null, endDate   = null, endVal   = null;

      if (this.index == 0) {
        startObj = this;
        endObj   = this.link.date;
        startVal = this.input.value;
        endVal   = this.link.date.input.value;
      }
      else {
        startObj = this.link.date;
        endObj   = this;
        startVal = this.link.date.input.value;
        endVal   = this.input.value;
      }

      // check if paired (both must be null or filled)
      if (this.hiddenInput.dataset.paired) {
        if ((startVal == this.mask && endVal == this.mask) || (startVal != this.mask && endVal != this.mask)) {} // ok
        else {
          valid = false;
          new FastFundModal({
            title:    'Invalid Date Range',
            content:  'Both selections for a paired date range must either be blank or filled with a valid date.',
            onClose:  function() { startObj.input.focus() }
          });
        }
      }

      // check if both have non-mask values
      if (valid) { // keep checking
        if ((startVal != endVal) && startVal != '' && startVal != this.mask && endVal != '' && endVal != this.mask) {
          startDate = this._parseDateString(startVal);
          endDate   = this._parseDateString(endVal);
          valid     = (startDate && endDate && (startDate < endDate));

          if (!valid) {
            // clear the start date and show a warning
            startObj.clear(false);

            new FastFundModal({
              title:    'Invalid Date Range',
              content:  'Start dates cannot come after end dates.',
              onClose:  function() { startObj.input.focus() }
            });
          }
        }
      }
    }

    return valid;
  }

  /**
   * Check validity of the date range and set a custom validity message
   *
   * @returns {Boolean} - true if valid
   */
  _checkValidity() {
    let text = '';

    if (!this.disabled) {
      const date = this.getDate();
      if (date && ((date < this.minDate) || (date > this.maxDate))) text = 'Invalid Date Range';
    }

    // validity text will be overridden and displayed in _onInvalid()
    this.input.setCustomValidity(text);

    return ff.isEmpty(text);
  }

  /**
   * Initialize event listeners
   */
  _initEvents() {
    // mask events
    const self = this;
    this.input.addEventListener('focus',    event => event.target.select()); // select entire value on focus
    this.input.addEventListener('blur',     event => this._checkValidity());
    this.input.addEventListener('keydown',  event => this._onKeyDown(event));
    this.input.addEventListener('keypress', event => this._onKeyPress(event));
    this.input.addEventListener('paste',    event => this._onPaste(event));
    this.input.addEventListener('invalid',  event => this._onInvalid(event));
    this.hiddenInput.addEventListener('change', event => this._onChange(event));
    this.hiddenInput.focus = function() { self.input.select() };
    ff.addMutationObserver(this.input, this);
    ff.addMutationObserver(this.hiddenInput, this);

    // icon events
    this.clearIcon.addEventListener('click', event => this.clear());
    this.calendarIcon.addEventListener('click', event => this.toggle());
    if (this.menu) this.menuIcon.addEventListener('click', event => this.toggleMenu(event));

    // submit event
    if (this.hiddenInput.form) {
      this.hiddenInput.form.addEventListener('submit', event => {
        // recheck min/max validity on submit
        let valid = this._checkValidity();
        // if linked, check if start date > end date
        if (valid && this.link && this.index == 1) valid = this._checkDate();

        if (!valid) {
          // force native input validity check, then stop the event
          this.input.checkValidity();
          ff.stopEvent(event);
        }
      });
    }
  }

  /**
   * Initialize the linkage between two date elements
   */
  _initLink() {
    if (this.hiddenInput.dataset.link) {
      this.link     = ff.get(`#${this.hiddenInput.dataset.link}`);
      this.menuIcon = ff.get(`#${this.id}_menu_icon`);
      this.menu     = new FastFundMenu(this.hiddenInput, {
        base:     this.hiddenInput.closest('div'),
        content:  FastFundDate.menuOptions,
        onSelect: this._onMenuSelect
      });
    }
  }

  /**
   * Initialize the picker
   */
  _initPicker() {
    // ------------------------------------------------------------------------------------------
    // NOTE - flatpickr handles updating the hiddenInput when values change in input
    // ------------------------------------------------------------------------------------------
    const self = this;

    this.datePicker = flatpickr(this.hiddenInput, {
      altFormat:            FastFundDate.formatData[this.mask].format,
      altInput:             true,
      allowInput:           true,
      clickOpens:           false,
      dateFormat:           'Y-m-d',               // date format for submitting
      disableMobile:        true,                  // disable native mobile date widget, use flatpickr
      ignoredFocusElements: [this.calendarIcon],   // ignore focus on calendar icon, we handle it manually
      parseDate:            this._parseDateString,
      errorHandler: function(error) {
        // re-focus the input if an invalid date (other than an empty mask) was entered
        let focus = (self.value() != self.mask);
        setTimeout(function() { self.clear(focus) }, 25);
        //console.log(`error: ${error}  val: ${self.value()}`);
      }
      /*
      onChange:             function(selectedDates, dateStr, instance) {},
      onValueUpdate:        function(selectedDates, dateStr, instance) {
        // DO NOT move focus to the next form element if a date was selected
        //setTimeout(function() { ff.focusNextElement(self.input) }, 25);
      }
      */
    });

    // add a unique class to the hidden input for easier selections
    this.hiddenInput.className        = ['ff-date-real', this.hiddenInput.dataset.class].filter(c => c).join(' ');
    this.hiddenInput.dataset.previous = this.hiddenInput.value; // save initial value

    this.input      = this.datePicker._input; // re-assign our null input to the new element created by flatpickr
    this.input.date = this;                   // add a reference to the picker (prevent re-init from ff_functions)
    this.input.id   = `${this.id}_fp`;        // set a unique ID

    // set a getValue function on the new input to return the real hidden input value -
    // the ISO date string or ''
    this.input.getValue = ()=>{ return this.hiddenInput.value };

    // copy any additional classes from hidden input
    if (this.hiddenInput.dataset.class) this.input.classList.add(this.hiddenInput.dataset.class);

    // copy attributes from the original input
    ['autocomplete', 'spellcheck', 'style'].forEach(attr => {
      this.input.setAttribute(attr, this.hiddenInput.getAttribute(attr));
    });

    // set the picker input id and update any associated label to point to it
    let label = ff.get(`label[for="${this.id}"]`);
    if (label) label.setAttribute('for', this.input.id);
  }

  /**
   * Initialize the date ranges and validation text
   *
   * @param   {Boolean}   dataminmax - True to use the input.dataset min/max values, False to use this.min/max
   */
  _initRange(dataminmax=true) {
    const notes   = [];
    const dataset = this.hiddenInput.dataset;

    if (this.txnDate) {
      // this is a transaction related date field, ensure -
      //   min date is the perm closed date (+1) unless the current user is an admin, then it's the soft closed date (+1)
      //   min date is greater of perm/soft close and 24 months
      //   max date is perm close + 24 months
      //
      // fixed date range limit for all users is 24 months before/after permanent close
      const permClose = FastFundDate.parseISO(currentCompany.perm_closed_date);
      const permMin   = new Date(permClose.getFullYear(), permClose.getMonth(), permClose.getDate()+1); // perm close date + 1 day
      const rangeMin  = new Date(permMin.getFullYear()-2,   permMin.getMonth(),   permMin.getDate());   // - 24months
      const rangeMax  = new Date(permClose.getFullYear()+2, permClose.getMonth(), permClose.getDate()); // + 24months
      this.maxDate    = rangeMax;

      // init the pref posting dates (today +/- company posting range preference months)
      this.minPrefDate = new Date(); this.minPrefDate.setMonth(this.minPrefDate.getMonth() - currentCompany.posting_range);
      this.maxPrefDate = new Date(); this.maxPrefDate.setMonth(this.maxPrefDate.getMonth() + currentCompany.posting_range);

      notes.push(`more than 24 months past the Permanent Books Closed date`);
      //notes.push(`outside the 24 month posting range limit (${this.formatDate(rangeMin)} - ${this.formatDate(rangeMax)})`);

      if (currentUser.admin === true) {
        this.minDate = (permMin < rangeMin) ? rangeMin : permMin;
        notes.push(`on or before the Permanent Close date (${this.formatDate(permClose)})`);
      }
      else {
        const softClose = FastFundDate.parseISO(currentCompany.soft_closed_date);
        const softMin   = new Date(softClose.getFullYear(), softClose.getMonth(), softClose.getDate()+1); // soft close date + 1 day

        this.minDate = (softMin < rangeMin) ? rangeMin : softMin;
        notes.push(`on or before the Soft Close date (${this.formatDate(softClose)})`);
      }
    }

    // check and override if a min/max was given
    this.minDate = dataset.min && dataminmax ? FastFundDate.parseISO(dataset.min) : (this.minDate || new Date('January 1, 1900 00:00:00'));
    this.maxDate = dataset.max && dataminmax ? FastFundDate.parseISO(dataset.max) : (this.maxDate || new Date(`December 31, ${new Date().getFullYear()+50} 23:59:59`));

    // invalidText is HTML for dialog, title is plain text for validation title
    this.invalidText = `The valid range for this date field is ${this.formatDate(this.minDate)} to ${this.formatDate(this.maxDate)}.`;
    this.input.title = this.invalidText;

    if (notes.length > 0) {
      this.input.title += `\n\nYou cannot add, delete or modify transactions in a period dated -`;
      this.input.title += `\n${notes.map(n => `   • ${n}`).join(`\n`)}\n`;
      this.invalidText += `
        <div style="margin-top: 1rem">
          You cannot add, delete or modify transactions in a period dated -
          <ul>${notes.map(n => `<li>${n}</li>`).join('')}</ul>
        </div>
        <div style="margin-top: 2rem">
          <span class="medium">NOTE: </span>
          The close dates can be changed from <a href="/utilities/books_closing" tabindex="-1">Utilities > Books Closing</a>
        </div>
      `;
    }
  }

  /**
   * Change Handler for the Hidden date input
   *
   * @param   {Event}   event - blur Event
   */
  _onChange(event) {
    // stop the change event if the value was not actually changed (prevents unnecessary form check)
    if (this.hiddenInput.value == this.hiddenInput.dataset.previous) ff.stopEvent(event);
    else {
      //console.log(`date changed: ${this.hiddenInput.dataset.previous} -> ${this.hiddenInput.value}`);
      this.hiddenInput.dataset.previous = this.hiddenInput.value;

      // date checking
      if (this.datePicker) {
        let date = this._parseDateString(this.value());

        if (date) {
          if ((date >= this.minDate) && (date <= this.maxDate)) {
            // ensure the picker is updated with the currently entered date
            this.datePicker.setDate(this.value(), false, FastFundDate.formatData[this.mask].format);

            // txn date checks
            if (this.txnDate) {
              const notes     = [];
              const softClose = FastFundDate.parseISO(currentCompany.soft_closed_date);

              if (date < this.minPrefDate || date > this.maxPrefDate) {
                // date is valid but outside the company date range posting preference
                notes.push(`
                  the date is outside the valid posting range of ${currentCompany.posting_range} months.
                  (${this.formatDate(this.minPrefDate)} - ${this.formatDate(this.maxPrefDate)}).
                `);
              }
              if (currentUser.admin && date <= softClose) {
                // admins can post in a soft close, with a warning
                notes.push(`the date is within a soft close period (${this.formatDate(softClose)}).`);
              }

              if (notes.length > 0) {
                let content = `
                  This transaction can be posted, however, please note -
                  <ul>${notes.map(n => '<li>'+n+'</li>').join('')}</ul>
                `;
                new FastFundModal({title: 'Notice', content: content, onClose: ()=>ff.focusNextElement(this.input)});
              }
            }

            return;
          }
          else {
            this._onInvalid();
          }
        }

        // we fell through the date check with an invalid or blank date
        if (this.value() == this.mask && this.hiddenInput.value == '') {
          // if the hidden input is empty and the date field contains the mask then
          // we don't want to unnecessarily clear and trigger a form change
        }
        else this.clear(false);
      }
    }
  }

  /**
   * Stop the native invalid event and display our own dialog
   *
   * @param   {Event}   event - Event object
   */
  _onInvalid(event) {
    if (event) ff.stopEvent(event);

    if (this.input.value != this.mask) {
      // only show an error dialog if an invalid date was entered, not on an empty date
      new FastFundModal({title: 'Invalid Date', content: this.invalidText, onClose: ()=> this.input.focus() });
    }
    else {
      this.input.focus();
    }
  }

  /**
   * Select callback handler for the Menu
   *
   * @param   {Element}   el - Selected element
   */
  _onMenuSelect(el) {
    this.menu.close();

    const value = el ? el.dataset.value : null;
    if (value) {
      let startDate = new Date();
      let endDate   = new Date();

      switch (value) {
        case 'bbtd':
          startDate = FastFundDate.parseISO(currentCompany.beginning_balance_date);
          break;
        case 'flast':
          const adjust = (new Date() < FISCAL_START_DATE) ? 2 : 1;
          endDate      = new Date(FISCAL_END_DATE.getTime());
          startDate    = new Date(FISCAL_START_DATE.getTime());
          endDate.setFullYear(endDate.getFullYear() - adjust);
          startDate.setFullYear(startDate.getFullYear() - adjust);
          break;
        case 'clast':
          startDate.setMonth(0);
          startDate.setDate(1);
          startDate.setFullYear(startDate.getFullYear() - 1);
          endDate.setMonth(11);
          endDate.setDate(31);
          endDate.setFullYear(endDate.getFullYear() - 1);
          break;
        case 'cytd':
          startDate.setMonth(0);
          startDate.setDate(1);
          break;
        case 'fytd':
          startDate = new Date(FISCAL_START_DATE.getTime());
          if (endDate < startDate) startDate.setFullYear(startDate.getFullYear() - 1);
          break;
        case 'month':
          startDate.setDate(1);
          endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
          break;
        case 'mlast':
          startDate.setDate(1);
          startDate.setMonth(startDate.getMonth() - 1);
          endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
          break;
        default:
          // value is numeric
          startDate.setMonth(startDate.getMonth() - value);
      }

      if (this.index == 0) {
        this.setDate(startDate);
        this.link.date.setDate(endDate);
        this.link.date.menu.close();
        ff.focusNextElement(this.link.date.input);
      }
      else {
        this.setDate(endDate);
        this.link.date.setDate(startDate);
        this.link.date.menu.close();
        ff.focusNextElement(this.input);
      }

      Rails.fire(this.hiddenInput, 'change');
      Rails.fire(this.link.date.hiddenInput, 'change');
      if (this.hiddenInput.form) ff.formChange(this.hiddenInput.form, true);
    }
  }

  /**
   * Handle a paste event
   *
   * @param   {Event}   event - Event object
   */
  _onPaste(event) {
    ff.stopEvent(event);
    const text = (event.originalEvent || event).clipboardData.getData('text/plain');
    let newVal = this.mask;

    // only allow a paste if the clipboard data matches the mask or ISO format
    if (FastFundDate.formatData[this.mask].regexp.test(text)) newVal = text;
    else if (FastFundDate.formatData['yyyy-mm-dd'].regexp.test(text)) {
      // convert ISO string to mask format
      let date = this.formatDate(FastFundDate.parseISO(text));
      if (date) newVal = date;
    }
    else ff.highlightEffect(this.input, 'error');

    this.input.value = newVal;
  }

  /**
   * Parse the value of the visual Picker input when changed to
   * ensure the real hidden input is updated with the ISO string
   *
   * @param   {String}   string - Date string
   * @param   {String}   format - Date format
   * @returns {Date}            - Date Object
   */
  _parseDateString(string, format) {
    //console.log('parsing date: '+string+'  format: '+format);
    let month, day, year = null;
    format = format || FastFundDate.formatData[this.mask].format;

    // strip out any invalid chars
    string = String(string).replace(/[^\d/-]/g,'');

    if (format == 'm/d/Y')      [month, day, year] = string.split('/');
    else if (format == 'd/m/Y') [day, month, year] = string.split('/');
    else if (format == 'Y-m-d') [year, month, day] = string.split('-');

    if ((/^\d{4}$/.test(year)) && (/^\d{1,2}$/.test(month)) && (/^\d{1,2}$/.test(day))) {
      try {
        let date = new Date(year, month-1, day);
        if (date.getFullYear() == year && date.getMonth() == month-1 && date.getDate() == day) {
          // valid date selected
          return date;
        }
      }
      catch(error) {}
    }

    // something invalid was entered, return the initial date if set, otherwise
    // just return undefined so flatpickr will clear the inputs
    return undefined;
  }

  /**
   * Update the required and pattern attributes of our date inputs.
   * When not required, the pattern includes the mask characters
   * so the date can be submitted with no value.
   *
   * @param   {Boolean}   required - required value
   */
  _setRequired(required) {
    this.hiddenInput.required = required;
    this.input.required       = required;

    if (required) this.input.setAttribute('pattern', FastFundDate.formatData[this.mask].requiredPattern);
    else this.input.setAttribute('pattern', FastFundDate.formatData[this.mask].emptyPattern);
  }

  //---------------------------------------------------------------------------------------------------------
  // Public Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Clear the date input
   *
   * @param   {Boolean} focus - focus the input after clearing
   */
  clear(focus=true) {
    if (this.datePicker) {
      this.datePicker.clear();
      this.input.value = this.mask;

      if (focus) {
        this.input.focus();
        this.input.select();
      }
    }
    return this;
  }

  /**
   * Close the picker
   */
  close() {
    if (this.datePicker) this.datePicker.close();
  }

  /**
   * Close the menu
   */
  closeMenu() {
    if (this.menu) this.menu.close();
  }

  /**
   * Destroy this object's associated elements
   */
  destroy() {
    this.hiddenInput.date = null;

    if (this.menu) {
      this.menu.destroy();
      this.menu = null;
    }

    if (this.datePicker) {
      this.datePicker.destroy();
      this.datePicker = null
    }
  }

  /**
   * Disable this input
   *
   * @param   {Boolean}   force   - force disabling
   * @param   {Boolean}   clear   - clear input value
   */
  disable(force, clear) {
    if (force || !this.disabled) {
      if (clear) this.clear();
      this.disabled             = true;
      this.hiddenInput.disabled = true;
      this.input.required       = false;
      this.input.setSelectionRange(0,0);
      [this.clearIcon, this.calendarIcon, this.menuIcon].filter(i => i).forEach(i => i.classList.add('disabled'));

      if (this.input.readOnly) ff.toggleElement(this.input, {readonly: true});
      else ff.toggleElement(this.input, {disabled: true});
    }
    return this;
  }

  /**
   * Enable this input
   *
   * @param   {Boolean}   force   - force enabling
   */
  enable(force) {
    if (force || this.disabled) {
      this.disabled             = false;
      this.hiddenInput.disabled = false;
      this.input.disabled       = false;
      this.input.required       = this.required;
      [this.clearIcon, this.calendarIcon, this.menuIcon].filter(i => i).forEach(i => i.classList.remove('disabled'));
    }
    return this;
  }

  /**
   * Format the given date object
   *
   * @param   {Date}   date - Date object (optional)
   * @returns {String}      - Formatted date
   */
  formatDate(date) {
    date = date || this.getDate();
    if (date) return flatpickr.formatDate(date, FastFundDate.formatData[this.mask].format);
    return '';
  }

  /**
   * Returns the currently selected date
   *
   * @returns {Date} - JS Date object
   */
  getDate() {
    return FastFundDate.parseISO(this.hiddenInput.value);
  }

  /**
   * Returns the current hidden input value
   *
   * @returns {String}
   */
  getValue() {
    return this.hiddenInput.value;
  }

  /**
   * Open the picker
   */
  open() {
    if (this.datePicker) this.datePicker.open();
  }

  /**
   * Set the date
   *
   * @param   {Date}    date  - Date object or ISO String
   * @param   {Boolean} focus - Focus input after setting
   */
  setDate(date, focus) {
    if (this.datePicker) {
      if (!ff.isDate(date) && ff.isEmpty(date)) this.clear(focus);
      else {
        this.datePicker.setDate(date);

        if (focus) {
          this.input.focus();
          this.input.select();
        }
      }
    }
  }

  /**
   * Set the maximum date
   *
   * @param   {String/Date} date - ISO date string or Date object
   */
  setMax(date) {
    if (ff.isString(date)) this.maxDate = FastFundDate.parseISO(date);
    else if (ff.isDate(date)) this.maxDate = date;
    this._initRange(false);
  }

  /**
   * Set the minimum date
   *
   * @param   {String/Date} date - ISO date string or Date object
   */
  setMin(date) {
    if (ff.isString(date)) this.minDate = FastFundDate.parseISO(date);
    else if (ff.isDate(date)) this.minDate = date;
    this._initRange(false);
  }

  /**
   * Toggle the picker open/closed
   */
  toggle() {
    if (this.datePicker) this.datePicker.toggle();
  }

  /**
   * Toggle the date options menu open/closed
   *
   * @param   {Event} event - Event object
   */
  toggleMenu(event) {
    if (this.menu) {
      // stop bubbling so the document listener will not catch the click and close the menu
      event.stopPropagation();
      this.menu.toggle();
    }
  }

  /**
   * Toggle the required state of this date input
   */
  toggleRequired() {
    this._setRequired(!this.hiddenInput.required);
  }

  /**
   * Returns the current input value
   *
   * @returns {String}
   */
  value() {
    return this.input.value;
  }

}
