'use strict';

/**
 * AJAX Search/Combobox Class
 *
 * @param   {Element}  input  - parent input Element
 */
export default class FastFundSearch {
  //---------------------------------------------------------------------------------------------------------
  // Static Variables
  //---------------------------------------------------------------------------------------------------------
  static ENTITY_ADD_URL  = '/ajax/add_entity';        // see ajax_controller
  static ENTITY_FORM_URL = '/ajax/get_entity_form';   // see ajax_controller
  // entity types allowed to be added on the fly
  static ENTITY_TYPES    = ['Constituent', 'Client', 'Vendor', 'Other', 'Solicitor', 'Tribute'];

  //---------------------------------------------------------------------------------------------------------
  // Static Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Returns the default options
   *
   * @returns {Object}
   */
  static get defaultOptions() {
    return {
      position:   'auto',    // force menu position to 'top' or 'bottom'
      chars:      3,         // minimum characters before search
      offset:     0,         // menu offset px
      addname:    null,      // Entity class type to allow quickadd on empty results (type is single: Other, Vendor, etc.)
      base:       null,      // base/positioning element or selector for the menu
      inactive:   null,      // non-null to allow selection of inactive items
      url:        null       // callback url, {search: <search string>} is POSTed along with the input's dataset
                             // POST options, sent as {data: {...}}
    };
  }

  //---------------------------------------------------------------------------------------------------------
  // Main Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Constructor
   *
   * @param   {Element}   input  - parent input Element
   */
  constructor(input) {
    // members
    this.input         = input;                                    // parent text input Element
    this.input.search  = this;                                     // reference to this object
    this.menu          = null;                                     // FastFundMenu instance
    this.hiddenInput   = null;                                     // hidden input Element for submitting real value
    this.searchInput   = null;                                     // search input Element
    this.lastSearch    = null;                                     // last search text
    this.clearIcon     = null;                                     // clear icon Element
    this.linkIcon      = null;                                     // link icon Element
    this.disabled      = input.disabled || input.readOnly;         // disabled flag
    this.search        = ff.debounce(this._search, 350);           // debounced search function
    this.options       = Object.assign(FastFundSearch.defaultOptions, input.dataset);
    this.options.base  = this.options.base || this.input;
    this._onMenuSelect = this._onMenuSelect.bind(this);            // ensure 'this' refers to this object in _onMenuSelect

    // initialize elements and events
    this._initSearch();
    this._checkValue();
    this._initEvents();

    ff.updateObjects(input, this);
  }

  /**
   * Handle an addName request
   *
   * @param   {String}   type  - Subentity type to add (Other, Vendor, etc.)
   * @param   {String}   name  - empty search result term
   */
  _addNameCallback(type, name) {
    ff.stopEvent();
    this.menu.close();

    switch (type) {
      case 'Client':
      case 'Vendor':
        ff.fetch(FastFundSearch.ENTITY_FORM_URL, {
          data:       {type: type, name: name},
          onSuccess:  (data) => {
            if (ff.isObject(data) && data.html && data.js) {
              new FastFundModal({
                title:    `Add a new ${type}`,
                cancel:   true,
                content:  data.html,
                buttons: [
                  {
                    label: 'ok',
                    callback: (modal, button) => {
                      const form  = ff.get('form', modal.modal);
                      const valid = (form && (type == 'Vendor' || (type == 'Client' && checkArAccounts())));

                      if (valid && form.reportValidity()) {
                        ff.fetch(FastFundSearch.ENTITY_ADD_URL, {
                          data:       new FormData(form),
                          onSuccess:  (subentity) => {
                            const $ctrlID = ff.get('#control_account_id');
                            if ($ctrlID) {
                              let id = '';

                              if (type == 'Client') {
                                id = subentity.r_account_id || subentity.m_account_id || subentity.c_account_id || subentity.e_account_id;
                              }
                              else if (type == 'Vendor') id = subentity.ap_account_id;

                              // update the control account (set manually - do not trigger the change event!)
                              $ctrlID.value = id;
                            }
                            // update the subentity and then focus next
                            setTimeout(()=>{ff.flash('info', `${subentity.subentity_label} was added successfully.`)}, 1000);
                            this.setValue(subentity.subentity_id, subentity.subentity_label);
                            ff.focusNextElement(this.input);
                          }
                        }).then(() => this._checkValue());

                        modal.close();
                      }
                    }
                  }
                ]
              });
              // add and execute the JS 'entity_new_js'
              ff.execJS(data.js);
            }
            else console.error('invalid entity data');
          }
        });
        break;

      case 'Constituent':
      case 'Solicitor':
      case 'Tribute':
        ff.fetch(FastFundSearch.ENTITY_FORM_URL, {
          data:       {type: 'Constituent', name: name, tribute: type == 'Tribute', solicitor: type == 'Solicitor'},
          onSuccess:  (data) => {
            if (ff.isObject(data) && data.html && data.js) {
              new FastFundModal({
                title:    `Add a new ${type}`,
                cancel:   true,
                content:  data.html,
                buttons: [
                  {
                    label: 'ok',
                    callback: (modal, button) => {
                      const form = ff.get('form', modal.modal);

                      if (form && form.reportValidity()) {
                        ff.fetch(FastFundSearch.ENTITY_ADD_URL, {
                          data:       new FormData(form),
                          onSuccess:  (subentity) => {
                            // update the subentity and then focus next
                            setTimeout(()=>{ff.flash('info', `${subentity.subentity_label} was added successfully.`)}, 1000);
                            this.setValue(subentity.subentity_id, subentity.subentity_label);
                            ff.focusNextElement(this.input);
                          }
                        }).then(() => this._checkValue());

                        modal.close();
                      }
                    }
                  }
                ]
              });
              // add and execute the JS 'entity_new_js'
              ff.execJS(data.js);
            }
            else console.error('invalid constituent data');
          }
        });
        break;

      case 'Other':
        ff.fetch(FastFundSearch.ENTITY_ADD_URL, {
          data:       {other: {entity_attributes: {name: name}}},
          onSuccess:  (subentity) => {
            setTimeout(()=>{ff.flash('info', `${subentity.subentity_label} was added successfully.`)}, 1000);
            this.setValue(subentity.subentity_id, subentity.subentity_label);
            ff.focusNextElement(this.input);
          }
        }).then(() => this._checkValue());
        break;
    }
  }

  /**
   * Check the input value validity
   */
  _checkValue() {
    const value = this.getValue();
    let   msg   = '';

    if (ff.isEmpty(value)) {
      this.clear(); // clear search and hidden input value
      if (this.input.required) msg = 'Valid selection required';
    }
    else {
      if (!this.linkIcon) {
        this.linkIcon             = document.createElement('i');
        this.linkIcon.className   = 'far ff-mask-icon fa-user';
        this.linkIcon.style.right = '30px';  // preserve fixed position even after window resizes
        this.linkIcon.title       = 'View Entity';
        this.input.style.setProperty('padding-right', '48px', 'important'); // allow room for both icons
        ff.insertBefore(this.clearIcon, this.linkIcon);
      }

      // always update the entity link
      const parts = value.split('_');
      const url   = `/lists/${parts[0].toLowerCase()}s/${parts[1]}/edit`;
      this.linkIcon.onclick = function() { window.open(url, '_blank') };
    }

    this.input.title       = this.input.value;
    this.input.placeholder = msg;
  }

  /**
   * Clear the search results
   *
   * @param   {String}   text  - Text to display
   */
  _clearSearch(text) {
    ff.empty(this.menu.ul);
    this.menu.selected = null;
    text = text || this.input.title;
    if (text) this.menu.ul.innerHTML = `<li class="disabled">${text}</li>`;
  }

  /**
   * Initialize the input and menu
   */
  _initSearch() {
    // search container input element
    this.searchInput                = document.createElement('input');
    this.searchInput.type           = 'text';
    this.searchInput.autocomplete   = 'off';
    this.searchInput.spellcheck     = false;
    this.searchInput.maxLength      = 50;
    this.searchInput.placeholder    = `Enter ${this.options.chars} or more characters`;
    this.searchInput.className      = 'ff-search-input';
    // hidden input for real submit value
    this.hiddenInput                = document.createElement('input');
    this.hiddenInput.type           = 'hidden';
    this.hiddenInput.name           = this.input.name;
    this.hiddenInput.value          = this.input.dataset.value || '';
    this.hiddenInput.dataset.id     = this.input.id;
    // remove the parent input name attribute, submit value comes from hiddenInput
    this.input.name                 = '';
    // clear icon
    this.clearIcon                  = document.createElement('i');
    this.clearIcon.className        = 'far ff-mask-icon fa-trash-can';
    this.clearIcon.style.right      = '0%';          // preserve fixed position even after window resizes
    this.clearIcon.style.padding    = '0 10px 0 0';  // preserve fixed position even after window resizes
    this.clearIcon.title            = 'Clear';
    // containers
    const searchDiv                 = document.createElement('div'); // container div for search and menu elements
    const inputDiv                  = document.createElement('div'); // new container div for the parent input
    searchDiv.className             = 'ff-search-container';
    inputDiv.style.position         = 'relative';

    // instantiate the menu
    this.menu = new FastFundMenu(inputDiv, Object.assign({
      content:    '',
      maxHeight:  null,
      onSelect:   this._onMenuSelect
    }, this.options));

    // add new elements to DOM
    searchDiv.appendChild(this.searchInput);
    inputDiv.appendChild(this.hiddenInput);
    inputDiv.appendChild(this.clearIcon);
    ff.insertBefore(this.menu.ul, searchDiv);
    // insert the new input container before the existing input within its parent,
    // then move the original input inside the new container so the icon can be positioned properly
    this.input.parentElement.insertBefore(inputDiv, this.input);
    this.input.style.setProperty('padding-right', '25px', 'important'); // allow room for trash icon
    inputDiv.appendChild(this.input);
    // finish by clearing/resetting the search
    if (this.disabled) this.disable(true);
    else this.enable(true);
    this._clearSearch();
  }

  /**
   * Initialize event listeners
   */
  _initEvents() {
    ff.addMutationObserver(this.input, this);
    this.input.addEventListener('blur',     event => this._checkValue());
    this.input.addEventListener('click',    event => this._onFocus(event));
    this.input.addEventListener('focus',    event => this._onFocus(event));
    this.input.addEventListener('invalid',  event => {
      ff.stopEvent(event);
      ff.highlightEffect(this.input);
    });

    this.menu.div.addEventListener('keydown',     event => this._onMenuKeyDown(event));
    this.searchInput.addEventListener('click',    event => ff.stopEvent(event));
    this.searchInput.addEventListener('keydown',  event => this._onSearchKeyDown(event));
    this.searchInput.addEventListener('keyup',    event => this._onSearchKeyUp());
    this.clearIcon.addEventListener('click',      event => { if (!this.input.disabled) this.clear(true) });
  }

  /**
   * Focus Handler
   *
   * @param {Event}   event   - Event object
   */
  _onFocus(event) {
    ff.stopEvent(event);
    if (!this.menu.isOpen()) {
      this.searchInput.value = '';
      this.lastSearch = null;
      this.menu.open();
    }
    this.searchInput.focus();
  }

  /**
   * Search Keydown Handler
   *
   * @param {Event}   event   - Event object
   */
  _onSearchKeyDown(event) {
    switch (event.key) {
      case 'Tab':
        ff.stopEvent(event);
        this.menu.select();
        break;
      case 'ArrowUp':
      case 'ArrowDown':
      case 'Home':
      case 'End':
        // direction keys are prevented in the input but propagated to the menu for handling
        event.preventDefault();
        break;
    }
  }

  /**
   * Search Keyup handler
   */
  _onSearchKeyUp() {
    let value = this.searchInput.value.trim();
    if (value.length >= this.options.chars) this.search(value);
    else {
      let text = (value.length > 0) ? this.searchInput.placeholder : '';
      this._clearSearch(text);
    }
  }

  /**
   * Menu Keydown Handler
   *
   * @param {Event}   event   - Event object
   */
  _onMenuKeyDown(event) {
    if (this.menu.isOpen()) {
      switch (event.key) {
        case 'ArrowUp':
          this.menu.move('previous');
          break;
        case 'ArrowDown':
          this.menu.move('next');
          break;
        case 'PageDown':
          this.menu.move('nextPage');
          break;
        case 'PageUp':
          this.menu.move('previousPage');
          break;
        case 'Home':
          this.menu.move('first');
          break;
        case 'End':
          this.menu.move('last');
          break;
        case 'Enter':
          ff.stopEvent(event);
          this.menu.select();
          break;
        default:
          break;
      }
    }
  }

  /**
   * Select callback handler for the Menu
   *
   * @param   {Element}   el - Selected element
   */
  _onMenuSelect(el) {
    if (el) {
      el = el.matches('li') ? el : el.closest('li');

      if (el) {
        if (el.dataset.addname) {
          this._clearSearch();
          this._addNameCallback(el.dataset.addname, el.innerText);
          // checkValue is called again from addName
        }
        else if (el.dataset.value) {
          this._clearSearch();
          this.setValue(el.dataset.value);
          this.input.value = el.innerText;
        }

        this._checkValue();
      }
    }

    this.menu.close();
    ff.focusNextElement(this.input);
  }

  /**
   * Search Function
   *
   * @param   {String}   value - Search term
   */
  _search(value) {
    value = value || this.searchInput.value.trim();
    if (value.length >= this.options.chars && value != this.lastSearch) {
      this.lastSearch = value;

      if (this.options.url) {
        this._clearSearch('Searching ...');

        // create a hash of post data, input data attributes + the search value
        const postData = Object.assign(this.input.dataset, {search: value});

        ff.fetch(this.options.url, {
          method:     'POST',
          data:       {data: postData},
          onSuccess:  data => {
            if (!ff.isArray(data)) this._clearSearch('Search Error');
            else {
              const addType = this.options.addname;
              const options = [];
              let   label;

              if (ff.isEmpty(data)) {
                this._clearSearch();
                options.push(`<li class="disabled">No matches found</li>`);
              }
              else {
                const escaped = value.replace(/([^\w\d\s])/gi, '\\$1'); // escape everything but chars, digits and whitespace
                const matcher = new RegExp(`(${escaped})`, 'gi');
                const matches = `${data.length} ${data.length > 1 ? 'matches' : 'match'}`;

                options.push(`<li class="disabled border-bottom">${matches} found -</li>`);

                data.forEach(name => {
                  if (name.value == this.getValue()) {
                    options.push(`<li data-value="${name.value}" class="disabled">${name.label}</li>`);
                  }
                  else if (name.inactive) {
                    // if input.dataset.inactive or if the original value = option value (editing),
                    // then allow selection of inactive, otherwise disabled but visible
                    const klass = (this.options.inactive || name.value == this.options.value) ? 'inactive' : 'disabled';
                    options.push(`<li data-value="${name.value}" class="${klass}">${name.label} - INACTIVE</li>`);
                  }
                  else {
                    label = name.label.replace(matcher, '<b>$1</b>');
                    options.push(`<li data-value="${name.value}">${label}</li>`);
                  }
                });
              }

              // always include an option to add the name on the fly if allowed (for partial matches)
              if (addType && FastFundSearch.ENTITY_TYPES.includes(addType)) {
                label = (addType == 'Other') ? 'an Other Name' : `a ${addType}`;
                options.push(`
                  <li class="disabled border-top">Select the option below to quick-add<br>the entered value as ${label} -</li>
                  <li data-addname="${addType}" class="center">${value}</li>
                `.trim());
              }

              if (options.length > 0) {
                this.menu.ul.innerHTML = options.join('').trim();
                this.menu.checkOverflow();
              }
            }
          }
        });
      }
    }
  }

  //---------------------------------------------------------------------------------------------------------
  // Public Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Clear the search and reset the value
   */
  clear(focus) {
    this._clearSearch();
    this.setValue('');
    this.input.value = '';

    if (this.linkIcon) {
      this.linkIcon.remove();
      this.linkIcon = null;
    }
    if (focus) {
      ff.stopEvent(event);
      this.input.focus();
    }
  }

  /**
   * Close the search
   */
  close() {
    this.menu.close();
  }

  /**
   * Destroy the associated elements
   */
  destroy() {
    this.menu.destroy();
  }

  /**
   * Disable this input
   *
   * @param   {Boolean}   force   - force disabling
   * @param   {Boolean}   clear   - clear input value
   */
  disable(force, clear) {
    if (force || !this.disabled) {
      if (clear) this.clear();
      this.disabled       = true;
      this.input.disabled = true;
      this.clearIcon.classList.add('disabled');
    }
  }

  /**
   * Enable this input
   *
   * @param   {Boolean}   force   - force enabling
   * @param   {Boolean}   clear   - clear input value
   */
  enable(force, clear) {
    if (force || this.disabled) {
      if (clear) this.clear();
      this.disabled       = false;
      this.input.disabled = false;
      this.clearIcon.classList.remove('disabled');
    }
  }

  /**
   * Returns the real value of the input associated with this search
   *
   * @returns   {String}  hidden input value
   */
  getValue() {
    return this.hiddenInput.value;
  }

  /**
   * Returns the name/label value of the search box
   *
   * @returns   {String}  input value
   */
  getLabel() {
    return this.input.value;
  }

  /**
   * Sets the search values to the given Subentity object
   *
   * @param     {Object}  sub - Subentity object
   * @returns   {String}  hidden input value
   */
  setEntity(sub) {
    if (!ff.isEmpty(sub)) {
      const value = `${sub.entity_type}_${sub.id}`;                    // Subentity.get_id
      const label = `${sub.name} (${sub.entity_type} #${sub.number})`; // Subentity.get_label
      return this.setValue(value, label);
    }
    else return null;
  }

  /**
   * Set and Returns the real value of the input associated with this search
   *
   * @param     {String}  value - Value of the input to be submitted
   * @param     {String}  label - Value of the visible Search input
   * @returns   {String}  hidden input value
   */
  setValue(value, label) {
    if (this.hiddenInput.value != value) {
      this.hiddenInput.value = value;
      this.hiddenInput.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}));
    }
    if (label) this.input.value = label;
    return value;
  }

}
