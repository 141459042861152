// ----------------------------------------------------------------------------
// Misc. AJAX Functions, available as ajax.funcname
// ----------------------------------------------------------------------------

/**
 * Validate a bank routing number
 *
 * @param   {Element}   el        - Routing number input element
 */
export function checkRoutingNumber(el) {
  const URL    = '/ajax/check_routing_number';
  const number = el.value.trim();

  if (number.length == 9) {
    ff.fetch(URL, {
      data:       {number: number},
      onSuccess:  (data) => {
        let title = 'Routing Number Verification';
        let content, onClose;

        if (!ff.isEmpty(data)) {
          content = `Please verify that routing number ${number} is associated with ${data}.`;
          onClose = (modal) => { ff.focusNextElement(el) };
        }
        else {
          el.value = '';
          content  = `
            We could not find a bank associated with routing number ${number}.<br><br>
            If this is a valid number, please contact support with the bank name and routing number so it can be added.
          `;
          onClose  = (modal) => { el.focus() };
        }

        new FastFundModal({title: title, content: content, onClose: onClose});
      }
    });
  }
}
