'use strict';
// ----------------------------------------------------------------------------
// Module Aggregation
// ----------------------------------------------------------------------------
export * from './ff_ajax.js';
export * from './ff_utils.js';
export * from './ff_dom_utils.js';
export * from './ff_event_handlers.js';
export * from './ff_functions.js';

// ----------------------------------------------------------------------------
// Constants
// ----------------------------------------------------------------------------

// Custom Event objects
export const EVENTS = {
  // fired from initContent() after content is updated on initial page load and AJAX updates
  contentLoaded: new CustomEvent('ffContentLoaded',   {bubbles: true, cancelable: true}),
  // fired from initForms() when the last element receives tab keydown
  lastTab:       new CustomEvent('ffLastTab',         {bubbles: true, cancelable: true}),
  // fired from txn_functions on a txn line change
  lineAdded:     new CustomEvent('ffLineAdded',       {bubbles: true, cancelable: true}),
  lineRemoved:   new CustomEvent('ffLineRemoved',     {bubbles: true, cancelable: true})
};

// padding between the footer and resized/stretched elements
export const FOOTER_PADDING = 20;

// selector string for focusable elements
export const FOCUSABLE_SELECTOR = `
  select:not([tabindex="-1"]):not(:disabled),
  input:not([type="hidden"]):not([tabindex="-1"]):not(:disabled),
  textarea:not([tabindex="-1"]):not(:disabled),
  button:not([tabindex="-1"]):not(:disabled),
  [href]:not([tabindex="-1"])
`.trim();

// container for dynamically defined functions
export const FUNCS = {};

// object of misc. globals
export const GLOBALS = {
  ajaxTimeout: null,                      // setTimeout ID of the AJAX spinner
  zIndex:      500                        // incrementing zIndex to ensure new popups open on top (modals, menus, tooltips, etc)
};

// Container for tracking FastFund and external object instances {id => object}
export const OBJECTS            = {};
export const OBJECTS_SELECTOR   = `
  input.ff-account, input.ff-decimal, input.ff-date, input.ff-number-mask, input.ff-search,
  i.ff-tooltip, select.ff-dropdown, select.ff-multiselect
`.trim();

// ----------------------------------------------------------------------------
// FastFund Initialization
// ----------------------------------------------------------------------------
document.addEventListener('DOMContentLoaded', async ()=> await ff.initFastFund());
