'use strict';

/**
 * Menu Class
 */
export default class FastFundMenu {
  //---------------------------------------------------------------------------------------------------------
  // Static Functions
  //---------------------------------------------------------------------------------------------------------

  // Array of instantiated Menu objects
  static OBJECTS = [];

  // Returns the CSS Class Name of selected items
  static get SelectedCSS() { return 'selected'; }

  // Returns true if any Menus are open
  static get active() {
    let active = false;
    FastFundMenu.OBJECTS.forEach(menu => active = active || menu.isOpen());
    return active;
  }

  //---------------------------------------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------------------------------------

  /**
   * Constructor
   *
   * @param   {Element}   control   - controlling Element
   * @param   {Object}    options   - options
   */
  constructor(control, options) {
    this.options = Object.assign({
      base:       null,               // positioning element or selector
      content:    null,               // initial UL content
      maxHeight:  300,                // integer value or null for dynamic
      offset:     0,                  // additional offset value in px when positioning the menu
      onClose:    null,               // close callback
      onSelect:   null,               // selection callback
      onOpen:     null,               // open callback
      padding:    ff.FOOTER_PADDING,  // top/bottom screen padding for calculating menu size
      position:   'auto'              // force position to 'top' or 'bottom'
    }, options);

    // members
    this.control  = control;                       // controlling Element
    this.base     = this.options.base || control;  // the element used for positioning
    this.selected = null;                          // currently selected LI
    this.div      = null;                          // menu (UL) parent DIV element
    this.ul       = null;                          // menu (UL) element

    // get the element if it was a selector
    if (!ff.isElement(this.base)) this.base = ff.get(this.base);

    FastFundMenu.OBJECTS.push(this);
    this._initMenu();
  }

  //---------------------------------------------------------------------------------------------------------
  // Private Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Initialize the Menu element
   */
  _initMenu() {
    // initialize the menu element
    this.div            = document.createElement('div');
    this.div.className  = 'ff-menu';
    this.ul             = document.createElement('ul');
    this.ul.className   = 'ff-menu';

    if (this.options.content)   this.ul.innerHTML        = this.options.content;
    if (this.options.maxHeight) this.ul.style.maxHeight  = `${this.options.maxHeight}px`;

    this.div.appendChild(this.ul);
    document.body.appendChild(this.div);

    // initialize events
    this.div.addEventListener('click', event => this._onClick(event));
    document.body.addEventListener('click', event => this._onBodyEvent(event));
    document.body.addEventListener('keydown', event => this._onBodyEvent(event));
  }

  /**
   * Handle an event from the document body for this menu
   *
   * @param   {Event}   event - Event object
   */
  _onBodyEvent(event) {
    switch (event.type) {
      case 'click':
        if (this.isOpen()) this.close();
        break;
      case 'keydown':
        if (event.key == 'Escape') {
          if (this.isOpen()) {
            this.close();
            event.stopPropagation(); // stop Escape from bubbling out and closing modals
          }
        }
        break;
    }
  }

  /**
   * Handle a click event
   *
   * @param   {Event}   event - Event object
   */
  _onClick(event) {
    event.stopPropagation(); // stop click from bubbling out of the menu
    this._onSelect(event.target);
  }

  /**
   * Invoke the onSelect callback
   *
   * @param   {Element}   el - Selected element object
   */
  _onSelect(el) {
    if (typeof this.options.onSelect === 'function') this.options.onSelect(el);
    else this.close();
  }

  /**
   * Set the menu's screen position and open
   */
  _open() {
    const self      = this;
    const maxHeight = `${document.body.offsetHeight-this.options.padding}px`;
    const overflow  = document.body.style.overflow;   // save overflow style
    const options   = {};                             // FloatingUI options

    this.div.classList.add('show');                   // show the div
    this.ul.scrollTop = 0;                            // reset the UL scroll position
    this.selected     = null;                         // reset selected element
    document.body.style.overflow = 'hidden';          // disable body overflow while resizing

    // reset the div and ul styles, zIndex keeps this menu on top
    Object.assign(this.ul.style,  {height: null, maxHeight: maxHeight});
    Object.assign(this.div.style, {top: 0, left: 0, height: null, maxHeight: maxHeight, zIndex: ff.GLOBALS.zIndex++});
    // setup options for FloatingUI
    if (['right','left'].includes(this.options.position)) {
      options.placement  = this.options.position;
      options.middleware = [FloatingUI.shift()];
    }
    else {
      // auto placement, choose the best fit starting with bottom-start
      options.placement  = 'bottom-start';
      options.middleware = [
        FloatingUI.flip({alignment: 'start', fallbackPlacements: ['top', 'right', 'left']}),
        FloatingUI.shift(),
        /*
        FloatingUI.size({
          apply({availableWidth, availableHeight, elements}) {
            Object.assign(elements.floating.style, {
              maxWidth:  `${availableWidth}px`,
              // maxHeight is already calculated and added to this.div above
              //maxHeight: `${availableHeight-self.options.padding}px`,
            });
          },
        })
        */
      ];
    }
    // FloatingUI init
    FloatingUI.computePosition(this.base, this.div, options).then(({x, y}) => {
      // update the placement of this.div then ensure that this.ul fits inside it
      Object.assign(self.div.style, {left: `${x}px`, top: `${y}px`});
      this.checkOverflow();
    });

    // finally, restore the body overflow style and run any callbacks
    document.body.style.overflow = overflow;
    if (typeof this.options.onOpen === 'function') this.options.onOpen(this);
  }

  //---------------------------------------------------------------------------------------------------------
  // Public Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Check if the inner menu overflowed the containing div
   */
  checkOverflow() {
    const bodyPos = ff.getPosition(document.body);
    let   divPos  = ff.getPosition(this.div);
    let   ulPos   = ff.getPosition(this.ul);

    if (divPos.bottom > bodyPos.bottom) {
      // div overflowed the bottom of document.body
      const height = bodyPos.bottom - divPos.top - ff.FOOTER_PADDING;
      this.div.style.height = `${height}px`;
      divPos = ff.getPosition(this.div);  // recalc
      ulPos  = ff.getPosition(this.ul);   // recalc
    }

    if (ulPos.bottom > divPos.bottom) {
      // ul overflowed the div, resize this.ul to fit
      Object.assign(this.ul.style, {height: `${divPos.height-(ulPos.top-divPos.top)-1}px`});
    }

    if (divPos.right > bodyPos.right) {
      // div overflowed the body to the right
      const left  = divPos.left - (divPos.right - bodyPos.right) - 1;
      const width = bodyPos.right - left - 1;
      Object.assign(this.div.style, {left: `${left}px`, width: `${width}px`});
    }
  }

  /**
   * Close (hide) the menu
   */
  close() {
    if (this.isOpen()) {
      this.div.classList.remove('show');
      if (typeof this.options.onClose === 'function') this.options.onClose(this);
    }
  }

  /**
   * Destroy the menu element
   */
  destroy() {
    this.div.remove();
    document.body.removeEventListener('click', event => this._onBodyEvent(event));
    document.body.removeEventListener('keydown', event => this._onBodyEvent(event));
    FastFundMenu.OBJECTS = FastFundMenu.OBJECTS.filter(menu => menu != this);
  }

  /**
   * Returns true if the menu is open
   *
   * @returns   {Boolean}  open
   */
  isOpen() {
    return this.div.classList.contains('show');
  }

  /**
   * Move focus to the next or previous LI
   *
   * @param     {String}   dir - direction: first, last, next, nextPage, previous, previousPage
   * @returns   {Element}  li  - li element moved to
   */
  move(dir = 'next') {
    //let items = [...this.ul.items].filter(li => !li.classList.contains('disabled'));
    const items = ff.getAll('li:not(.disabled,.group)', this.ul);
    let   li    = null;
    let   index = 0;

    // find the index of the currently selected li
    if (this.selected) {
      for (let i = 0; i < items.length; i++) {
        if (this.selected.dataset.value == items[i].dataset.value) {
          index = i;
          break;
        }
      }
    }

    switch(dir) {
      case 'last':
        li = items[items.length-1];
        break;
      case 'next':
        if (this.selected) li = items[index+1] || items[items.length-1];
        break;
      case 'nextPage':
        li = items[index+Math.ceil(items.length/10)] || items[items.length-1];
        break;
      case 'previous':
        li = items[index-1] || items[0];
        break;
      case 'previousPage':
        li = items[index-Math.ceil(items.length/10)] || items[0];
        break;
      default:
        // first
        li = items[0];
        break;
    }

    li = li || items[0];
    if (li) {
      // remove selected class from any previously selected LI
      ff.getAll(`li.${FastFundMenu.SelectedCSS}`, this.ul).forEach(li => {
        li.classList.remove(FastFundMenu.SelectedCSS);
      });

      // set and decorate the newly selected LI
      this.selected = li;
      this.selected.classList.add(FastFundMenu.SelectedCSS);

      // move focus to any focusable element within the LI
      const focusable = ff.get(ff.FOCUSABLE_SELECTOR, li);
      if (focusable) focusable.focus();

      this.ul.scrollTo({
        behavior: 'smooth',
        top:      this.selected.offsetTop-this.ul.offsetHeight/2
      });
    }

    return li;
  }

  /**
   * Open the menu
   *
   * @param   {String}   html - optional menu content
   */
  open(html=null) {
    if (html) this.ul.innerHTML = html;
    // close any other open menus
    FastFundMenu.OBJECTS.forEach(menu => { if (menu != this) menu.close() });
    // always check and set the position on open
    this._open();
  }

  /**
   * Invoke the onSelect with the currently selected item
   */
  select() {
    this._onSelect(this.selected || ff.get('.selected', this.ul));
  }

  /**
   * Toggle the menu open/closed
   */
  toggle() {
    if (this.isOpen()) this.close();
    else this.open();
  }

}
